unit uSignIn;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, Vcl.Controls, Vcl.StdCtrls, WEBLib.StdCtrls, WEBLib.Storage;

type
  TfrmSignIn = class(TForm)
    edEmail: TEdit;
    btnSignIn: TButton;
    rememberMe: TCheckBox;
    edPassword: TEdit;
    procedure btnSignInClick(Sender: TObject);
    procedure WebFormCreate(Sender: TObject);
  private
    { Private declarations }
  public
    { Public declarations }
  protected procedure LoadDFMValues; override; end;

var
  frmSignIn: TfrmSignIn;

implementation

{$R *.dfm}

uses uMain;

procedure TfrmSignIn.btnSignInClick(Sender: TObject);

  {procedure FormCreated(AForm: TObject);
  begin
    (AForm as TForm).Show;
  end;}

var
  sEmail, sPassword: string;
begin
  sEmail:= trim(edEmail.Text);
  sPassword:= trim(edPassword.Text);
  if (sEmail = '') and (sPassword = '') then
    ShowMessage('Please enter your email and password to proceed!!')
  else if (sEmail = '') then
    ShowMessage('Please enter your email to proceed!!')
  else if (sPassword = '') then
    ShowMessage('Please enter your password to proceed!!')
  else if ((sEmail.ToUpper = 'MIKE@NOVASERO.COM') and (sPassword.ToUpper = 'NOVAROSE'))
       or ((sEmail.ToUpper = 'CONSTANTINOS@NICHEADV.COM') and (sPassword.ToUpper = '1234'))
       or ((sEmail.ToUpper = 'CGEORGIOU@PIATSAEXPRESS.COM') and (sPassword.ToUpper = '1234'))
       or ((sEmail.ToUpper = 'MARIOSMALEKKOS@GMAIL.COM') and (sPassword.ToUpper = '1234'))
       or ((sEmail.ToUpper = 'PAPPASHYDROTECH@GMAIL.COM') and (sPassword.ToUpper = '1234'))
       or ((sEmail.ToUpper = 'SOCRATES447@GMAIL.COM') and (sPassword.ToUpper = '1234'))
       or ((sEmail.ToUpper = 'INFO@CITO.EXPRESS') and (sPassword.ToUpper = '1234'))
       or ((sEmail.ToUpper = 'MIKE.PANTELI@GMAIL.COM') and (sPassword.ToUpper = '1234'))
       or ((sEmail.ToUpper = 'IOANNOU.ANTONIS1969@GMAIL.COM') and (sPassword.ToUpper = '1234')) then

  begin
    //close;
    //free;
    TLocalStorage.SetValue('Login.Username', sEmail);
    TLocalStorage.SetValue('Login.Password', sPassword);
    if rememberMe.Checked then TLocalStorage.SetValue('Login.RememberMe', 'True')
    else TLocalStorage.SetValue('Login.RememberMe', 'False');
    TLocalStorage.SetValue('Login.SignedIn', 'True');

    //TWebLocalStorage.SetValue('Login.JWT', MainForm.JWT);
    //TWebLocalStorage.SetValue('Login.Expiry', FloatToStr(MainForm.JWT_Expiry));
    Application.CreateForm(TMainForm, MainForm);
    //MainForm:= TMainForm.CreateNew(@FormCreated);
  end else ShowMessage('Wrong email or password!!')



end;

procedure TfrmSignIn.WebFormCreate(Sender: TObject);
begin
  if (TLocalStorage.GetValue('Login.RememberMe') = 'True')
  then
  begin

    edEmail.Text:= TLocalStorage.GetValue('Login.Username');
    edPassword.Text:= TLocalStorage.GetValue('Login.Password');
    rememberMe.Checked:= True;
    //if TWebLocalStorage.GetValue('Login.SignedIn') = 'True' then btnSignInClick(btnSignIn)
  end else
  begin
    edEmail.Text:= '';
    edPassword.Text:= '';
    rememberMe.Checked:= False;
  end;
  TLocalStorage.SetValue('Login.SignedIn',  'False');
end;

procedure TfrmSignIn.LoadDFMValues;
begin
  inherited LoadDFMValues;

  edEmail := TEdit.Create('edEmail');
  btnSignIn := TButton.Create('btnSignIn');
  rememberMe := TCheckBox.Create('rememberMe');
  edPassword := TEdit.Create('edPassword');

  edEmail.BeforeLoadDFMValues;
  btnSignIn.BeforeLoadDFMValues;
  rememberMe.BeforeLoadDFMValues;
  edPassword.BeforeLoadDFMValues;
  try
    Name := 'frmSignIn';
    Width := 640;
    Height := 480;
    Color := clBtnFace;
    CSSLibrary := cssBootstrap;
    ElementFont := efCSS;
    SetEvent(Self, 'OnCreate', 'WebFormCreate');
    edEmail.SetParentComponent(Self);
    edEmail.Name := 'edEmail';
    edEmail.Left := 128;
    edEmail.Top := 96;
    edEmail.Width := 96;
    edEmail.Height := 25;
    edEmail.ElementFont := efCSS;
    edEmail.ElementPosition := epIgnore;
    edEmail.HeightStyle := ssAuto;
    edEmail.HeightPercent := 100.000000000000000000;
    edEmail.WidthStyle := ssAuto;
    edEmail.WidthPercent := 100.000000000000000000;
    btnSignIn.SetParentComponent(Self);
    btnSignIn.Name := 'btnSignIn';
    btnSignIn.Left := 101;
    btnSignIn.Top := 0;
    btnSignIn.Width := 96;
    btnSignIn.Height := 25;
    btnSignIn.Caption := 'Sign In';
    btnSignIn.ChildOrder := 1;
    btnSignIn.ElementFont := efCSS;
    btnSignIn.ElementPosition := epIgnore;
    btnSignIn.HeightStyle := ssAuto;
    btnSignIn.HeightPercent := 100.000000000000000000;
    btnSignIn.WidthStyle := ssAuto;
    btnSignIn.WidthPercent := 100.000000000000000000;
    SetEvent(btnSignIn, Self, 'OnClick', 'btnSignInClick');
    rememberMe.SetParentComponent(Self);
    rememberMe.Name := 'rememberMe';
    rememberMe.Left := 202;
    rememberMe.Top := 0;
    rememberMe.Width := 96;
    rememberMe.Height := 25;
    rememberMe.Caption := 'rememberMe';
    rememberMe.ChildOrder := 2;
    rememberMe.ElementFont := efCSS;
    rememberMe.ElementPosition := epIgnore;
    rememberMe.HeightStyle := ssAuto;
    rememberMe.HeightPercent := 100.000000000000000000;
    rememberMe.WidthStyle := ssAuto;
    rememberMe.WidthPercent := 100.000000000000000000;
    edPassword.SetParentComponent(Self);
    edPassword.Name := 'edPassword';
    edPassword.Left := 128;
    edPassword.Top := 144;
    edPassword.Width := 96;
    edPassword.Height := 25;
    edPassword.ChildOrder := 3;
    edPassword.ElementFont := efCSS;
    edPassword.ElementPosition := epIgnore;
    edPassword.HeightStyle := ssAuto;
    edPassword.HeightPercent := 100.000000000000000000;
    edPassword.WidthStyle := ssAuto;
    edPassword.WidthPercent := 100.000000000000000000;
  finally
    edEmail.AfterLoadDFMValues;
    btnSignIn.AfterLoadDFMValues;
    rememberMe.AfterLoadDFMValues;
    edPassword.AfterLoadDFMValues;
  end;
end;

end.
