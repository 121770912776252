program SMProDashboard;

{$R *.dres}

uses
  Vcl.Forms,
  WEBLib.Forms,
  uDashBoard in 'uDashBoard.pas'{*.html},
  uTables in 'uTables.pas'{*.html},
  uframe in 'uframe.pas',
  uSignIn in 'uSignIn.pas'{*.html},
  uMain in 'uMain.pas'{*.html};

{$R *.res}

begin
  Application.Initialize;
  Application.MainFormOnTaskbar := True;
  //Application.CreateForm(TfrmDashboard, frmDashboard);
  //Application.CreateForm(TfrmSignIn, frmSignIn);
  Application.CreateForm(TMainForm, MainForm);
  //Application.CreateForm(TfrmTables, frmTables);
  Application.Run;
end.
