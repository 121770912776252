{********************************************************************}
{                                                                    }
{ written by TMS Software                                            }
{            copyright (c) 2018 - 2023                               }
{            Email : info@tmssoftware.com                            }
{            Web : http://www.tmssoftware.com                        }
{                                                                    }
{ The source code is given as is. The author is not responsible      }
{ for any possible damage done due to the use of this code.          }
{ The complete source code remains property of the author and may    }
{ not be distributed, published, given or sold in any form as such.  }
{ No parts of the source code can be included in any other component }
{ or application without written authorization of the author.        }
{********************************************************************}

unit WEBLib.StdCtrls;

{$DEFINE NOPP}

interface

uses
  Classes, WEBLib.Controls, SysUtils, Web, WEBLib.Graphics, Types, WEBlib.WebTools,
  WEBLib.Forms, WEBLib.Menus;

type
  Single = Double;
  TEditCharCase = (wecLowerCase, wecNormal, wecMixedCase, wecUpperCase);
  TCheckBoxState = (cbChecked, cbGrayed, cbUnchecked);
  TDateTimeKind = (dtkDate, dtkTime);
  TEllipsisPosition = (epEndEllipsis, epNone, epPathEllipsis, epWordEllipsis);
  TTextLayout = (tlTop, tlCenter, tlBottom);
  TSysLinkType = (sltID, sltURL);

  TAutoCompletion = (acOff,acHonorificPrefix, acGivenName, acAdditionalName, acFamilyName, acHonorificSuffix,
    acNickName, acEmail, acUserName, acNewPassword, acCurrentPassword, acOrganizationTitle, acOrganization,
    acStreetAddress, acAddressLine1, acAddressLine2, acAddressLine3, acAddressLevel1, acAddressLevel2,
    acAddressLevel3, acAddressLevel4, acCountry, acCountryName, acPostalCode, acCCName, acCCGivenName,
    acCCAdditionalName, acCCFamilyName, acCCNumber, acExpiry, acExpiryMonth, acExpiryYear, acCSC,
    acType, acTransactionCurrency, acTransactionAmount, acLanguage, acBirthday, acBirthdayDay,
    acBirthDayMonth, acBirthDayYear, acSex, acTelephone, acTelephoneCountryCode, acTelephoneNational,
    acTelephoneAreaCode, acTelephoneLocal, acTelephoneExtension, acIMPP, acURL, acPhoto, acNone, acNope);

  TEditType = (weString, weFloat, weHex, weNumeric, weSignedFloat, weSignedNumeric, weSearch);

  THTMLType = (tLABELTAG, tSPAN, tDIV, tH1,tH2, tH3, tH4, tH5, tH6, tP);

  TLinkClickEvent = procedure(Sender: TObject; Link: string; LinkType: TSysLinkType) of object;

  TCustomLabel = class(TWebCustomControl)
  private
    FContent: TJSElement;
    FAutoSize: boolean;
    FEllipsisPosition: TEllipsisPosition;
    FWordWrap: Boolean;
    FAlignment: TAlignment;
    FLayout: TTextLayout;
    FTransparent: boolean;
    FHTMLType: THTMLType;
    FFocusControl: TWinControl;
    FShowAccelChar: boolean;
    FColor: TColor;
    FHasAccel: boolean;
    FHasHTML: boolean;
    FElementLabelClassName: TElementClassName;
    FOrigWidth: integer;
    FOldWidth, FOldHeight: integer;
    FHTML: string;
    procedure SetLayout(const Value: TTextLayout);
    procedure SetAlignment(const Value: TAlignment);
    function GetContentHandle: TJSHTMLElement;
    procedure SetTransparent(const Value: boolean);
    procedure SetHTMLType(const Value: THTMLType);
    procedure SetColorEx(const Value: TColor);
    procedure SetElementLabelClassName(const Value: TElementClassName);
    procedure SetHTML(const Value: string);
  protected
    procedure SetWidth(AValue: Integer); override;
    function CreateElement: TJSElement; override;
    function CreateLabelElement: TJSElement; virtual;
    function GetDisplayText: string; virtual;
    function CanShowFocus: boolean; override;
    procedure SetParent(AValue: TControl); override;
    procedure BindElement; override;
    procedure Loaded; override;
    procedure UpdateAutoSize; virtual;
    procedure UpdateElementData; override;
    procedure UpdateElementVisual; override;
    procedure UpdateElementSize; override;
    procedure SetAutoSize(AValue: boolean);
    procedure SetCaption(const AValue: string); override;
    procedure SetControlCursor(const Value: TCursor); override;
    procedure SetEllipsisPosition(AValue: TEllipsisPosition);
    property ContentHandle: TJSHTMLElement read GetContentHandle;
    property Alignment: TAlignment read FAlignment write SetAlignment default taLeftJustify;
    property AutoSize: boolean read FAutoSize write SetAutoSize default true;
    property ElementLabelClassName: TElementClassName read FElementLabelClassName write SetElementLabelClassName;
    property EllipsisPosition: TEllipsisPosition read FEllipsisPosition write SetEllipsisPosition default epNone;
    property FocusControl: TWinControl read FFocusControl write FFocusControl;
    property Layout: TTextLayout read FLayout write SetLayout default tlTop;
    property ShowAccelChar: boolean read FShowAccelChar write FShowAccelChar default true;
    property Transparent: boolean read FTransparent write SetTransparent default true;
    property WordWrap: Boolean read FWordWrap write FWordWrap default false;
    property HTML: string read FHTML write SetHTML;
    property HTMLType: THTMLType read FHTMLType write SetHTMLType default tLABELTAG;
    property Color: TColor read FColor write SetColorEx default clWhite;

    function GetWidth: Integer; override;
    function GetHeight: Integer; override;
  public
    procedure CreateInitialize; override;
  end;

  TLabel = class(TCustomLabel)
  published
    property Align;
    property Alignment;
    property AlignWithMargins;
    property Anchors;
    property AutoSize;
    property Caption;
    property ChildOrder;
    property Color;
    property DragMode;
    property EllipsisPosition;
    property Enabled;
    property ElementClassName;
    property ElementLabelClassName;
    property ElementID;
    property ElementFont;
    property ElementPosition;
    property FocusControl;
    property Font;
    property Height;
    property HeightPercent;
    property HeightStyle;
    property Hint;
    property HTML;
    property HTMLType;
    property Layout;
    property Left;
    property Margins;
    property ParentFont;
    property PopupMenu;
    property ShowAccelChar;
    property ShowHint;
    property TextDirection;
    property Top;
    property Transparent;
    property Visible;
    property Width;
    property WidthPercent;
    property WidthStyle;
    property WordWrap;
    property OnClick;
    property OnDblClick;
    property OnTouchStart;
    property OnTouchEnd;
    property OnTouchMove;
    property OnTouchCancel;
    property OnDragOver;
    property OnDragDrop;
    property OnStartDrag;
    property OnEndDrag;
  end;

  TWebLabel = class(TLabel);

  TCustomInput = class(TWebCustomControl)
  private
  protected
    function IsInputControl: Boolean; override;
    function GetInputType: string; virtual;
    function CreateElement: TJSElement; override;
    procedure UpdateElementVisual; override;
    procedure LoadState(AState: string); override;
    function SaveState: string; override;
  public
    procedure CreateInitialize; override;
  end;

  TCustomEdit = class(TCustomInput)
  private
    FCharCase: TEditCharCase;
    FMaxLength: integer;
    FReadOnly: Boolean;
    FText: string;
    FTextHint: string;
    FSelStart: Integer;
    FAlignment: TAlignment;
    FHideSelection: Boolean;
    FPasswordChar: Char;
    FOnChange: TNotifyEvent;
    FAutoSize: Boolean;
    FAutoSelect: Boolean;
    FSelLength: Integer;
    FNumeric: Boolean;
    FAutoCompletion: TAutoCompletion;
    FEditType: TEditType;
    FRequired: Boolean;
    FAutoFocus: boolean;
    FPattern: string;
    FHandlePastePtr: pointer;
    FHandleCutPtr: pointer;
    FHandleChangePtr: pointer;
    FHandleInvalidPtr: pointer;
    FSpellCheck: boolean;
    FRequiredText: string;
    procedure SetAlignment(const Value: TAlignment);
    procedure SetHideSelection(const Value: Boolean);
    procedure SetAutoSelect(const Value: Boolean);
    procedure SetAutoSize(const Value: Boolean);
    procedure SetSelLength(const Value: Integer);
    procedure SetSelStart(const Value: Integer);
    procedure SetPasswordChar(const Value: Char);
    procedure SetNumeric(const Value: Boolean);
    procedure SetAutoCompletion(const Value: TAutoCompletion);
    procedure SetAutoFocus(const Value: boolean);
    procedure SetRequired(const Value: Boolean);
    procedure SetPattern(const Value: string);
    function GetSelLength: Integer;
    function GetSelStart: Integer;
    procedure SetEditType(const Value: TEditType);
    function GetSelText: string;
    procedure SetSpellCheck(const Value: boolean);
  protected
    function GetElementInputHandle: TJSHTMLInputElement; virtual;
    function DoHandlePaste(Event: TEventListenerEvent): Boolean; virtual;
    function DoHandleCut(Event: TEventListenerEvent): Boolean; virtual;
    function DoHandleChange(Event: TEventListenerEvent): Boolean; virtual;
    function DoHandleInvalid(Event: TJSEvent): Boolean; virtual;
    function IsCustomEditor: Boolean; virtual;
    procedure KeyPress(var Key: Char); override;
    procedure DoEnter; override;
    function GetInputType: string; override;
    procedure PersistinHTML; override;
    function GetText: string;
    function GetDisplayText: string; virtual;
    function IsReadOnly: boolean; virtual;
    function Validate(AValue: string): boolean; virtual;
    procedure ClearMethodPointers; override;
    procedure GetMethodPointers; override;
    procedure BindEvents; override;
    procedure UnBindEvents; override;
    procedure UpdateElementData; override;
    procedure SetCharCase(AValue: TEditCharCase); virtual;
    procedure SetMaxLength(AValue: integer);
    procedure SetReadOnly(AValue: boolean);
    procedure SetText(AValue: string);
    procedure SetTextHint(AValue: string);
    function CanPaste(AValue: string): boolean; virtual;
    function CanCut: boolean; virtual;
    property Font;
    property AutoCompletion: TAutoCompletion read FAutoCompletion write SetAutoCompletion default acOff;
    property AutoFocus: boolean read FAutoFocus write SetAutoFocus default false;
    property Color;
    property BorderStyle;
    property HideSelection: Boolean read FHideSelection write SetHideSelection;
    property CharCase: TEditCharCase read FCharCase write SetCharCase default wecNormal;
    property MaxLength: integer read FMaxLength write SetMaxLength default 0;
    property Numeric: Boolean read FNumeric write SetNumeric default false;
    property PasswordChar: Char read FPasswordChar write SetPasswordChar default #0;
    property Pattern: string read FPattern write SetPattern;
    property ReadOnly: Boolean read FReadOnly write SetReadOnly default false;
    property Required: Boolean read FRequired write SetRequired default false;
    property RequiredText: string read FRequiredText write FRequiredText;
    property Text: string read GetText write SetText;
    property TextHint: string read FTextHint write SetTextHint;
    property AutoSize: Boolean read FAutoSize write SetAutoSize default false;
    property AutoSelect: Boolean read FAutoSelect write SetAutoSelect default true;
    property Alignment: TAlignment read FAlignment write SetAlignment;
    property OnChange: TNotifyEvent read FOnChange write FOnChange;
    property OnClick;
    property OnDblClick;
  public
    procedure CreateInitialize; override;
    property SelStart: Integer read GetSelStart write SetSelStart;
    property SelLength: Integer read GetSelLength write SetSelLength;
    procedure Clear;
    procedure ClearSelection;
    procedure Change; virtual;
    procedure SelectAll;
    procedure CopyToClipboard;
    procedure SetSelection(ASelStart, ASelLength: integer);
    property SelText: string read GetSelText;
    property SpellCheck: boolean read FSpellCheck write SetSpellCheck default true;
    property EditType: TEditType read FEditType write SetEditType default weString;
    property ElementInputHandle: TJSHTMLInputElement read GetElementInputHandle;
  end;

  TEdit = class(TCustomEdit)
  published
    property Alignment;
    property Align;
    property AlignWithMargins;
    property Anchors;
    property AutoCompletion;
    property AutoFocus;
    property AutoSize;
    property AutoSelect;
    property BiDiMode;
    property BorderStyle;
    property CharCase;
    property ChildOrder;
    property Color;
    property DragMode;
    property EditType;
    property ElementClassName;
    property ElementID;
    property ElementFont;
    property ElementPosition;
    property Enabled;
    property Font;
    property Height;
    property HeightPercent;
    property HeightStyle;
    property Hint;
    property HideSelection;
    property Left;
    property ParentFont;
    property PasswordChar;
    property Pattern;
    property Margins;
    property MaxLength;
    property PopupMenu;
    property ReadOnly;
    property Required;
    property RequiredText;
    property ShowFocus;
    property ShowHint;
    property SpellCheck;
    property TabOrder;
    property TabStop;
    property Text;
    property TextDirection;
    property TextHint;
    property Top;
    property Visible;
    property Width;
    property WidthPercent;
    property WidthStyle;
    property OnChange;
    property OnClick;
    property OnDblClick;
    property OnKeyDown;
    property OnKeyPress;
    property OnKeyUp;
    property OnMouseDown;
    property OnMouseUp;
    property OnMouseMove;
    property OnMouseLeave;
    property OnMouseEnter;
    property OnEnter;
    property OnExit;
    property OnDragDrop;
    property OnDragOver;
    property OnEndDrag;
    property OnStartDrag;
  end;

  TWebEdit = class(TEdit);

  TSpinEdit = class(TCustomInput)
  private
    FIncrement: integer;
    FMaxValue: integer;
    FMinValue: integer;
    FValue: integer;
    FAutoSize: Boolean;
    FOnChange: TNotifyEvent;
    FReadOnly: boolean;
    FHandleChangePtr: pointer;
    FHandleInvalidPtr: pointer;
    FRequiredText: string;
    FRequired: Boolean;
    function GetText: string;
    procedure SetText(const Value: string);
    function GetElementInputHandle: TJSHTMLInputElement;
    procedure SetReadOnly(const Value: boolean);
    procedure SetRequired(const Value: Boolean);
  protected
    procedure KeyPress(var Key: Char); override;
    procedure PersistinHTML; override;
    function GetInputType: string; override;
    procedure UpdateElementData; override;
    function GetValue: integer;
    procedure SetIncrement(AValue: integer);
    procedure SetMaxValue(AValue: integer);
    procedure SetMinValue(AValue: integer);
    procedure SetValue(AValue: integer);
    function DoHandleChange(Event: TEventListenerEvent): Boolean;
    function DoHandleInvalid(Event: TJSEvent): Boolean; virtual;
    function IsReadOnly: boolean; virtual;
    function GetDisplayText: string; virtual;
    procedure BindEvents; override;
    procedure UnBindEvents; override;
    procedure Change; virtual;
    procedure ClearMethodPointers; override;
    procedure GetMethodPointers; override;
  public
    procedure CreateInitialize; override;
    property ElementInputHandle: TJSHTMLInputElement read GetElementInputHandle;
    property Text: string read GetText write SetText;
  published
    property Align;
    property AlignWithMargins;
    property Anchors;
    property AutoSize: Boolean read FAutoSize write FAutoSize;
    property BiDiMode;
    property BorderStyle;
    property ChildOrder;
    property Color;
    property DragMode;
    property ElementClassName;
    property ElementID;
    property ElementFont;
    property ElementPosition;
    property Enabled;
    property Font;
    property Height;
    property HeightPercent;
    property HeightStyle;
    property Hint;
    property Increment: integer read FIncrement write SetIncrement;
    property Left;
    property Margins;
    property MaxValue: integer read FMaxValue write SetMaxValue default 100;
    property MinValue: integer read FMinValue write SetMinValue default 0;
    property ParentFont;
    property ReadOnly: boolean read FReadOnly write SetReadOnly default false;
    property Required: Boolean read FRequired write SetRequired default false;
    property RequiredText: string read FRequiredText write FRequiredText;
    property Role;
    property ShowFocus;
    property ShowHint;
    property TabStop;
    property TabOrder;
    property TextDirection;
    property Value: integer read GetValue write SetValue;
    property Visible;
    property Width;
    property WidthPercent;
    property WidthStyle;
    property OnChange: TNotifyEvent read FOnChange write FOnChange;
    property OnClick;
    property OnDblClick;
    property OnKeyDown;
    property OnKeyPress;
    property OnKeyUp;
    property OnMouseDown;
    property OnMouseUp;
    property OnMouseMove;
    property OnMouseLeave;
    property OnMouseEnter;
    property OnEnter;
    property OnExit;
    property OnDragDrop;
    property OnDragOver;
    property OnEndDrag;
    property OnStartDrag;
  end;

  TWebSpinEdit = class(TSpinEdit);

  TDateTimePicker = class(TCustomInput)
  private
    FDate: TDate;
    FTime: TTime;
    FKind: TDateTimeKind;
    FOnChange: TNotifyEvent;
    FReadOnly: Boolean;
    FElementCheck: TJSHTMLElement;
    FElementPicker: TJSHTMLElement;
    FShowCheckBox: boolean;
    FClickPtr: pointer;
    FHandleChangePtr: pointer;
    FFocusPtr: pointer;
    FBlurPtr: pointer;
    FChecked: boolean;
    FShowSeconds: boolean;
    FAutoDropDown: boolean;
    procedure SetDate(AValue: TDate);
    function GetDate: TDate;
    procedure SetTime(AValue: TTime);
    function GetTime: TTime;
    procedure SetKind(AValue: TDateTimeKind);
    procedure SetText(const Value: String);
    function GetText: String;
    procedure SetReadOnly(const Value: boolean);
    procedure SetShowCheckBox(const Value: boolean);
    procedure SetChecked(const Value: boolean);
    function GetDateTime: TDateTime;
    procedure SetDateTime(const Value: TDateTime);
    procedure SetShowSeconds(const Value: boolean);
  protected
    function DoCheckClick(Event: TEventListenerEvent): Boolean;
    function DoPickerFocus(Event: TEventListenerEvent): Boolean;
    function DoPickerBlur(Event: TEventListenerEvent): Boolean;
    function DoHandleChange(Event: TEventListenerEvent): Boolean;
    function HandleDoEnter(Event: TJSFocusEvent): Boolean; override;
    function GetElementBindHandle: TJSEventTarget; override;
    procedure BindEvents; override;
    procedure UnBindEvents; override;
    procedure UpdateElementData; override;
    procedure UpdateElementVisual; override;
    function CreateElement: TJSElement; override;
    function GetInputType: string; override;
    procedure Change; virtual;
    function IsReadOnly: boolean; virtual;
    procedure ClearMethodPointers; override;
    procedure GetMethodPointers; override;
    function GetElementPicker: TJSHTMLElement;
    procedure SetEnabled(Value: Boolean); override;
  public
    procedure CreateInitialize; override;
    procedure Clear;
    property DateTime: TDateTime read GetDateTime write SetDateTime;
    procedure SetFocus; override;
  published
    property Align;
    property AlignWithMargins;
    property Anchors;
    property AutoDropDown: boolean read FAutoDropDown write FAutoDropDown default false;
    property BorderStyle;
    property Checked: boolean read FChecked write SetChecked default false;
    property ChildOrder;
    property Color;
    property Date: TDate read GetDate write SetDate;
    property DragMode;
    property ElementClassName;
    property ElementID;
    property ElementFont;
    property ElementPosition;
    property Font;
    property Height;
    property HeightPercent;
    property HeightStyle;
    property Hint;
    property Kind: TDateTimeKind read FKind write SetKind;
    property Left;
    property Margins;
    property ParentFont;
    property ReadOnly: boolean read FReadOnly write SetReadOnly default false;
    property Role;
    property ShowCheckBox: boolean read FShowCheckBox write SetShowCheckBox default false;
    property ShowFocus;
    property ShowHint;
    property ShowSeconds: boolean read FShowSeconds write SetShowSeconds default true;
    property TabStop;
    property TabOrder;
    property Text: String read GetText write SetText;
    property TextDirection;
    property Time: TTime read GetTime write SetTime;
    property Top;
    property Visible;
    property Width;
    property WidthPercent;
    property WidthStyle;
    property OnClick;
    property OnChange: TNotifyEvent read FOnChange write FOnChange;
    property OnDblClick;
    property OnKeyDown;
    property OnKeyPress;
    property OnKeyUp;
    property OnMouseDown;
    property OnMouseUp;
    property OnMouseMove;
    property OnMouseLeave;
    property OnMouseEnter;
    property OnEnter;
    property OnExit;
    property OnDragDrop;
    property OnDragOver;
    property OnEndDrag;
    property OnStartDrag;
  end;

  TWebDateTimePicker = class(TDateTimePicker);

  TButton = class(TWebCustomControl)
  private
    FModalResult: TModalResult;
    FDefault: Boolean;
    FCancel: Boolean;
    FButtonType: string;
    procedure SetButtonType(const Value: string);
    procedure SetDefault(const Value: Boolean);
  protected
    function CreateElement: TJSElement; override;
    procedure SetCaption(const AValue: string); override;
    procedure UpdateElementData; override;
    procedure UpdateElementVisual; override;
    procedure HandleKeyPreview(Key: Word); override;
  public
    procedure CreateInitialize; override;
    procedure Click; override;
  published
    property Align;
    property AlignWithMargins;
    property Anchors;
    property BiDiMode;
    property BorderStyle;
    property ButtonType: string read FButtonType write SetButtonType;
    property Caption;
    property Cancel: Boolean read FCancel write FCancel default false;
    property ChildOrder;
    property Color;
    property Default: Boolean read FDefault write SetDefault default false;
    property DragMode;
    property ElementClassName;
    property ElementID;
    property ElementFont;
    property ElementPosition;
    property Enabled;
    property Font;
    property Height;
    property HeightPercent;
    property HeightStyle;
    property Hint;
    property Left;
    property Margins;
    property ModalResult: TModalResult read FModalResult write FModalResult default mrNone;
    property PopupMenu;
    property Role;
    property ParentFont;
    property ShowHint;
    property TabOrder;
    property TabStop;
    property TextDirection;
    property Top;
    property Visible;
    property Width;
    property WidthPercent;
    property WidthStyle;
    property OnClick;
    property OnKeyDown;
    property OnKeyPress;
    property OnKeyUp;
    property OnMouseDown;
    property OnMouseUp;
    property OnMouseMove;
    property OnMouseLeave;
    property OnMouseEnter;
    property OnEnter;
    property OnExit;
    property OnDragDrop;
    property OnDragOver;
    property OnEndDrag;
    property OnStartDrag;
  end;

  TWebButton = class(TButton);

  TCheckBox = class(TWebCustomControl)
  private
    FChecked: boolean;
    FState: TCheckBoxState;
    FElementButtonClassName: TElementClassName;
    FElementLabelClassName: TElementClassName;
    FOnCheckClick: TNotifyEvent;
  protected
    procedure Loaded; override;
    procedure UpdateElementData; override;
    procedure UpdateElementSize; override;
    procedure UpdateElementVisual; override;
    procedure PersistinHTML; override;
    function CreateElement: TJSElement; override;
    procedure SetChecked(AValue: boolean);
    function GetChecked: boolean;
    procedure SetState(AValue: TCheckBoxState);
    function GetState: TCheckBoxState;
    procedure SetCaption(const AValue: string); override;
    procedure SetEnabled(Value: boolean); override;
    function HandleLabelClick(Event: TJSMouseEvent): Boolean; virtual;
    function HandleCheckClick(Event: TJSMouseEvent): Boolean; virtual;
    procedure Click; override;
    procedure DoCheckClick; virtual;
    function GetCheckElement: TJSHTMLElement;
    procedure KeyPress(var ch: char); override;
    procedure LoadState(AState: string); override;
    function SaveState: string; override;
    property OnCheckClick: TNotifyEvent read FOnCheckClick write FOnCheckClick;
  public
    procedure CreateInitialize; override;
  published
    property Align;
    property AlignWithMargins;
    property Anchors;
    property Caption;
    property Checked: boolean read GetChecked write SetChecked default False;
    property ChildOrder;
    property Color default clNone;
    property DragMode;
    property ElementClassName;
    property ElementButtonClassName: TElementClassName read FElementButtonClassName write FElementButtonClassName;
    property ElementLabelClassName: TElementClassName read FElementLabelClassName write FElementLabelClassName;
    property ElementID;
    property ElementFont;
    property ElementPosition;
    property Enabled;
    property Font;
    property Height;
    property HeightPercent;
    property HeightStyle;
    property Hint;
    property Left;
    property Margins;
    property ParentFont;
    property PopupMenu;
    property Role;
    property ShowFocus;
    property ShowHint;
    property State: TCheckBoxState read GetState write SetState default cbUnchecked;
    property TabOrder;
    property TabStop;
    property Top;
    property Visible;
    property Width;
    property WidthPercent;
    property WidthStyle;
    property OnClick;
    property OnKeyDown;
    property OnKeyPress;
    property OnKeyUp;
    property OnMouseDown;
    property OnMouseUp;
    property OnMouseMove;
    property OnMouseLeave;
    property OnMouseEnter;
    property OnEnter;
    property OnExit;
    property OnDragDrop;
    property OnDragOver;
    property OnEndDrag;
    property OnStartDrag;
  end;

  TWebCheckBox = class(TCheckBox);

  TRadioButton = class(TWebCustomControl)
  private
    FChecked: boolean;
    FGroupName: string;
    FElementButtonClassName: TElementClassName;
    FElementLabelClassName: TElementClassName;
  protected
    procedure PersistInHTML; override;
    function CreateElement: TJSElement; override;
    procedure SetChecked(AValue: boolean);
    function GetChecked: boolean;
    procedure SetEnabled(Value: boolean); override;
    procedure SetCaption(const AValue: string); override;
    procedure SetGroupName(AValue: string);
    function HandleLabelClick(Event: TJSMouseEvent): Boolean; virtual;
    function HandleRadioClick(Event: TJSMouseEvent): Boolean; virtual;
    procedure UpdateElementSize; override;
    procedure UpdateElementData; override;
    procedure UpdateElementVisual; override;
    function GetRadioElement: TJSHTMLElement;
    procedure Keypress(var ch: Char); override;
    procedure LoadState(AState: string); override;
    function SaveState: string; override;
  public
    procedure CreateInitialize; override;
  published
    property Align;
    property AlignWithMargins;
    property Anchors;
    property Caption;
    property Checked: boolean read GetChecked write SetChecked default false;
    property ChildOrder;
    property Color default clNone;
    property DragMode;
    property ElementClassName;
    property ElementButtonClassName: TElementClassName read FElementButtonClassName write FElementButtonClassName;
    property ElementLabelClassName: TElementClassName read FElementLabelClassName write FElementLabelClassName;
    property ElementID;
    property ElementFont;
    property ElementPosition;
    property Enabled;
    property Font;
    property GroupName: string read FGroupName write SetGroupName;
    property Height;
    property HeightPercent;
    property HeightStyle;
    property Hint;
    property Left;
    property ParentFont;
    property PopupMenu;
    property Role;
    property ShowFocus;
    property ShowHint;
    property TabOrder;
    property TabStop;
    property Visible;
    property Width;
    property WidthPercent;
    property WidthStyle;
    property OnClick;
    property OnDblClick;
    property OnKeyDown;
    property OnKeyPress;
    property OnKeyUp;
    property OnMouseDown;
    property OnMouseUp;
    property OnMouseMove;
    property OnMouseLeave;
    property OnMouseEnter;
    property OnEnter;
    property OnExit;
    property OnDragDrop;
    property OnDragOver;
    property OnEndDrag;
    property OnStartDrag;
  end;

  TWebRadioButton = class(TRadioButton);

  TListBox = class(TWebCustomControl)
  private
    FItems: TStrings;
    FItemIndex: integer;
    FMultiSelect: boolean;
    FSelected: TList;
    FItemHeight: Integer;
    FSorted: Boolean;
    FOnChange: TNotifyEvent;
    FHandleChangePtr: pointer;
    FElementItemClassName: string;
    procedure SetItemHeight(const Value: Integer);
    procedure SetSorted(const Value: Boolean);
    function GetCount: Integer;
    function GetElementSelectHandle: TJSHTMLSelectElement;
    function GetSelCount: integer;
    function GetValues(AIndex: integer): string;
    procedure SetValues(AIndex: integer; const Value: string);
  protected
    function DoHandleChange(Event: TEventListenerEvent): Boolean;
    procedure DoItemsChange(Sender: TObject);
    procedure DoUpdateList; virtual;
    procedure PersistinHTML; override;
    function GetItemIndex: integer; virtual;
    function CreateElement: TJSElement; override;
    function GetSelected(AIndex: integer): boolean;
    procedure SetSelected(AIndex: Integer; AValue: boolean);
    procedure SetItems(AItems: TStrings);
    procedure SetItemIndex(AIndex: integer); virtual;
    procedure SetMultiSelect(AValue: boolean);
    procedure Loaded; override;
    procedure UpdateElementData; override;
    procedure UpdateElementVisual; override;
    procedure UpdateParent; override;
    procedure BindEvents; override;
    procedure UnBindEvents; override;
    procedure Change; virtual;
    function SaveState: string; override;
    procedure LoadState(AState: string); override;
    procedure EnableDrag; override;
    procedure DisableDrag; override;
    procedure ClearMethodPointers; override;
    procedure GetMethodPointers; override;
  public
    procedure CreateInitialize; override;
    destructor Destroy; override;
    procedure EndUpdate; override;
    procedure ClearSelection;
    procedure SelectAll;
    procedure DeleteSelected;
    property Sorted: Boolean read FSorted write SetSorted;
    property Count: Integer read GetCount;
    property SelCount: integer read GetSelCount;
    property ElementSelectHandle: TJSHTMLSelectElement read GetElementSelectHandle;
    procedure AddItem(Item: string; AObject: TObject);
    property Selected[i: Integer]: boolean read GetSelected write SetSelected;
    property Values[AIndex: integer]: string read GetValues write SetValues;
    procedure Clear;
  published
    property Align;
    property AlignWithMargins;
    property Anchors;
    property BiDiMode;
    property BorderStyle;
    property ChildOrder;
    property Color;
    property DragMode;
    property Font;
    property ElementClassName;
    property ElementItemClassName: string read FElementItemClassName write FElementItemClassName;
    property ElementID;
    property ElementFont;
    property ElementPosition;
    property Enabled;
    property Height;
    property HeightPercent;
    property HeightStyle;
    property Hint;
    property ItemHeight: Integer read FItemHeight write SetItemHeight;
    property ItemIndex: integer read GetItemIndex write SetItemIndex default -1;
    property Items: TStrings read FItems write SetItems;
    property Left;
    property MultiSelect: boolean read FMultiSelect write SetMultiSelect;
    property ParentColor;
    property ParentFont;
    property PopupMenu;
    property Role;
    property ShowFocus;
    property ShowHint;
    property TabOrder;
    property TabStop;
    property TextDirection;
    property Top;
    property Visible;
    property Width;
    property WidthPercent;
    property WidthStyle;
    property OnChange: TNotifyEvent read FOnChange write FOnChange;
    property OnClick;
    property OnDblClick;
    property OnKeyDown;
    property OnKeyPress;
    property OnKeyUp;
    property OnMouseDown;
    property OnMouseUp;
    property OnMouseMove;
    property OnMouseLeave;
    property OnMouseEnter;
    property OnMouseWheel;
    property OnEnter;
    property OnExit;
    property OnDragDrop;
    property OnDragOver;
    property OnEndDrag;
    property OnStartDrag;
  end;

  TWebListBox = class(TListBox);

  TComboBoxStyle = (csDropDownList, csDropDown);

  TCustomComboBox = class(TWebCustomControl)
  private
    FItems: TStrings;
    FItemIndex: integer;
    FStyle: TComboBoxStyle;
    FDroppedDown: Boolean;
    FOnChange: TNotifyEvent;
    FTextHint: string;
    FListName: string;
    FText: string;
    FHandleChangePtr: pointer;
    FHandleInputPtr: pointer;
    FHandleFocusInPtr: pointer;
    FHandleFocusOutPtr: pointer;
    FOnFocusOut: TNotifyEvent;
    FOnFocusIn: TNotifyEvent;
    function GetText: string;
    procedure SetText(const Value: string);
    procedure SetStyle(const Value: TComboBoxStyle);
    procedure SetDroppedDown(const Value: Boolean);
    function GetElementSelectHandle: TJSHTMLSelectElement;
    procedure SetTextHint(const Value: string);
    function GetValues(AIndex: integer): string;
    procedure SetValues(AIndex: integer; const Value: string);
  protected
    function DoHandleChange(Event: TEventListenerEvent): Boolean; virtual;
    function DoHandleInput(Event: TEventListenerEvent): Boolean; virtual;
    function DoHandleFocusIn(Event: TEventListenerEvent): Boolean; virtual;
    function DoHandleFocusOut(Event: TEventListenerEvent): Boolean; virtual;
    procedure DoItemsChange(Sender: TObject);
    procedure ClearMethodPointers; override;
    procedure GetMethodPointers; override;
    procedure AddTextHint; virtual;
    procedure DoUpdateList; virtual;
    procedure LoadState(AState: string); override;
    function SaveState: string; override;
    function GetItemIndex: integer;
    function CreateElement: TJSElement; override;
    procedure BindEvents; override;
    procedure UnBindEvents; override;
    procedure UpdateElementData; override;
    procedure UpdateParent; override;
    procedure SetItems(AItems: TStrings);
    procedure SetItemIndex(AIndex: integer);
    procedure Loaded; override;
    procedure Change; virtual;
    procedure PersistinHTML; override;
    property Items: TStrings read FItems write SetItems;
    property Align;
    property AlignWithMargins;
    property BiDiMode;
    property Color;
    property ElementClassName;
    property ElementID;
    property ElementFont;
    property ElementPosition;
    property Enabled;
    property Font;
    property Height;
    property Hint;
    property ItemIndex: integer read GetItemIndex write SetItemIndex default -1;
    property Left;
    property PopupMenu;
    property ShowHint;
    property TabOrder;
    property TabStop;
    property Text: string read GetText write SetText;
    property TextHint: string read FTextHint write SetTextHint;
    property Top;
    property Visible;
    property Width;
    property OnChange: TNotifyEvent read FOnChange write FOnChange;
    property OnClick;
    property OnDblClick;
    property OnFocusIn: TNotifyEvent read FOnFocusIn write FOnFocusIn;
    property OnFocusOut: TNotifyEvent read FOnFocusOut write FOnFocusOut;
    property OnKeyDown;
    property OnKeyPress;
    property OnKeyUp;
    property OnMouseDown;
    property OnMouseUp;
    property OnMouseMove;
    property OnMouseLeave;
    property OnMouseEnter;
    property OnMouseWheel;
    property OnEnter;
    property OnExit;
  public
    procedure CreateInitialize; override;
    destructor Destroy; override;
    procedure Clear;
    procedure AddItem(Item: string; AObject: TObject);
    property Values[AIndex: integer]: string read GetValues write SetValues;
    property ElementSelectHandle: TJSHTMLSelectElement read GetElementSelectHandle;
    property Style: TComboBoxStyle read FStyle write SetStyle;
    property DroppedDown: Boolean read FDroppedDown write SetDroppedDown;
  end;

  TComboBox = class(TCustomComboBox)
  published
    property Align;
    property AlignWithMargins;
    property Anchors;
    property BiDiMode;
    property ChildOrder;
    property Color;
    property DragMode;
    property ElementClassName;
    property ElementID;
    property ElementFont;
    property ElementPosition;
    property Enabled;
    property Font;
    property Height;
    property HeightPercent;
    property HeightStyle;
    property Hint;
    property ItemIndex;
    property Items;
    property Left;
    property ParentFont;
    property Role;
    property ShowFocus;
    property ShowHint;
    property Style;
    property TabOrder;
    property TabStop;
    property Text;
    property TextDirection;
    property TextHint;
    property Top;
    property Visible;
    property Width;
    property WidthPercent;
    property WidthStyle;
    property OnChange;
    property OnClick;
    property OnDblClick;
    property OnKeyDown;
    property OnKeyPress;
    property OnKeyUp;
    property OnMouseDown;
    property OnMouseUp;
    property OnMouseMove;
    property OnMouseLeave;
    property OnMouseEnter;
    property OnEnter;
    property OnExit;
    property OnDragDrop;
    property OnDragOver;
    property OnEndDrag;
    property OnStartDrag;
  end;

  TWebComboBox = class(TComboBox);

  TFontPicker = class(TCustomComboBox)
  public
    procedure CreateInitialize; override;
    property Items;
  published
    property Align;
    property AlignWithMargins;
    property Color;
    property ElementClassName;
    property ElementID;
    property ElementFont;
    property ElementPosition;
    property Enabled;
    property Font;
    property Height;
    property Hint;
    property ItemIndex;
    property Left;
    property ShowHint;
    property TabOrder;
    property TabStop;
    property Text;
    property Top;
    property Visible;
    property Width;
    property WidthPercent;
    property OnChange;
    property OnClick;
    property OnDblClick;
    property OnKeyDown;
    property OnKeyPress;
    property OnKeyUp;
    property OnMouseDown;
    property OnMouseUp;
    property OnMouseMove;
    property OnMouseLeave;
    property OnMouseEnter;
    property OnEnter;
    property OnExit;
  end;

  TWebFontPicker = class(TFontPicker);

  TFontSizePickerMode = (fmPointSize, fmRelativeSize);

  TFontSizePicker = class(TCustomComboBox)
  private
    FPickerMode: TFontSizePickerMode;
    procedure SetPickerMode(const AValue: TFontSizePickerMode);
  protected
    procedure Init; virtual;
  public
    procedure CreateInitialize; override;
    property Items;
  published
    property Align;
    property AlignWithMargins;
    property Color;
    property ElementClassName;
    property ElementID;
    property ElementFont;
    property ElementPosition;
    property Enabled;
    property Font;
    property Height;
    property HeightPercent;
    property HeightStyle;
    property Hint;
    property ItemIndex;
    property Left;
    property PickerMode: TFontSizePickerMode read FPickerMode write SetPickerMode;
    property ShowHint;
    property TabOrder;
    property TabStop;
    property Text;
    property Top;
    property Visible;
    property Width;
    property WidthPercent;
    property WidthStyle;
    property OnChange;
    property OnClick;
    property OnDblClick;
    property OnKeyDown;
    property OnKeyPress;
    property OnKeyUp;
    property OnMouseDown;
    property OnMouseUp;
    property OnMouseMove;
    property OnMouseLeave;
    property OnMouseEnter;
    property OnEnter;
    property OnExit;
  end;

  TLineSpacingPicker = class(TCustomComboBox)
  private
  protected
    procedure CreateInitialize; override;
  public
    property Items;
  published
    property Align;
    property AlignWithMargins;
    property Color;
    property ElementClassName;
    property ElementID;
    property ElementFont;
    property ElementPosition;
    property Enabled;
    property Font;
    property Height;
    property HeightPercent;
    property HeightStyle;
    property Hint;
    property ItemIndex;
    property Left;
    property ShowHint;
    property TabOrder;
    property TabStop;
    property Text;
    property Top;
    property Visible;
    property Width;
    property WidthPercent;
    property WidthStyle;
    property OnChange;
    property OnClick;
    property OnDblClick;
    property OnKeyDown;
    property OnKeyPress;
    property OnKeyUp;
    property OnMouseDown;
    property OnMouseUp;
    property OnMouseMove;
    property OnMouseLeave;
    property OnMouseEnter;
    property OnEnter;
    property OnExit;
  end;


  TWebFontSizePicker = class(TFontSizePicker);

  TCaretPosition = TPoint;

  TCustomMemo = class(TWebCustomControl)
  private
    FWordWrap: Boolean;
    FBlockChange: Boolean;
    FLines: TStrings;
    FSelStart: Integer;
    FSelLength: Integer;
    FCaretPosition: TCaretPosition;
    FAutoSize: Boolean;
    FOnChange: TNotifyEvent;
    FReadOnly: boolean;
    FTextHint: string;
    FHandleChangePtr: pointer;
    FHandlePastePtr: pointer;
    FHandleCutPtr: pointer;
    FHandleInputPtr: pointer;
    FWantTabs: boolean;
    FAutoCompletion: TAutoCompletion;
    FSpellCheck: boolean;
    FModified: boolean;
    function GetText: String;
    procedure SetText(const Value: String);
    procedure SetSelLength(const Value: Integer);
    procedure SetSelStart(const Value: Integer);
    procedure SetAutoSize(const Value: Boolean);
    function GetElementInputHandle: TJSHTMLInputElement;
    procedure SetReadOnly(const Value: boolean);
    function GetSelLength: Integer;
    function GetSelStart: Integer;
    procedure SetTextHint(const Value: string);
    procedure SetAutoCompletion(const Value: TAutoCompletion);
    procedure SetSpellCheck(const Value: boolean);
    procedure SetWantTabs(const Value: boolean);
    procedure SetWordWrap(const Value: Boolean);
    function GetCaretPos: TPoint;
    procedure SetCaretPos(const Value: TPoint);
  protected
    function IsInputControl: Boolean; override;
    function HandleDoKeyDown(Event: TJSKeyBoardEvent): Boolean; override;
    function CreateElement: TJSElement; override;
    function DoHandlePaste(Event: TEventListenerEvent): Boolean; virtual;
    function DoHandleCut(Event: TEventListenerEvent): Boolean; virtual;
    function DoHandleInput(Event: TEventListenerEvent): Boolean; virtual;
    function DoHandleChange(Event: TEventListenerEvent): Boolean; virtual;
    function IsReadOnly: boolean; virtual;
    procedure PersistinHTML; override;
    function GetDisplayText: string; virtual;
    procedure BindEvents; override;
    procedure UnBindEvents; override;
    procedure UpdateElementData; override;
    procedure UpdateElementVisual; override;
    procedure SetLines(ALines: TStrings);
    procedure ClearMethodPointers; override;
    procedure GetMethodPointers; override;
    procedure DoLinesChange(Sender: TObject);
    function CanPaste(AValue: string): boolean; virtual;
    function CanCut: boolean; virtual;
    function SaveState: string; override;
    procedure LoadState(AState: string); override;
    property Font;
    property Color;
    property BorderStyle;
    property AutoSize: Boolean read FAutoSize write SetAutoSize;
    property Lines: TStrings read FLines write SetLines;
    property ReadOnly: boolean read FReadOnly write SetReadOnly;
    property OnClick;
    property OnDblClick;
    property OnChange: TNotifyEvent read FOnChange write FOnChange;
    procedure Change; virtual;
    procedure SetCaret(x,y: integer);
    function GetCaret: TPoint;
  public
    procedure CreateInitialize; override;
    destructor Destroy; override;
    procedure Clear;
    procedure SelectAll;
    procedure CopyToClipboard;
    property CaretPos: TPoint read GetCaretPos write SetCaretPos;
    property AutoCompletion: TAutoCompletion read FAutoCompletion write SetAutoCompletion default acOff;
    property CaretPosition: TCaretPosition read FCaretPosition write FCaretPosition;
    property ElementInputHandle: TJSHTMLInputElement read GetElementInputHandle;
    property Modified: boolean read FModified write FModified;
    property SelStart: Integer read GetSelStart write SetSelStart;
    property SelLength: Integer read GetSelLength write SetSelLength;
    property Spellcheck: boolean read FSpellCheck write SetSpellCheck default true;
    property Text: String read GetText write SetText;
    property TextHint: string read FTextHint write SetTextHint;
    property WantTabs: boolean read FWantTabs write SetWantTabs default false;
    property WordWrap: Boolean read FWordWrap write SetWordWrap default true;
  end;

  TMemo = class(TCustomMemo)
  published
    property Align;
    property AlignWithMargins;
    property Anchors;
    property AutoSize;
    property BiDiMode;
    property BorderStyle;
    property ChildOrder;
    property Color;
    property DragMode;
    property ElementClassName;
    property ElementID;
    property ElementFont;
    property ElementPosition;
    property Enabled;
    property Font;
    property Height;
    property HeightPercent;
    property HeightStyle;
    property Hint;
    property Left;
    property Lines;
    property Margins;
    property ParentColor;
    property ParentFont;
    property PopupMenu;
    property ReadOnly;
    property Role;
    property SelStart;
    property SelLength;
    property ShowFocus;
    property ShowHint;
    property SpellCheck;
    property TabOrder;
    property TabStop;
    property TextDirection;
    property Top;
    property Visible;
    property Width;
    property WidthPercent;
    property WidthStyle;
    property OnChange;
    property OnClick;
    property OnDblClick;
    property OnKeyDown;
    property OnKeyPress;
    property OnKeyUp;
    property OnMouseDown;
    property OnMouseUp;
    property OnMouseMove;
    property OnMouseLeave;
    property OnMouseEnter;
    property OnMouseWheel;
    property OnEnter;
    property OnExit;
    property OnDragDrop;
    property OnDragOver;
    property OnEndDrag;
    property OnStartDrag;
  end;

  TWebMemo = class(TMemo);

  TControlGroup = class(TWebCustomControl)
  private
    FControls: TList;
    FOldCount: integer;
    FColumns: integer;
    FItems: TStrings;
    FOnChange: TNotifyEvent;
    FElementButtonClassName: TElementClassName;
    FElementLabelClassName: TElementClassName;
    FElementGroupClassName: TElementClassName;
    FControlPosition: TElementPosition;
    FElementLegendClassName: TElementClassName;
    procedure SetControlPosition(const Value: TElementPosition);
  protected
    function CreateElement: TJSElement; override;
    function DoHandleChange(Event: TEventListenerEvent): Boolean;
    procedure DoHandleClick(AControl: TCustomControl); virtual;
    procedure DoItemsChange(Sender: TObject);
    procedure DoControlClick(Sender: TObject);
    procedure SetCaption(const AValue: string); override;
    procedure SetColumns(AValue: integer);
    procedure SetItems(AItems: TStrings);
    procedure DoUpdateList; virtual;
    procedure Change; virtual;
    procedure Loaded; override;
    procedure FontChanged; override;
    function CreateGroupControl(ALeft, ATop, AIndex: integer; ACaption: string; APosition: TElementPosition; AState, AEnabled: boolean): TCustomControl; virtual;
    function GetGroupControlState(AIndex: integer): boolean; virtual;
    function GetGroupControl(AIndex: integer): TCustomControl;
    procedure SetEnabled(Value: Boolean); override;
  public
    procedure CreateInitialize; override;
    destructor Destroy; override;
    procedure EndUpdate; override;
    procedure SetHeight(AValue: Integer); override;
    procedure SetWidth(AValue: Integer); override;
  published
    property Align;
    property AlignWithMargins;
    property Anchors;
    property Caption;
    property ChildOrder;
    property Columns: integer read FColumns write SetColumns;
    property ControlPosition: TElementPosition read FControlPosition write SetControlPosition default epAbsolute;
    property DragMode;
    property ElementClassName;
    property ElementButtonClassName: TElementClassName read FElementButtonClassName write FElementButtonClassName;
    property ElementGroupClassName: TElementClassName read FElementGroupClassName write FElementGroupClassName;
    property ElementLabelClassName: TElementClassName read FElementLabelClassName write FElementLabelClassName;
    property ElementLegendClassName: TElementClassName read FElementLegendClassName write FElementLegendClassName;
    property ElementID;
    property ElementFont;
    property ElementPosition;
    property Enabled;
    property Font;
    property Height;
    property HeightPercent;
    property HeightStyle;
    property Items: TStrings read FItems write SetItems;
    property Margins;
    property ParentFont;
    property PopupMenu;
    property Role;
    property Visible;
    property Width;
    property WidthPercent;
    property WidthStyle;
    property OnChange: TNotifyEvent read FOnChange write FOnChange;
    property OnDragDrop;
    property OnDragOver;
    property OnEndDrag;
    property OnStartDrag;
  end;

  TRadioGroup = class(TControlGroup)
  private
    FItemIndex: integer;
    FOldItemIndex: integer;
  protected
    procedure DoHandleClick(AControl: TCustomControl); override;
    function GetItemIndex: integer;
    procedure SetItemIndex(AIndex: integer);
    function CreateGroupControl(ALeft, ATop, AIndex: integer; ACaption: string; APosition: TElementPosition; AState, AEnabled: boolean): TCustomControl; override;
    function GetGroupControlState(AIndex: integer): boolean; override;
    procedure LoadState(AState: string); override;
    function SaveState: string; override;
  public
    procedure CreateInitialize; override;
    procedure SetFocus; override;
  published
    property ItemIndex: integer read GetItemIndex write SetItemIndex;
  end;

  TWebRadioGroup = class(TRadioGroup);

  TCheckClickEvent = procedure(Sender: TObject; AIndex: integer) of object;

  TCheckGroup = class(TControlGroup)
  private
    FOnCheckClick: TCheckClickEvent;
    function GetChecked(AIndex: integer): boolean;
    procedure SetChecked(AIndex: integer; const Value: boolean);
  protected
    procedure DoHandleClick(AControl: TCustomControl); override;
    function CreateGroupControl(ALeft, ATop, AIndex: integer; ACaption: string; APosition: TElementPosition; AState, AEnabled: boolean): TCustomControl; override;
    function GetGroupControlState(AIndex: integer): boolean; override;
    function SaveState: string; override;
    procedure LoadState(AState: string); override;
  public
    procedure CreateInitialize; override;
    property Checked[AIndex: integer]: boolean read GetChecked write SetChecked;
    procedure SetFocus; override;
  published
    property OnCheckClick: TCheckClickEvent read FOnCheckClick write FOnCheckClick;
  end;

  TWebCheckGroup = class(TCheckGroup);

  TColorPicker = class(TCustomInput)
  private
    FColor: TColor;
    FOnSelect: TNotifyEvent;
    FHandleChangePtr: pointer;
    function GetElementInputHandle: TJSHTMLInputElement;
  protected
    procedure UpdateElementVisual; override;
    function DoHandleChange(Event: TEventListenerEvent): Boolean;
    function GetInputType: string; override;
    function GetColor: TColor;
    procedure SetColor(AValue: TColor); reintroduce;
    procedure BindEvents; override;
    procedure UnBindEvents; override;
    procedure Select; virtual;
    procedure ClearMethodPointers; override;
    procedure GetMethodPointers; override;
  public
    procedure CreateInitialize; override;
    property ElementInputHandle: TJSHTMLInputElement read GetElementInputHandle;
    property Selected: TColor read GetColor write SetColor;
  published
    property Align;
    property AlignWithMargins;
    property Anchors;
    property ChildOrder;
    property Color: TColor read GetColor write SetColor;
    property DragMode;
    property ElementClassName;
    property ElementID;
    property ElementFont;
    property ElementPosition;
    property Enabled;
    property Height;
    property HeightPercent;
    property Left;
    property Margins;
    property Role;
    property Top;
    property Visible;
    property Width;
    property WidthPercent;
    property OnClick;
    property OnSelect: TNotifyEvent read FOnSelect write FOnSelect;
    property OnDragDrop;
    property OnDragOver;
    property OnEndDrag;
    property OnStartDrag;
  end;

  TWebColorPicker = class(TColorPicker);

  TScrollBarContent = class(TCustomControl)
  protected
    function CreateElement: TJSElement; override;
  end;

  TScrollBarInc = Integer;

  TScrollBar = class(TCustomControl)
  private
    FScrolling: Boolean;
    FContent: TScrollBarContent;
    FKind: TScrollBarKind;
    FPosition: Integer;
    FSmallChange: TScrollBarInc;
    FMax: Integer;
    FMin: Integer;
    FLargeChange: TScrollBarInc;
    FPageSize: Integer;
    FOnChange: TNotifyEvent;
    FScrollPtr: pointer;
    procedure SetKind(const Value: TScrollBarKind);
    procedure SetPosition(const Value: Integer);
    procedure SetMax(const Value: Integer);
    procedure SetMin(const Value: Integer);
    procedure SetPageSize(const Value: Integer);
    function GetPosition: Integer;
    function GetPageSize: Integer;
  protected
    function GetValue(XYPos: Double): Double;
    function CreateElement: TJSElement; override;
    function DoScroll(Event: TJSUIEvent): Boolean; virtual;
    procedure BindEvents; override;
    procedure UnBindEvents; override;
    procedure UpdateElementVisual; override;
    procedure UpdateContent; virtual;
    procedure Loaded; override;
    procedure ClearMethodPointers; override;
    procedure GetMethodPointers; override;
  public
    procedure CreateInitialize; override;
    destructor Destroy; override;
    procedure SetBounds(X, Y, AWidth, AHeight: Integer); override;
  published
    property Align;
    property AlignWithMargins;
    property DragMode;
    property Height;
    property Kind: TScrollBarKind read FKind write SetKind;
    property Position: Integer read GetPosition write SetPosition;
    property Left;
    property SmallChange: TScrollBarInc read FSmallChange write FSmallChange;
    property LargeChange: TScrollBarInc read FLargeChange write FLargeChange;
    property Max: Integer read FMax write SetMax;
    property Min: Integer read FMin write SetMin;
    property PageSize: Integer read GetPageSize write SetPageSize;
    property Top;
    property Visible;
    property Width;
    property OnChange: TNotifyEvent read FOnChange write FOnChange;
    property OnDragDrop;
    property OnDragOver;
    property OnEndDrag;
    property OnStartDrag;
  end;

  TWebScrollBar = class(TScrollBar);

function GetAutoCompletionName(aAutoCompletion: TAutoCompletion): string;

implementation

function GetAutoCompletionName(aAutoCompletion: TAutoCompletion): string;
begin
  case aAutoCompletion of
    acOff: Result := '';
    acHonorificPrefix: Result := 'honorific-prefix';
    acGivenName: Result := 'given-name';
    acAdditionalName: Result := 'additional-name';
    acFamilyName: Result := 'family-name';
    acHonorificSuffix: Result := 'honorific-suffix';
    acNickName: Result := 'nickname';
    acEmail: Result := 'email';
    acUserName: Result := 'username';
    acNewPassword: Result := 'new-password';
    acCurrentPassword: Result := 'current-password';
    acOrganizationTitle: Result := 'organization-title';
    acOrganization: Result := 'organization';
    acStreetAddress: Result := 'street-address';
    acAddressLine1: Result := 'address-line1';
    acAddressLine2: Result := 'address-line2';
    acAddressLine3: Result := 'address-line3';
    acAddressLevel1: Result := 'address-level1';
    acAddressLevel2: Result := 'address-level2';
    acAddressLevel3: Result := 'address-level3';
    acAddressLevel4: Result := 'address-level4';
    acCountry: Result := 'country';
    acCountryName: Result := 'country-name';
    acPostalCode: Result := 'postal-code';
    acCCName: Result := 'cc-name';
    acCCGivenName: Result := 'cc-given-name';
    acCCAdditionalName: Result := 'cc-additional-name';
    acCCFamilyName: Result := 'cc-family-name';
    acCCNumber: Result := 'cc-number';
    acExpiry: Result := 'cc-exp';
    acExpiryMonth: Result := 'cc-exp-month';
    acExpiryYear: Result := 'cc-exp-year';
    acCSC: Result := 'cc-csc';
    acType: Result := 'cc-type';
    acTransactionCurrency: Result := 'transaction-currency';
    acTransactionAmount: Result := 'transaction-amount';
    acLanguage: Result := 'language';
    acBirthday: Result := 'bday';
    acBirthdayDay: Result := 'bday-day';
    acBirthDayMonth: Result := 'bday-month';
    acBirthDayYear: Result := 'bday-year';
    acSex: Result := 'sex';
    acTelephone: Result := 'tel';
    acTelephoneCountryCode: Result := 'tel-country-code';
    acTelephoneNational: Result := 'tel-national';
    acTelephoneAreaCode: Result := 'tel-area-code';
    acTelephoneLocal: Result := 'tel-local';
    acTelephoneExtension: Result := 'tel-extension';
    acIMPP: Result := 'impp';
    acURL: Result := 'url';
    acPhoto: Result := 'photo';
    acNope: Result := 'nope';
  end;
end;

{ TCustomLabel }

procedure TCustomLabel.CreateInitialize;
begin
  inherited;
  FAutoSize := true;
  FLayout := tlTop;
  FEllipsisPosition := epNone;
  FColor := clWhite;
  FTransparent := True;
  FAlignment := taLeftJustify;
  TabStop := false;
  FShowAccelChar := true;
end;

function TCustomLabel.CanShowFocus: boolean;
begin
  Result := false;
end;

function TCustomLabel.CreateElement: TJSElement;
begin
  Result := document.createElement('DIV');

  FContent := CreateLabelElement;
  Result.appendChild(FContent);
end;

function TCustomLabel.CreateLabelElement: TJSElement;
begin
  Result := nil;

  case HTMLType of
  tSPAN: Result := document.createElement('SPAN');
  tDIV: Result := document.createElement('DIV');
  tH1: Result := document.createElement('H1');
  tH2: Result := document.createElement('H2');
  tH3: Result := document.createElement('H3');
  tH4: Result := document.createElement('H4');
  tH5: Result := document.createElement('H5');
  tH6: Result := document.createElement('H6');
  tLABELTAG: Result := document.createElement('LABEL');
  tP: Result := document.createElement('P');
  end;

  Result.setAttribute('data-lbl','lbl');
end;

procedure TCustomLabel.BindElement;
begin
  FContent := Container.firstElementChild;
end;

function TCustomLabel.GetContentHandle: TJSHTMLElement;
begin
  Result := TJSHTMLElement(FContent);
end;

function TCustomLabel.GetDisplayText: string;
begin
  Result := Caption;
end;

function TCustomLabel.GetHeight: Integer;
begin
  if AutoSize and Assigned(ElementHandle) and Assigned(Parent) then
  begin
    Result := Round(TJSHTMLElement(ElementHandle).offsetHeight);
    if Result = 0 then
       Result := inherited GetHeight;

    if (Result = 0) and (Caption <> '') and (csDesigning in ComponentState) then
      Result := FOldHeight;
  end
  else
    Result := inherited GetHeight;
end;

function TCustomLabel.GetWidth: Integer;
begin
  if AutoSize and Assigned(ElementHandle) and Assigned(Parent) then
  begin
    Result := Round(TJSHTMLElement(ElementHandle).offsetWidth);

    if Result = 0 then
      Result := inherited GetWidth;

    if (Result = 0) and (Caption <> '') and (csDesigning in ComponentState) then
      Result := FOldWidth;
  end
  else
    Result := inherited GetWidth;
end;

procedure TCustomLabel.Loaded;
begin
  inherited;

  if ShowAccelChar and Assigned(FocusControl) then
    UpdateElementData;
end;

procedure TCustomLabel.SetAutoSize(AValue: boolean);
begin
  if (FAutoSize <> AValue) then
  begin
    FAutoSize := AValue;
    UpdateAutoSize;
    UpdateElement;
  end;
end;

procedure TCustomLabel.SetCaption(const AValue: string);
var
  dw: integer;
begin
  if Caption <> AValue then
  begin
    dw := Width;
    inherited SetCaption(AValue);
    UpdateAutoSize;
    UpdateElement;
    dw := dw - Width;
    if AutoSize and (Alignment <> taLeftJustify) and (dw <> 0) and (akRight in Anchors) and not IsUpdating then
      Left := Left + dw;
  end;
end;

procedure TCustomLabel.SetColorEx(const Value: TColor);
begin
  FColor := Value;

  if (csDesigning in ComponentState) and (Color <> clWhite) then
    Transparent := false;

  UpdateElement;
end;

procedure TCustomLabel.SetControlCursor(const Value: TCursor);
begin
  inherited;
  if (ElementClassName = '') and Assigned(ContentHandle) then
    SetElementPointer(ContentHandle, Cursor);
end;

procedure TCustomLabel.SetElementLabelClassName(const Value: TElementClassName);
begin
  if (FElementLabelClassName <> Value) then
  begin
    FElementLabelClassName := Value;
    UpdateElement;
  end;
end;

procedure TCustomLabel.SetEllipsisPosition(AValue: TEllipsisPosition);
begin
  if FEllipsisPosition <> AValue then
  begin
    FEllipsisPosition := AValue;
    if FEllipsisPosition <> epNone then
      FAutoSize := False;
    UpdateElement;
  end;
end;

procedure TCustomLabel.SetHTML(const Value: string);
begin
  if (FHTML <> Value) then
  begin
    FHTML := Value;
    UpdateElementData;
  end;
end;

procedure TCustomLabel.SetHTMLType(const Value: THTMLType);
var
  el: TJSElement;
begin
  if (FHTMLType <> Value) then
  begin
    FHTMLType := Value;

    if FHTMLType in [tH1..tH6] then
      ElementFont := efCSS;

    if Assigned(ElementHandle) and Assigned(FContent) then
    begin
      el := TJSElement(ElementHandle.firstChild);

      if Assigned(el) and (el.getAttribute('data-lbl') = 'lbl') then
      begin
        ElementHandle.removeChild(FContent);
        FContent := CreateLabelElement;
        ElementHandle.appendChild(FContent);
        UpdateElement;
      end;
    end;
  end;
end;

procedure TCustomLabel.SetLayout(const Value: TTextLayout);
begin
  if FLayout <> Value then
  begin
    FLayout := Value;
    UpdateElementVisual;
  end;
end;

procedure TCustomLabel.SetParent(AValue: TControl);
begin
  inherited;
  if AutoSize and (Alignment <> taLeftJustify) and (FOrigWidth <> -1) then
  begin
    Left := Left + FOrigWidth - Width;
    FOrigWidth := -1;
  end;
end;

procedure TCustomLabel.SetTransparent(const Value: boolean);
begin
  if (FTransparent <> Value) then
  begin
    FTransparent := Value;
    UpdateElementVisual;
  end;
end;

procedure TCustomLabel.SetWidth(AValue: Integer);
begin
  if (AValue <> -1) and (csLoading in ComponentState) then
    FOrigWidth := AValue;

  inherited;
end;

procedure TCustomLabel.SetAlignment(const Value: TAlignment);
begin
  if FAlignment <> Value then
  begin
    FAlignment := Value;
    UpdateElementVisual;
  end;
end;

procedure TCustomLabel.UpdateAutoSize;
begin
  if FAutoSize and (Align = alNone) then
  begin
    if Assigned(ElementHandle) and ElementVisible(ElementHandle) {and not (csDesigning in ComponentState)} then
    begin
      Width := -1;
      Height := -1;
    end;
    FEllipsisPosition := epNone;
  end;
end;

procedure TCustomLabel.UpdateElementData;
var
  lTxt: string;
  acc: string;
  contentelement: TJSElement;
begin
  inherited;

  acc := '';

  if ShowAccelChar then
    lTxt := ProcessAccelerator(GetDisplayText,acc)
  else
    lTxt := GetDisplayText;

  if HTML = '' then
    lTxt := HTMLToString(lTxt)
  else
    lTxt := HTML;

  if IsLinked and (lTxt = '') and (csLoading in ComponentState) then
    Exit;

  if Assigned(ContentHandle) then
  begin
    if isLinked then
    begin
      contentelement := ContentHandle;

      if (ElementID <> '') then
      begin
        contentelement := document.getElementById(ElementID);
      end;

      if Assigned(contentelement) then
      begin
        if Assigned(ContentHandle.nodeValue) then
          contentelement.nodeValue := lTxt
        else
          if contentelement.childelementcount = 0 then
            contentelement.innerHTML := lTxt;
      end;
    end
    else
    begin
      if (ContentHandle.childElementCount > 0) and not FHasAccel and not FHasHTML then
        ContentHandle.nodeValue := lTxt
      else
        ContentHandle.innerHTML := lTxt
    end;
  end
  else
    ElementHandle.innerHTML := lTxt;

  FHasHTML := pos('</', lTxt) > 0;
  FHasAccel := acc <> '';

  // https://www.htmlgoodies.com/tutorials/html_401/article.php/3479621
  if (acc <> '') and Assigned(FocusControl) then
  begin
    ContentHandle['accesskey'] := acc;
    ContentHandle['for'] := FocusControl.GetID;
  end;
end;

procedure TCustomLabel.UpdateElementSize;
begin
  inherited;

  if AutoSize and (Align = alNone) and Assigned(ElementHandle) then
  begin
    if not (csDesigning in ComponentState) then
    begin
      ElementHandle.style.removeProperty('width');
      ElementHandle.style.removeProperty('height');
    end;
  end;

  if Assigned(ContentHandle) and (Align in [alLeft, alRight, alClient]) and not AutoSize and (Layout in [tlCenter, tlBottom]) then
  begin
    ContentHandle.style.setProperty('height', Height.ToString+'px');
  end;
end;

procedure TCustomLabel.UpdateElementVisual;
var
  ow,oh: integer;
begin
  inherited;

  if IsUpdating then
    Exit;

  if Assigned(ElementHandle) then
  begin
    oh := Round(TJSHTMLElement(ElementHandle).offsetHeight);
    ow := Round(TJSHTMLElement(ElementHandle).offsetWidth);

    if oh > 0 then
      FOldHeight := oh;
    if ow > 0 then
      FOldWidth := ow;

    if Visible and (not AutoSize or (Align <> alNone)) and (Alignment <> taLeftJustify) then
      ElementHandle.style.setProperty('display', 'table');

    ElementHandle['zindex'] := '1';

    if AutoSize and (Align = alNone) and not (csDesigning in ComponentState) then
      ElementHandle.style.setProperty('overflow', '')
    else
      ElementHandle.style.setProperty('overflow', 'hidden');
  end;

  if Assigned(ContentHandle) then
  begin
    case Layout of
      tlTop: ContentHandle.style.setProperty('vertical-align', 'top');
      tlCenter: ContentHandle.style.setProperty('vertical-align', 'middle');
      tlBottom: ContentHandle.style.setProperty('vertical-align', 'bottom');
    end;

    case Alignment of
    taLeftJustify: ContentHandle.style.removeProperty('text-align');
    taCenter: ContentHandle.style.setProperty('text-align', 'center');
    taRightJustify: ContentHandle.style.setProperty('text-align', 'right');
    end;

    SetHTMLElementColor(ElementHandle, Color, Transparent or (Color = clNone) or (Color = clWindow));
    SetHTMLElementColor(ContentHandle, Color, Transparent or (Color = clNone) or (Color = clWindow));

    ContentHandle.style.setProperty('display', 'table-cell');

    if ElementClassName = '' then
    begin
      if Enabled and (ElementFont = efProperty) and not IsLinked then
        ContentHandle.style.setProperty('color', ColorToHtml(Font.Color))
      else
        ContentHandle.style.removeProperty('color');

      SetElementPointer(ContentHandle, Cursor);
      SetHTMLElementFont(ContentHandle, Font, not ((ElementFont = efProperty) and not IsLinked) );
    end
    else
    begin
      ContentHandle.style.removeProperty('color');
      SetHTMLElementFont(ContentHandle, Font, true);
    end;

    if ElementLabelClassName <> '' then
      ContentHandle['class'] := ElementLabelClassName
    else
      ContentHandle.removeAttribute('class');

    if (ElementPosition = epAbsolute) or (WidthStyle = ssAbsolute) then
    begin
      if FEllipsisPosition = epNone then
        ContentHandle.style.setProperty('text-overflow', 'clip')
      else
        ContentHandle.style.setProperty('text-overflow', 'ellipsis');
    end
    else
      ContentHandle.style.removeProperty('text-overflow');

    if WordWrap then
      ContentHandle.style.setProperty('white-space', 'normal')
    else
      ContentHandle.style.setProperty('white-space', 'nowrap');

    // allow selection in labels
    ElementHandle.style.setProperty('user-select', '');

    // allow default caret on text
    if Cursor = crDefault then
      ElementHandle.style.setProperty('cursor', '');

    if AutoSize and (Align = alNone) and not (csDesigning in ComponentState) then
    begin
      ElementHandle.style.removeProperty('width');
      ElementHandle.style.removeProperty('height');
    end;
  end;
end;

{ TCustomInput }

function TCustomInput.CreateElement: TJSElement;
begin
  Result := document.createElement('INPUT');
  Result['type'] := GetInputType;
end;

procedure TCustomInput.UpdateElementVisual;
begin
  inherited;

  if Assigned(Container) and not IsLinked then
  begin
    TJSHTMLInputElement(Container).style.setProperty('-moz-box-sizing', 'border-box');
    TJSHTMLInputElement(Container).style.setProperty('-webkit-box-sizing', 'border-box');
    TJSHTMLInputElement(Container).style.setProperty('box-sizing', 'border-box');
  end;
end;

procedure TCustomInput.CreateInitialize;
begin
  inherited;
  ShowFocus := true;
  NoUserSelect := false;
end;

function TCustomInput.GetInputType: string;
begin
   Result := 'EDIT';
end;

function TCustomInput.IsInputControl: Boolean;
begin
  Result := True;
end;

procedure TCustomInput.LoadState(AState: string);
begin
  TJSHTMLInputElement(ElementHandle).value := AState;
end;

function TCustomInput.SaveState: string;
begin
  Result := TJSHTMLInputElement(ElementHandle).value;
end;

{ TCustomEdit }

procedure TCustomEdit.BindEvents;
begin
  inherited;
  if Assigned(ElementInputHandle) then
  begin
    ElementInputHandle.addEventListener('input', FHandleChangePtr);
    ElementInputHandle.addEventListener('paste', FHandlePastePtr);
    ElementInputHandle.addEventListener('cut', FHandleCutPtr);
    ElementInputHandle.addEventListener('invalid', FHandleInvalidPtr);
  end;
end;

procedure TCustomEdit.UnBindEvents;
begin
  inherited;
  if Assigned(ElementInputHandle) then
  begin
    ElementInputHandle.removeEventListener('input', FHandleChangePtr);
    ElementInputHandle.removeEventListener('paste', FHandlePastePtr);
    ElementInputHandle.removeEventListener('cut', FHandleCutPtr);
    ElementInputHandle.removeEventListener('invalid', FHandleInvalidPtr);
  end;
end;


procedure TCustomEdit.Change;
begin
  if Assigned(ElementHandle) then
    FText := ElementInputHandle.value;

  if Assigned(OnChange) then
    OnChange(Self);
end;

procedure TCustomEdit.Clear;
begin
  Text := '';
end;

procedure TCustomEdit.ClearMethodPointers;
begin
  inherited;
  FHandlePastePtr := nil;
  FHandleCutPtr := nil;
  FHandleChangePtr := nil;
  FHandleInvalidPtr := nil;
end;

procedure TCustomEdit.ClearSelection;
var
  s: string;
begin
  s := Text;
  Text := Copy(s, 1, SelStart) + Copy(s, SelStart + SelLength, Length(s));
end;

{$HINTS OFF}
procedure TCustomEdit.CopyToClipboard;
var
  s: string;
begin
  s := Text;
  asm
    navigator.clipboard.writeText(s);
  end;
end;
{$HINTS ON}

procedure TCustomEdit.CreateInitialize;
begin
  inherited;
  FAutoCompletion := acOff;
  FEditType := weString;
  FText := '';
  FCharCase := wecNormal;
  FMaxLength := 0;
  FReadOnly := false;
  FTextHint := '';
  FPasswordChar := #0;
  FSpellCheck := true;
  FAutoSelect := true;
  Height := 25;
end;

{$HINTS OFF}
procedure TCustomEdit.DoEnter;
var
  el: TJSHTMLElement;
begin
  inherited;
  if AutoSelect and Assigned(ElementInputHandle) then
  begin
    el := ElementInputHandle;
    asm
      el.select();
    end;
  end;
end;
{$HINTS ON}

function TCustomEdit.DoHandleChange(Event: TEventListenerEvent): Boolean;
begin
  Change;
  Result := True;
  if FRequiredText <> '' then
  begin
    ElementInputHandle.setCustomValidity('');
  end;
end;

function TCustomEdit.DoHandleCut(Event: TEventListenerEvent): Boolean;
begin
  if not CanCut then
  begin
    asm
      Event.preventDefault();
      Event.stopPropagation();
    end;
  end;

  Result := False;
end;

function TCustomEdit.DoHandleInvalid(Event: TJSEvent): Boolean;
begin
  ElementInputHandle.setCustomValidity(FRequiredText);
  Result := true;
end;

function TCustomEdit.DoHandlePaste(Event: TEventListenerEvent): Boolean;
var
  s: string;

begin
  asm
    var clipboardData = Event.clipboardData || window.clipboardData;
    s = clipboardData.getData('Text');
  end;

  if not CanPaste(s) or not Validate(s) then
  begin
    asm
      Event.preventDefault();
      Event.stopPropagation();
    end;
  end;

  Result := False;
end;

function TCustomEdit.CanCut: boolean;
begin
  Result := True
end;

function TCustomEdit.CanPaste(AValue: string): boolean;
begin
  Result := True;
end;

procedure TCustomEdit.SetReadOnly(AValue: Boolean);
begin
  if (FReadOnly <> AValue) then
  begin
    FReadOnly := AValue;
    UpdateElement;
  end;
end;

procedure TCustomEdit.SetRequired(const Value: Boolean);
begin
  FRequired := Value;
  UpdateElement;
end;

procedure TCustomEdit.SetSelection(ASelStart, ASelLength: integer);
var
  eh: TJSHTMLElement;
begin
  FSelStart := ASelStart;
  FSelLength := ASelLength;
  eh := ElementInputHandle;
  if Assigned(eh) then
  begin
    asm
      eh.setSelectionRange(ASelStart, ASelStart + ASelLength);
    end;
  end;
end;

procedure TCustomEdit.SetSelLength(const Value: Integer);
begin
  FSelLength := Value;
  UpdateElement;
end;

procedure TCustomEdit.SetSelStart(const Value: Integer);
begin
  FSelStart := Value;
  UpdateElement;
end;

procedure TCustomEdit.SetSpellCheck(const Value: boolean);
begin
  if (FSpellCheck <> Value) then
  begin
    FSpellCheck := Value;
    UpdateElement;
  end;
end;

procedure TCustomEdit.SetPasswordChar(const Value: Char);
begin
  FPasswordChar := Value;
  UpdateElement;
end;

procedure TCustomEdit.SetPattern(const Value: string);
begin
  if FPattern <> Value then
  begin
    FPattern := Value;
    UpdateElement;
  end;
end;

procedure TCustomEdit.SetNumeric(const Value: Boolean);
begin
  FNumeric := Value;
  UpdateElement;
end;

procedure TCustomEdit.SetText(AValue: string);
begin
  FText := AValue;

  if Assigned(ElementInputHandle) then
  begin
    ElementInputHandle.value := GetDisplayText;
    ElementInputHandle.readOnly := IsReadOnly;
  end;
end;

function TCustomEdit.GetText: string;
begin
  Result := FText;
  if Assigned(ElementInputHandle) then
    Result := ElementInputHandle.value;

  if CharCase = wecUpperCase  then
    Result := Uppercase(Result);

  if CharCase = wecLowerCase  then
    Result := Lowercase(Result);
end;

function TCustomEdit.IsCustomEditor: Boolean;
begin
  Result := False;
end;

function TCustomEdit.IsReadOnly: boolean;
begin
  Result := ReadOnly;
end;

procedure TCustomEdit.KeyPress(var Key: Char);
var
  isValid: boolean;

begin
  inherited KeyPress(Key);

  isValid := true;

  case EditType of
  weNumeric: isValid := (Key in ['0'..'9']);
  weSignedNumeric: isValid := (Key in ['0'..'9','+','-']);
  weFloat: isValid := (Key in ['0'..'9',',','.']);
  weSignedFloat: isValid := (Key in ['0'..'9',',','.','+','-']);
  weHex: isValid := (Key in ['0'..'9','A'..'F']);
  end;

  if not isValid then
    Key := #0;
end;

procedure TCustomEdit.SetTextHint(AValue: string);
begin
  FTextHint := AValue;
  UpdateElement;
end;

{$HINTS OFF}
procedure TCustomEdit.UpdateElementData;
var
  eh: TJSHTMLElement;
  ss, sl: Integer;
  isNum, doAc: boolean;
begin
  inherited;

  if Assigned(ElementInputHandle) then
  begin
    if not IsLinked then
    begin
      case CharCase of
        wecUpperCase: ElementInputHandle.style.setProperty('text-transform', 'uppercase');
        wecLowerCase: ElementInputHandle.style.setProperty('text-transform', 'lowercase');
        wecMixedCase: ElementInputHandle.style.setProperty('text-transform', 'capitalize');
        wecNormal: ElementInputHandle.style.setProperty('text-transform', 'initial');
      end;
    end;

    ElementInputHandle.readOnly := IsReadOnly;

    if TextHint <> '' then
      ElementInputHandle.placeholder := TextHint;

    doAc := true;

    if IsLinked then
    begin
      doAc :=  not TJSHTMLElement(ElementInputHandle).hasAttribute('autocomplete');
    end;

    if doAc then
    begin
      if AutoCompletion = acNope then
      begin
        TJSHTMLElement(ElementInputHandle).setAttribute('autocomplete','nope');
        if not IsLinked then
          TJSHTMLElement(ElementInputHandle).removeAttribute('name');
      end
      else
      if AutoCompletion = acNone then
      begin
        TJSHTMLElement(ElementInputHandle).setAttribute('autocomplete','off');
        if not IsLinked then
          TJSHTMLElement(ElementInputHandle).removeAttribute('name');
      end
      else
      if AutoCompletion <> acOff then
      begin
        TJSHTMLElement(ElementInputHandle).setAttribute('autocomplete','on');
        if not IsLinked then
          TJSHTMLElement(ElementInputHandle).setAttribute('name', GetAutoCompletionName(AutoCompletion));
      end
      else
      begin
        TJSHTMLElement(ElementInputHandle).removeAttribute('autocomplete');
        if not IsLinked then
          TJSHTMLElement(ElementInputHandle).removeAttribute('name');
      end;
   end;

    if not isLinked then
    begin
      ElementInputHandle['type'] := GetInputType;
      ElementInputHandle['role'] := 'textbox';

      case Alignment of
      taLeftJustify: TJSHTMLElement(ElementInputHandle).style.removeProperty('text-align');
      taCenter: TJSHTMLElement(ElementInputHandle).style.setProperty('text-align', 'center');
      taRightJustify: TJSHTMLElement(ElementInputHandle).style.setProperty('text-align', 'right');
      end;
    end;

    if AutoFocus then
      ElementInputHandle.setAttribute('autofocus','')
    else
      ElementInputHandle.removeAttribute('autofocus');

    if Required then
      ElementInputHandle.setAttribute('required','')
    else
      ElementInputHandle.removeAttribute('required');

    if FPattern <> '' then
      ElementInputHandle.setAttribute('pattern',FPattern)
    else
      ElementInputHandle.removeAttribute('pattern');

    if MaxLength <= 0 then
      ElementInputHandle.removeAttribute('maxLength')
    else
      ElementInputHandle.maxLength := MaxLength;

    ElementInputHandle.value := GetDisplayText;

    isNum := Uppercase(GetInputType) = 'NUMBER';

    if not SpellCheck then
      ElementInputHandle['spellcheck'] := 'false'
    else
      ElementInputHandle.removeAttribute('spellcheck');

    if (not isNum) and (not isLinked) and (not IsCustomEditor) then
    begin
      ss := FSelStart;
      sl := FSelStart + FSelLength;
      eh := ElementInputHandle;
      asm
        setTimeout(function() {
          eh.setSelectionRange(ss, sl);
        }, 1);
      end;
    end;

  end;
end;

{$HINTS ON}

function TCustomEdit.Validate(AValue: string): boolean;
var
  i: integer;
  Key: char;
  isvalid: boolean;
begin
  Result := true;

  for i := 1 to Length(AValue) do
  begin
    Key := AValue[i];
    isValid := true;

    case EditType of
    weNumeric: isValid := (Key in ['0'..'9']);
    weSignedNumeric: isValid := (Key in ['0'..'9','+','-']);
    weFloat: isValid := (Key in ['0'..'9',',','.']);
    weSignedFloat: isValid := (Key in ['0'..'9',',','.','+','-']);
    weHex: isValid := (Key in ['0'..'9','A'..'F']);
    end;

    if not IsValid then
    begin
      Result := false;
      break;
    end;
  end;
end;

procedure TCustomEdit.SelectAll;
begin
  if Assigned(ElementInputHandle) then
    ElementInputHandle.select;
end;

procedure TCustomEdit.SetAlignment(const Value: TAlignment);
begin
  FAlignment := Value;
  UpdateElement;
end;

procedure TCustomEdit.SetAutoCompletion(const Value: TAutoCompletion);
begin
  if (FAutoCompletion <> Value) then
  begin
    FAutoCompletion := Value;
    UpdateElement;
  end;
end;

procedure TCustomEdit.SetAutoFocus(const Value: boolean);
begin
  if (FAutoFocus <> Value) then
  begin
    FAutoFocus := Value;
    UpdateElement;
  end;
end;

procedure TCustomEdit.SetAutoSelect(const Value: Boolean);
begin
  if FAutoSelect <> Value then
  begin
    FAutoSelect := Value;
    UpdateElement;
  end;
end;

procedure TCustomEdit.SetAutoSize(const Value: Boolean);
begin
  if FAutoSize <> Value then
  begin
    FAutoSize := Value;
    UpdateElement;
  end;
end;

procedure TCustomEdit.SetCharCase(AValue: TEditCharCase);
begin
  if (FCharcase <> AValue) then
  begin
    FCharcase := AValue;
    UpdateElement;
  end;
end;

procedure TCustomEdit.SetEditType(const Value: TEditType);
begin
  if (FEditType <> Value) then
  begin
    FEditType := Value;
    UpdateElement;
  end;
end;

procedure TCustomEdit.SetHideSelection(const Value: Boolean);
begin
  if (FHideSelection <> Value) then
  begin
    FHideSelection := Value;
    UpdateElement;
  end;
end;

procedure TCustomEdit.SetMaxLength(AValue: integer);
begin
  if (FMaxLength <> AValue) then
  begin
    FMaxLength := AValue;
    UpdateElementData;
  end;
end;

function TCustomEdit.GetDisplayText: string;
begin
  Result := FText;
end;

function TCustomEdit.GetElementInputHandle: TJSHTMLInputElement;
begin
  Result := TJSHTMLInputElement(Container);
end;

function TCustomEdit.GetInputType: string;
begin
  if (PasswordChar <> #0) then
    Result := 'PASSWORD'
  else if Numeric then
    Result := 'NUMBER'
  else
    if (EditType in [weFloat, weNumeric]) then
      Result := 'TEL'
    else
      if EditType = weSearch then
        Result := 'SEARCH'
      else
        Result := 'TEXT';
end;

procedure TCustomEdit.GetMethodPointers;
begin
  inherited;
  FHandlePastePtr := @DoHandlePaste;
  FHandleCutPtr := @DoHandleCut;
  FHandleChangePtr := @DoHandleChange;
  FHandleInvalidPtr := @DoHandleInvalid;
end;

function TCustomEdit.GetSelLength: Integer;
begin
  Result := -1;
  if Assigned(ElementInputHandle) then
    Result := ElementInputHandle.selectionEnd - ElementInputHandle.selectionStart;
end;

function TCustomEdit.GetSelStart: Integer;
begin
  Result := -1;
  if Assigned(ElementInputHandle) then
    Result := ElementInputHandle.selectionStart;
end;

function TCustomEdit.GetSelText: string;
begin
  Result := Copy(Text,SelStart + 1, SelLength);
end;

procedure TCustomEdit.PersistinHTML;
begin
  ElementInputHandle.setAttribute('value', Text);
end;

{ TSpinEdit }

procedure TSpinEdit.CreateInitialize;
begin
  inherited;
  FIncrement := 1;
  FMaxValue := 100;
  FMinValue := 0;
  ShowFocus := true;
  Height := 25;

  // make the spin buttons always visible
  AddControlStyle('input[type="number"]::-webkit-inner-spin-button { opacity: 1 !important; }');
end;

procedure TSpinEdit.BindEvents;
begin
  inherited;
  if Assigned(ElementInputHandle) then
  begin
    ElementInputHandle.addEventListener('input', FHandleChangePtr);
    ElementInputHandle.addEventListener('invalid', FHandleInvalidPtr);
  end;
end;

procedure TSpinEdit.UnBindEvents;
begin
  inherited;
  if Assigned(ElementInputHandle) then
  begin
    ElementInputHandle.removeEventListener('input', FHandleChangePtr);
    ElementInputHandle.removeEventListener('invalid', FHandleInvalidPtr);
  end;
end;

function TSpinEdit.GetDisplayText: string;
begin
  Result := IntToStr(FValue);
end;

function TSpinEdit.GetElementInputHandle: TJSHTMLInputElement;
begin
  Result := TJSHTMLInputElement(Container);
end;

function TSpinEdit.DoHandleChange(Event: TEventListenerEvent): Boolean;
begin
  Change;
  Result := True;
  if FRequiredText <> '' then
  begin
    ElementInputHandle.setCustomValidity('');
  end;
end;

function TSpinEdit.DoHandleInvalid(Event: TJSEvent): Boolean;
begin
  ElementInputHandle.setCustomValidity(FRequiredText);
  Result := true;
end;

procedure TSpinEdit.Change;
begin
  FValue := GetValue;

  if Assigned(OnChange) then
    OnChange(Self);
end;

procedure TSpinEdit.ClearMethodPointers;
begin
  inherited;
  FHandleChangePtr := nil;
  FHandleInvalidPtr := nil;
end;

function TSpinEdit.GetInputType: string;
begin
  Result := 'NUMBER';
end;

procedure TSpinEdit.GetMethodPointers;
begin
  inherited;
  FHandleChangePtr := @DoHandleChange;
  FHandleInvalidPtr := @DoHandleInvalid;
end;

function TSpinEdit.GetText: string;
begin
  Result := IntToStr(Value);
end;

function TSpinEdit.GetValue: integer;
var
  s: string;
begin
  Result := FValue;

  if not Assigned(Container) then
    Exit;

  s := TJSHTMLInputElement(Container).value;
  if (s <> '') then
    Result := StrToInt(s);
end;

function TSpinEdit.IsReadOnly: boolean;
begin
  Result := FReadOnly;
end;

procedure TSpinEdit.KeyPress(var Key: Char);
begin
  if not (Key in ['0'..'9']) then
  begin
    Key := #0;
    PreventDefault;
    StopPropagation;
  end;
  inherited;
end;

procedure TSpinEdit.SetIncrement(AValue: integer);
begin
  FIncrement := AValue;
  if Assigned(Container) then
    Container['step'] := IntToStr(AValue);
end;

procedure TSpinEdit.SetMaxValue(AValue: integer);
begin
  FMaxValue := AValue;
  if Assigned(Container) then
    Container['max'] := IntToStr(AValue);
end;

procedure TSpinEdit.SetMinValue(AValue: integer);
begin
  FMinValue := AValue;
  if Assigned(Container) then
    Container['min'] := IntToStr(AValue);
end;

procedure TSpinEdit.SetReadOnly(const Value: boolean);
begin
  if (FReadOnly <> Value) then
  begin
    FReadOnly := Value;
    UpdateElement;
  end;
end;

procedure TSpinEdit.SetRequired(const Value: Boolean);
begin
  if (FRequired <> Value) then
  begin
    FRequired := Value;
    UpdateElement;
  end;
end;

procedure TSpinEdit.SetText(const Value: string);
var
  i: Integer;
begin
  i := 0;
  if TryStrToInt(Value, i) then
    Self.Value := i
  else
    Self.Value := 0;
end;

procedure TSpinEdit.PersistinHTML;
begin
  ElementInputHandle.setAttribute('value',IntToStr(Value));
end;

procedure TSpinEdit.SetValue(AValue: integer);
begin
  FValue := AValue;
  UpdateElement;
end;

procedure TSpinEdit.UpdateElementData;
begin
  inherited;
  if Assigned(Container) then
  begin
    Container['inputmode'] := 'numeric';
    Container['pattern'] := '[0-9]*';
    TJSHTMLInputElement(Container).value := GetDisplayText;
    ElementInputHandle.readOnly := IsReadOnly;

    if Required then
      ElementInputHandle.setAttribute('required','')
    else
      ElementInputHandle.removeAttribute('required');
  end;
end;

{ TDateTimePicker }

function TDateTimePicker.GetInputType: string;
begin
   if FKind = dtkDate then
    Result := 'DATE'
  else
    Result := 'TIME';
end;

procedure TDateTimePicker.GetMethodPointers;
begin
  inherited;
  FHandleChangePtr := @DoHandleChange;
  FClickPtr := @DoCheckClick;
  FFocusPtr := @DoPickerFocus;
  FBlurPtr := @DoPickerBlur;
end;

procedure TDateTimePicker.SetChecked(const Value: boolean);
begin
  if (FChecked <> Value) then
  begin
    FChecked := Value;
    UpdateElementVisual;
  end;
end;

procedure TDateTimePicker.SetDate(AValue: TDate);
begin
  if FDate <> AValue then
  begin
    FDate := Int(AValue);
    FTime := 0;
    UpdateElementData;
  end;
end;

procedure TDateTimePicker.SetDateTime(const Value: TDateTime);
begin
  FDate := Int(Value);
  FTime := Frac(Value);
  UpdateElementData;
end;

procedure TDateTimePicker.SetEnabled(Value: Boolean);
var
  el: TJSHTMLElement;
begin
  inherited;
  el := TJSHTMLElement(GetElementPicker);
  if  Assigned(el) then
  begin
    if Value then
      el.removeAttribute('disabled')
    else
      el.setAttribute('disabled','');
  end;
end;

procedure TDateTimePicker.SetFocus;
begin
  if Assigned(GetElementPicker) then
  begin
    TJSHTMLElement(GetElementPicker).Focus();
  end;
end;

procedure TDateTimePicker.BindEvents;
begin
  inherited;
  if Assigned(FElementCheck) then
    FElementCheck.addEventListener('click',FClickPtr);

  if Assigned(GetElementPicker) then
  begin
    GetElementPicker.addEventListener('input', FHandleChangePtr);
    GetElementPicker.addEventListener('focus',FFocusPtr);
    GetElementPicker.addEventListener('blur',FBlurPtr);
  end;
end;

procedure TDateTimePicker.UnBindEvents;
begin
  inherited;
  if Assigned(FElementCheck) then
    FElementCheck.removeEventListener('click',FClickPtr);

  if Assigned(GetElementPicker) then
  begin
    GetElementPicker.removeEventListener('input', FHandleChangePtr);
    GetElementPicker.removeEventListener('focus',FFocusPtr);
    GetElementPicker.removeEventListener('blur',FBlurPtr);
  end;
end;


procedure TDateTimePicker.Change;
begin
  FTime := GetTime;
  FDate := GetDate;
  if Assigned(OnChange) then
    OnChange(Self);
end;

procedure TDateTimePicker.Clear;
begin
  TJSHTMLInputElement(GetElementPicker).value := '';
end;

procedure TDateTimePicker.ClearMethodPointers;
begin
  inherited;
  FHandleChangePtr := nil;
  FBlurPtr := nil;
  FFocusPtr := nil;
  FClickPtr := nil;
end;

function TDateTimePicker.CreateElement: TJSElement;
begin
  Result := document.createElement('DIV');

  FElementCheck := TJSHTMLElement(document.createElement('INPUT'));
  FElementPicker := TJSHTMLElement(document.createElement('INPUT'));

  Result.appendChild(FElementCheck);
  Result.appendChild(FElementPicker);
end;

procedure TDateTimePicker.CreateInitialize;
begin
  inherited;
  Date := now;
  ShowFocus := true;
  if (csDesigning in ComponentState) then
  begin
    Width := 170;
    Height := 25;
  end;
  FDate := Int(Now);
  FTime := 0;
  FReadOnly := false;
  FShowSeconds := true;
end;

function TDateTimePicker.DoCheckClick(Event: TEventListenerEvent): Boolean;
begin
  FChecked := TJSHTMLInputElement(FElementCheck).checked;

  if FChecked then
    GetElementPicker.removeAttribute('disabled')
  else
    GetElementPicker.setAttribute('disabled','true');

  Result := true;
end;

function TDateTimePicker.DoHandleChange(Event: TEventListenerEvent): Boolean;
begin
  Change;
  Result := true;
end;

function TDateTimePicker.DoPickerBlur(Event: TEventListenerEvent): Boolean;
begin
  if not IsLinked then
    ElementHandle.style.setProperty('outline','none');
  Result := true;
  DoExit;
end;

function TDateTimePicker.DoPickerFocus(Event: TEventListenerEvent): Boolean;
begin
  if not IsLinked then
    ElementHandle.style.setProperty('outline','auto');
  Result := true;
  DoEnter;
end;

function TDateTimePicker.GetDate: TDate;
var
  str: string;

begin
  Result := Int(FDate) + Frac(FTime);
  if not Assigned(GetElementPicker) then
    Exit;

  str := TJSHTMLInputElement(GetElementPicker).value;

  if Kind = dtkDate then
  begin
    Result := PickerDateToDate(str) + Frac(FTime);
  end
  else
    Result := FTime;
end;

function TDateTimePicker.GetDateTime: TDateTime;
begin
  Result := Int(FDate) + Frac(FTime);
end;

function TDateTimePicker.GetElementBindHandle: TJSEventTarget;
begin
  Result := GetElementPicker;
end;

function TDateTimePicker.GetElementPicker: TJSHTMLElement;
begin
  if IsLinked then
    Result := ElementHandle
  else
    Result := FElementPicker;
end;

procedure TDateTimePicker.SetText(const Value: String);
begin
  if not Assigned(GetElementPicker) then
    Exit;
end;

procedure TDateTimePicker.SetTime(AValue: TTime);
begin
  if FTime <> AValue then
  begin
    FDate := 0;
    FTime := AValue;
    UpdateElementData;
  end;
end;

procedure TDateTimePicker.UpdateElementData;
var
  el: TJSHTMLElement;
begin
  inherited;

  el := GetElementPicker;

  if Assigned(el) then
  begin
    el['step'] := '1';

    case Kind of
      dtkTime:
        begin
          TJSHTMLInputElement(el).value := FormatDateTime(TFormatSettings.Invariant.LongTimeFormat, FTime);
          if not ShowSeconds then
            el['step'] := '60';
        end;
      dtkDate:
        begin
          if FDate = 0 then
            TJSHTMLInputElement(el).value := ''
          else
            TJSHTMLInputElement(el).value := FormatDateTime('yyyy-MM-dd', FDate);
        end;
    end;

    if TabStop then
      el.setAttribute('tabindex',inttostr(TabOrder))
    else
      el.setAttribute('tabindex','-1');


    TJSHTMLInputElement(el).readOnly := IsReadOnly;

    if IsEnabled then
      el.removeAttribute('disabled')
    else
      el.setAttribute('disabled','');
  end;
end;

procedure TDateTimePicker.UpdateElementVisual;
begin
  inherited;

  if Assigned(GetElementPicker) and Assigned(ElementHandle) and Assigned(FElementCheck) then
  begin
    FElementCheck['type'] := 'CHECKBOX';

    if FKind = dtkDate then
      GetElementPicker['type'] := 'DATE'
    else
      GetElementPicker['type'] := 'TIME';

    GetElementPicker.style.setProperty('border','none');
    GetElementPicker.style.setProperty('border-width','0px');
    GetElementPicker.style.setProperty('outline','none');
    GetElementPicker.style.setProperty('font-family','inherit');
    GetElementPicker.style.setProperty('font-size','inherit');
    GetElementPicker.style.setProperty('width','100%');
    GetElementPicker.style.setProperty('height','100%');

    if not IsLinked then
    begin
      ElementHandle.style.setProperty('padding','1px');
      ElementHandle.style.setProperty('outline','none');
    end;

    if BorderStyle = bsSingle then
    begin
      if not Enabled then
        ElementHandle.style.setProperty('border','1px silver solid')
      else
        ElementHandle.style.setProperty('border','1px solid');

      ElementHandle.style.setProperty('border-radius','3px');
    end
    else
      ElementHandle.style.removeProperty('border');

    if Assigned(FElementCheck) then
    begin
      if ShowCheckBox then
        FElementCheck.style.SetProperty('display','')
      else
        FElementCheck.style.SetProperty('display','none');
    end;

    if ShowCheckBox then
    begin
      if Checked then
        GetElementPicker.removeAttribute('disabled')
      else
        GetElementPicker.setAttribute('disabled','true');
    end;
  end;
end;

function TDateTimePicker.GetText: String;
begin
  Result := '';
  if not Assigned(GetElementPicker) then
    Exit;

  Result := TJSHTMLInputElement(GetElementPicker).value;
end;

function TDateTimePicker.GetTime: TTime;
var
  str: string;
  d: TDateTime;
begin
  if FDate > 0 then
    Result := Frac(FTime) + Int(FDate)
  else
    Result := Frac(FTime);

  if not Assigned(GetElementPicker) then
    Exit;

  str := TJSHTMLInputElement(GetElementPicker).value;
  if TryStrToTime(str, d) then
  begin
    Result := d + Int(FDate);
  end;
end;

{$HINTS OFF}
function TDateTimePicker.HandleDoEnter(Event: TJSFocusEvent): Boolean;
var
  el: TJSElement;
begin
  Result := inherited HandleDoEnter(Event);
  if AutoDropDown then
  begin
    el := GetElementPicker;
    asm
      el.showPicker();
    end;
  end;
end;
{$HINTS ON}

function TDateTimePicker.IsReadOnly: boolean;
begin
  Result := FReadOnly;
end;

procedure TDateTimePicker.SetKind(AValue: TDateTimeKind);
var
  dt: TDateTime;
begin
  FKind := AValue;
  if Assigned(GetElementPicker) then
  begin
    dt := Int(FDate) + Frac(FTime);
    if AValue = dtkDate then
      GetElementPicker['type'] := 'DATE'
    else
      GetElementPicker['type'] := 'TIME';

    Date := dt;
    Time := dt;
    UpdateElement;
  end;
end;

procedure TDateTimePicker.SetReadOnly(const Value: boolean);
begin
  FReadOnly := Value;
  UpdateElement;
end;

procedure TDateTimePicker.SetShowCheckBox(const Value: boolean);
begin
  if (FShowCheckBox <> Value) then
  begin
    FShowCheckBox := Value;
    UpdateElementVisual;
  end;
end;

procedure TDateTimePicker.SetShowSeconds(const Value: boolean);
begin
  if (FShowSeconds <> Value) then
  begin
    FShowSeconds := Value;
    UpdateElement;
  end;
end;

{ TButton }

procedure TButton.CreateInitialize;
begin
  inherited;
  if (csDesigning in ComponentState) and (Caption = '') then
    Caption := Name;
  Color := clNone;
  FModalResult := mrNone;
end;

procedure TButton.HandleKeyPreview(Key: Word);
begin
  inherited;
  if Default and (Key = VK_RETURN) then
  begin
    Click;
  end;
  if Cancel and (Key = VK_ESCAPE) then
  begin
    Click;
  end;
end;

procedure TButton.Click;
var
  frm: TCustomForm;
begin
  inherited;

  if ModalResult <> mrNone then
  begin
    frm := GetParentForm(Self);
    if Assigned(frm) then
      frm.ModalResult := ModalResult;
  end;
end;

function TButton.CreateElement: TJSElement;
begin
  Result := document.createElement('BUTTON');
  Result['type'] := 'BUTTON';
end;

procedure TButton.SetCaption(const AValue: string);
begin
  if Caption <> AValue then
  begin
    inherited SetCaption(AValue);
    UpdateElementData;
  end;
end;

procedure TButton.SetDefault(const Value: Boolean);
begin
  FDefault := Value;
  if FDefault then
    SetFocus;
end;

procedure TButton.SetButtonType(const Value: string);
begin
  FButtonType := Value;
  UpdateElementData;
end;

procedure TButton.UpdateElementData;
var
  acc: string;
begin
  inherited;

  if IsLinked and (Caption = '') then
    Exit;

  if Assigned(ElementHandle) then
  begin
    if (ElementHandle.childElementCount = 0) or (Pos('</', Caption) > 0) or not IsLinked then
    begin
      ElementHandle.innerHTML := ProcessAccelerator(Caption,acc);
    end;

    if acc <> '' then
      ElementHandle['accesskey'] := acc;

    ElementHandle['role'] := 'button';
    ElementHandle['aria-label'] := Caption;

    if FButtonType <> '' then
      ElementHandle['type'] := FButtonType
    else
      ElementHandle['type'] := 'BUTTON';
  end;
end;

procedure TButton.UpdateElementVisual;
var
  ecn: string;
begin
  inherited;
  if Assigned(ElementHandle) then
  begin
    ElementHandle.style.removeProperty('outline');
    ElementHandle.style.removeProperty('user-select');

    ecn := Uppercase(ElementClassName);

    if (ecn = 'BTN') or (pos('BTN ', ecn) > 0)  then
    begin
//      ElementHandle.style.setProperty('border','none');
    end;
  end;
end;

{ TCheckBox }

procedure TCheckBox.CreateInitialize;
begin
  inherited;
  Color := clNone;
  FChecked := False;
  FState := cbUnchecked;

  if (csDesigning in ComponentState) and (Caption = '') then
     Caption := Name;

  ShowFocus := true;
end;

procedure TCheckBox.DoCheckClick;
begin
  if Assigned(OnCheckClick) then
    OnCheckClick(Self);
end;

procedure TCheckBox.Click;
begin
  inherited;
  FChecked := GetChecked;
  if FChecked then
    FState := cbChecked
  else
    FState := cbUnChecked;

  SetFocus;
end;

function TCheckBox.CreateElement: TJSElement;
var
  btn, lbl: TJSElement;
begin
  Result := document.createElement('SPAN');
  btn := document.createElement('INPUT');
  lbl := document.createElement('SPAN');

  btn['TYPE'] := 'CHECKBOX';
  btn['id'] := GetID;
  btn['role'] := 'checkbox';
  lbl['id'] := GetID + 'lbl';
  lbl['value'] := GetID;

  Result.appendChild(btn);
  Result.appendChild(lbl);

  (btn as TJSHTMLInputElement).OnClick := @HandleCheckClick;
end;

function TCheckBox.HandleCheckClick(Event: TJSMouseEvent): Boolean;
begin
  DoCheckClick;
  Result := true;
end;

function TCheckBox.HandleLabelClick(Event: TJSMouseEvent): Boolean;
var
  chk: TJSHTMLInputElement;
begin
  if Assigned(Container) and Enabled then
  begin
    chk := TJSHTMLInputElement(GetCheckElement);
    chk.checked := not chk.checked;

    Checked := chk.checked;
  end;
  DoCheckClick;
  Result := true;
end;

procedure TCheckBox.KeyPress(var ch: char);
var
  chk: TJSHTMLInputElement;
begin
  inherited;
  if ch = #32 then
  begin
    if Assigned(Container) then
    begin
      chk := TJSHTMLInputElement(GetCheckElement);
      chk.checked := not chk.checked;
    end;
  end;
end;

procedure TCheckBox.Loaded;
var
  lbl: TJSHTMLInputElement;

begin
  inherited;

  if Assigned(Container) then
  begin
    if not IsLinked then
    begin
      lbl := TJSHTMLInputElement(Container.children[1]);
      lbl.OnClick := @HandleLabelClick;
    end;
  end;
end;

procedure TCheckBox.UpdateElementData;
var
  chk: TJSHTMLInputElement;
  btn: TJSElement;
begin
  inherited;

  if Assigned(Container) then
  begin
    chk := TJSHTMLInputElement(GetCheckElement);
    chk.disabled := not IsEnabled;
    chk.checked := FChecked;
    chk.indeterminate := (State = cbGrayed);

    if not IsLinked then
    begin
      if (Caption <> '') then
        Container.lastElementChild.innerHTML := Caption;

      ElementHandle.setAttribute('tabindex','-1');

      btn := TJSElement(ElementHandle.firstChild);
      btn.setAttribute('tabindex','-1');
    end;

    if TabStop then
      Container.setAttribute('tabindex',inttostr(TabOrder));
  end;
end;

procedure TCheckBox.UpdateElementSize;
begin
  inherited;

  if Assigned(Container) and not IsLinked and (ElementPosition = epAbsolute) then
  begin
    if HeightStyle <> ssAuto then
      TJSHTMLElement(Container.firstElementChild).style.setProperty('height','100%')
    else
      TJSHTMLElement(Container.firstElementChild).style.removeProperty('height');
  end;
end;

procedure TCheckBox.UpdateElementVisual;
var
  btn,lbl: TJSElement;
  frm: TCustomForm;
  isbs: boolean;
begin
  inherited;

  if Assigned(ElementHandle) then
  begin
    btn := TJSElement(ElementHandle.firstChild);

    if Assigned(btn) then
    begin
      if Assigned(TJSHTMLElement(btn).style) then
        TJSHTMLElement(btn).style.setProperty('margin-right','4px');

      if FElementButtonClassName <> '' then
        btn.setAttribute('class',FElementButtonClassName)
      else
        btn.removeAttribute('class');
    end;

    lbl := TJSElement(ElementHandle.lastElementChild);

    if Assigned(lbl) then
    begin
      if FElementLabelClassName <> '' then
        lbl.setAttribute('class',FElementLabelClassName)
      else
        lbl.removeAttribute('class');
    end;

    if not TabStop or not ShowFocus or (ElementClassName <> '') then
      ElementHandle.style.setProperty('outline','none');

    if not IsLinked then
    begin
      ElementHandle.style.setProperty('user-select','none');

      if ElementPosition = epAbsolute then
      begin
        if ElementButtonClassName = '' then
        begin
          TJSHTMLElement(btn).style.setProperty('float','left');

          if HeightStyle = ssAuto then
            TJSHTMLElement(btn).style.removeProperty('height')
          else
            if (ElementPosition = epAbsolute) then
              TJSHTMLElement(btn).style.setProperty('height','100%');

          TJSHTMLElement(btn).style.setProperty('vertical-align','middle');

          if not (Parent is TCheckGroup) then
          begin
            TJSHTMLElement(btn).style.setProperty('margin-top','0px');
            TJSHTMLElement(btn).style.setProperty('margin-bottom','0px');
          end;
        end;

        frm := GetParentForm(Self);
        isbs := Assigned(frm) and (frm.CSSLibrary = cssBootstrap);

        if (ElementLabelClassName = '') or isbs then
        begin
          lbl := TJSElement(Container.lastElementChild);
          TJSHTMLElement(lbl).style.setProperty('vertical-align','middle');
          TJSHTMLElement(lbl).style.setProperty('min-height','100%');
          TJSHTMLElement(lbl).style.setProperty('height','100%');
          TJSHTMLElement(lbl).style.setProperty('position','absolute');
          TJSHTMLElement(lbl).style.setProperty('overflow','hidden');
          TJSHTMLElement(lbl).style.setProperty('display','inline-flex');
          TJSHTMLElement(lbl).style.setProperty('align-items','center');
        end;
      end;
    end;

  end;
end;

procedure TCheckBox.SetCaption(const AValue: string);
begin
  if Caption <> AValue then
  begin
    inherited SetCaption(AValue);
    if Assigned(Container) then
    begin
      if IsLinked then
      begin
        if Assigned(Container.lastElementChild) and (Container.lastElementChild.tagName = 'SPAN') then
          Container.lastElementChild.innerHTML := AValue;
      end
      else
        if Assigned(Container.lastElementChild) then
          Container.lastElementChild.innerHTML := AValue;
    end;
  end;
end;

procedure TCheckBox.SetEnabled(Value: boolean);
begin
  inherited;
  if Assigned(Container) then
    TJSHTMLInputElement(GetCheckElement).disabled := not Value;
end;

procedure TCheckBox.SetChecked(AValue: boolean);
begin
  FChecked := AValue;
  if AValue then
    FState := cbChecked
  else
    FState := cbUnChecked;

  UpdateElement;
end;

function TCheckBox.GetChecked: boolean;
begin
  if Assigned(Container) then
    FChecked := TJSHTMLInputElement(GetCheckElement).checked;

  Result := FChecked;
end;

function TCheckBox.GetCheckElement: TJSHTMLElement;
begin
  if IsLinked then
  begin
    if ElementHandle.tagName = 'INPUT' then
      Result := TJSHTMLElement(ElementHandle)
    else
      Result := TJSHTMLElement(Container.firstElementChild);
  end
  else
    Result := TJSHTMLElement(Container.firstElementChild);
end;

procedure TCheckBox.SetState(AValue: TCheckBoxState);
begin
  FState := AValue;
  FChecked := FState = cbChecked;
  UpdateElement;
end;

function TCheckBox.GetState: TCheckBoxState;
begin
  Result := FState;
end;

procedure TCheckBox.PersistinHTML;
var
  cb: TJSHTMLInputElement;
begin
  if not Assigned(Container) then
    Exit;

  cb := TJSHTMLInputElement(GetCheckElement);

  if (cb.checked) then
    cb.setAttribute('checked','checked')
  else
    cb.removeAttribute('checked');
end;

procedure TCheckBox.LoadState(AState: string);
var
  cb: TJSHTMLInputElement;
begin
  if not Assigned(Container) then
    Exit;

  cb := TJSHTMLInputElement(GetCheckElement);
  cb.checked := AState = '1';
end;

function TCheckBox.SaveState: string;
var
  cb: TJSHTMLInputElement;
begin
  if not Assigned(Container) then
    Exit;

  cb := TJSHTMLInputElement(GetCheckElement);

  if (cb.checked) then
    Result := '1'
  else
    Result := '0';
end;

{ TRadioButton }

procedure TRadioButton.CreateInitialize;
begin
  inherited;
  Color := clNone;

  if (csDesigning in ComponentState) and (Caption = '') then
    Caption := Name;

  FGroupName := '';
  ShowFocus := true;
end;

function TRadioButton.CreateElement: TJSElement;
var
  btn, lbl: TJSElement;
begin
  Result := document.createElement('SPAN');
  btn := document.createElement('INPUT');
  lbl := document.createElement('SPAN');
  Result.addEventListener('click', @HandleLabelClick);

  btn['TYPE'] := 'RADIO';
  btn['id'] := GetID + 'rd';
  btn['name'] := GroupName;
  btn['role'] := 'radio';
  btn['tabindex'] := '-1';
  btn.AddEventListener('click',@HandleRadioClick);

  lbl['id'] := GetID + 'lbl';
  lbl['value'] := GetID;

  Result.appendChild(btn);
  Result.appendChild(lbl);
end;

function TRadioButton.HandleLabelClick(Event: TJSMouseEvent): Boolean;
var
  rb: TJSHTMLInputElement;
begin
  if Assigned(Container) and Enabled then
  begin
    rb := TJSHTMLInputElement(GetRadioElement);
    rb.checked := true;
  end;
  Result := true;
end;

function TRadioButton.HandleRadioClick(Event: TJSMouseEvent): Boolean;
begin
  SetFocus;
  Result := true;
end;

procedure TRadioButton.Keypress(var ch: Char);
var
  rb: TJSHTMLInputElement;
begin
  inherited;
  if ch = #32 then
  begin
    rb := TJSHTMLInputElement(GetRadioElement);
    rb.checked := true;
  end;
end;

procedure TRadioButton.LoadState(AState: string);
var
  rb: TJSHTMLInputElement;
begin
  if not Assigned(Container) then
    Exit;

  rb := TJSHTMLInputElement(GetRadioElement);
  rb.checked := AState = '1';
end;

procedure TRadioButton.SetCaption(const AValue: string);
begin
  if (Caption <> AValue) then
  begin
    inherited SetCaption(AValue);
    if Assigned(Container) and not IsLinked then
      Container.lastElementChild.innerHTML := AValue;
  end;
end;

procedure TRadioButton.SetEnabled(Value: boolean);
begin
  inherited;
  if Assigned(Container) then
    TJSHTMLInputElement(GetRadioElement).disabled := not Value;
end;

procedure TRadioButton.SetGroupName(AValue: string);
begin
  FGroupName := AValue;
  UpdateElementData;
end;

function TRadioButton.SaveState: string;
var
  rb: TJSHTMLInputElement;
begin
  if not Assigned(Container) then
    Exit;

  rb := TJSHTMLInputElement(GetRadioElement);

  if rb.checked then
    Result := '1'
  else
    Result := '0';
end;

procedure TRadioButton.UpdateElementData;
var
  btn: TJSElement;

begin
  inherited;
  if Assigned(Container) then
  begin
    if IsLinked then
      TJSHTMLElement(ElementHandle)['name'] := FGroupName
    else
    begin
      Container.firstElementChild['name'] := FGroupName;
      btn := TJSElement(ElementHandle.firstChild);
      TJSHTMLInputElement(btn).disabled := not Enabled;
    end;

    if not IsLinked then
    begin
      if (Caption <> '') then
        Container.lastElementChild.innerHTML := Caption;

      ElementHandle.setAttribute('tabindex','-1');

      if TabStop and Assigned(btn) then
        btn.setAttribute('tabindex','-1');
    end;

    if TabStop then
      Container.setAttribute('tabindex',inttostr(TabOrder));
  end;
end;

procedure TRadioButton.UpdateElementSize;
begin
  inherited;

  if Assigned(Container) and not IsLinked then
  begin
    if (HeightStyle <> ssAuto) then
      TJSHTMLElement(Container.firstElementChild).style.setProperty('height','100%')
    else
      TJSHTMLElement(Container.firstElementChild).style.removeProperty('height')
  end;
end;

procedure TRadioButton.UpdateElementVisual;
var
  btn,lbl: TJSElement;
  isbs: boolean;
  frm: TCustomForm;
begin
  inherited;

  if Assigned(ElementHandle) then
  begin

    if not IsLinked  then
    begin
      btn := TJSElement(ElementHandle.firstChild);
      TJSHTMLElement(btn).style.setProperty('margin-right','4px');

      if Assigned(btn) then
      begin
        if FElementButtonClassName <> '' then
          btn.setAttribute('class',FElementButtonClassName)
        else
          btn.removeAttribute('class');
      end;

      lbl := TJSElement(ElementHandle.lastElementChild);

      if Assigned(lbl) then
      begin
        if FElementLabelClassName <> '' then
          lbl.setAttribute('class',FElementLabelClassName)
        else
          lbl.removeAttribute('class');
      end;
    end;

    if not TabStop or not ShowFocus or (ElementClassName <> '') then
      ElementHandle.style.setProperty('outline','none');

    if not IsLinked then
    begin
      ElementHandle.style.setProperty('user-select','none');
      ElementHandle.style.setProperty('white-space','normal');

      if ElementPosition = epAbsolute then
      begin
        if ElementButtonClassName = '' then
        begin
          TJSHTMLElement(btn).style.setProperty('float','left');

          if HeightStyle <> ssAuto then
            TJSHTMLElement(btn).style.setProperty('height','100%')
          else
            TJSHTMLElement(btn).style.removeProperty('height');
          TJSHTMLElement(btn).style.setProperty('vertical-align','middle');

          if not (Parent is TRadioGroup) then
          begin
            TJSHTMLElement(btn).style.setProperty('margin-top','0px');
            TJSHTMLElement(btn).style.setProperty('margin-bottom','0px');
          end;
        end;

        frm := GetParentForm(Self);
        isbs := Assigned(frm) and (frm.CSSLibrary = cssBootstrap);

        if (ElementLabelClassName = '') or isbs then
        begin
          lbl := TJSElement(Container.lastElementChild);
          TJSHTMLElement(lbl).style.setProperty('vertical-align','middle');
          TJSHTMLElement(lbl).style.setProperty('min-height','100%');
          TJSHTMLElement(lbl).style.setProperty('height','100%');
          if not (Parent is TRadioGroup) then
            TJSHTMLElement(lbl).style.setProperty('position','absolute');
          TJSHTMLElement(lbl).style.setProperty('overflow','hidden');
          TJSHTMLElement(lbl).style.setProperty('display','inline-flex');
          TJSHTMLElement(lbl).style.setProperty('align-items','center');
        end;
      end;
    end;

  end;
end;

procedure TRadioButton.SetChecked(AValue: boolean);
begin
  FChecked := AValue;

  if Assigned(Container) then
    TJSHTMLInputElement(GetRadioElement).checked := AValue;
end;

function TRadioButton.GetChecked: boolean;
begin
  Result := FChecked;
  if Assigned(Container) then
    Result := TJSHTMLInputElement(GetRadioElement).checked;
end;

function TRadioButton.GetRadioElement: TJSHTMLElement;
begin
  if IsLinked then
    Result := TJSHTMLElement(ElementHandle)
  else
    Result := TJSHTMLElement(Container.firstElementChild);
end;

procedure TRadioButton.PersistInHTML;
var
  rb: TJSHTMLInputElement;
begin
  if not Assigned(Container) then
    Exit;

  rb := TJSHTMLInputElement(GetRadioElement);
  if (rb.checked) then
    rb.setAttribute('checked','checked')
  else
    rb.removeAttribute('checked');
end;

{ TListBox }

procedure TListBox.CreateInitialize;
begin
  inherited;
  FItems := TStringList.Create;
  FItems.SkipLastLinebreak := true;
  TStringList(FItems).OnChange := DoItemsChange;
  FMultiSelect := false;
  FSelected := TList.Create;
  FItemIndex := -1;
  ShowFocus := true;
  if (csDesigning in ComponentState) then
  begin
    Width := 160;
    Height := 180;
  end;
end;

function TListBox.CreateElement: TJSElement;
begin
  Result := document.createElement('SELECT');
  Result['Size'] := '2';
end;

procedure TListBox.DeleteSelected;
var
  i: integer;
begin
  Items.BeginUpdate;
  for i := Items.Count - 1 downto 0 do
  begin
    if Selected[i] then
      Items.Delete(i);
  end;
  Items.EndUpdate;
end;

destructor TListBox.Destroy;
begin
  FItems.Free;
  FSelected.Free;
  inherited;
end;

procedure TListBox.DisableDrag;
var
  I: Integer;
begin
  if not Assigned(Container) then
    Exit;

  for I := 0 to TJSHTMLSelectElement(Container).options.length - 1 do
    TJSHTMLOptionElement(TJSHTMLSelectElement(Container).options[I]).Attrs['draggable'] := 'false';
end;

function TListBox.DoHandleChange(Event: TEventListenerEvent): Boolean;
begin
  Change;
  Result := True;
end;

procedure TListBox.AddItem(Item: string; AObject: TObject);
begin
  FItems.AddObject(Item, AObject);
end;

procedure TListBox.BindEvents;
begin
  inherited;
  if Assigned(ElementHandle) then
  begin
    ElementHandle.addEventListener('change', FHandleChangePtr);
  end;
end;

procedure TListBox.UnBindEvents;
begin
  inherited;
  if Assigned(ElementHandle) then
  begin
    ElementHandle.removeEventListener('change', FHandleChangePtr);
  end;
end;


procedure TListBox.Change;
begin
  if Assigned(OnChange) then
    OnChange(Self);
end;

procedure TListBox.Clear;
begin
  BeginUpdate;
  FItems.Clear;
  EndUpdate;
end;

procedure TListBox.ClearMethodPointers;
begin
  inherited;
  FHandleChangePtr := nil;
end;

procedure TListBox.ClearSelection;
var
  i: integer;
begin
  ItemIndex := -1;

  if not Assigned(Container) then
    Exit;

  if ElementHandle.tagName <> 'SELECT' then
    Exit;

  for i := 0 to TJSHTMLSelectElement(Container).options.length - 1 do
    TJSHTMLOptionElement(TJSHTMLSelectElement(Container).options[i]).selected := false;
end;

function TListBox.SaveState: string;
var
  i: integer;
  s: string;
begin
  for i := 0 to Items.Count - 1 do
  begin
    if Selected[i] then
      s := s + ',1'
    else
      s := s + ',0';
  end;
  Delete(s,1,1);
  Result := s;
end;

procedure TListBox.SelectAll;
var
  i: integer;
begin
  if not Assigned(Container) then
    Exit;

  if ElementHandle.tagName <> 'SELECT' then
    Exit;

  for i := 0 to TJSHTMLSelectElement(Container).options.length - 1 do
  begin
    Selected[i] := true;
    TJSHTMLOptionElement(TJSHTMLSelectElement(Container).options[i]).selected := true;
  end;
end;

procedure TListBox.SetItems(AItems: TStrings);
begin
  FItems.Assign(AItems);
end;

function TListBox.GetCount: Integer;
begin
  Result := FItems.Count;
end;

function TListBox.GetElementSelectHandle: TJSHTMLSelectElement;
begin
  Result := TJSHTMLSelectElement(Container);
end;

function TListBox.GetItemIndex: integer;
begin
  Result := FItemIndex;
  if Assigned(Container) then
    Result := TJSHTMLSelectElement(Container).selectedIndex;
end;

procedure TListBox.GetMethodPointers;
begin
  inherited;
  FHandleChangePtr := @DoHandleChange;
end;

procedure TListBox.PersistinHTML;
var
  i: integer;
begin
  if not Assigned(Container) then
    Exit;

  if ElementHandle.tagName <> 'SELECT' then
    Exit;

  for i := 0 to TJSHTMLSelectElement(Container).options.length - 1 do
  begin
    if TJSHTMLOptionElement(TJSHTMLSelectElement(Container).options[i]).selected then
      TJSHTMLOptionElement(TJSHTMLSelectElement(Container).options[i]).setAttribute('selected','selected')
    else
      TJSHTMLOptionElement(TJSHTMLSelectElement(Container).options[i]).removeAttribute('selected');
  end;
end;

procedure TListBox.SetItemHeight(const Value: Integer);
begin
  FItemHeight := Value;
end;

procedure TListBox.SetItemIndex(AIndex: integer);
begin
  if FItemIndex <> AIndex then
  begin
    FItemIndex := AIndex;
    UpdateElement;
  end;
end;

procedure TListBox.SetMultiSelect(AValue: boolean);
begin
  FMultiSelect := AValue;
  if Assigned(Container) then
    TJSHTMLSelectElement(Container).multiple := AValue;
end;

function TListBox.GetSelCount: integer;
var
  i: integer;
begin
  Result := 0;

  if Assigned(Container) then
  begin
    for i := 0 to FItems.Count - 1 do
    begin
      if TJSHTMLOptionElement(TJSHTMLSelectElement(Container).options[i]).selected then
        inc(Result);
    end;
  end;
end;

function TListBox.GetSelected(AIndex: integer): boolean;
begin
  if (AIndex < FSelected.Count) then
    Result := boolean(FSelected.Items[AIndex])
  else
    Result := False;

  if ElementHandle.tagName <> 'SELECT' then
    Exit;

  if Assigned(Container) and (AIndex < TJSHTMLSelectElement(Container).options.length) then
    Result := TJSHTMLOptionElement(TJSHTMLSelectElement(Container).options[Aindex]).selected;
end;

function TListBox.GetValues(AIndex: integer): string;
var
  opt: TJSHTMLOptionElement;
  el: TJSHTMLSelectElement;
begin
  if (AIndex >= 0) and (AIndex < Items.Count) then
  begin
    el := TJSHTMLSelectElement(ElementHandle);
    opt := TJSHTMLOptionElement(el.options[AIndex]);
    Result := opt['value'];
  end;
end;

procedure TListBox.Loaded;
begin
  inherited;
  DoUpdateList;
  UpdateElement;
end;

procedure TListBox.LoadState(AState: string);
var
  sl: TStringList;
  i: integer;
begin
  sl := TStringList.Create;
  sl.Delimiter := ',';
  sl.CommaText := AState;

  for i := 0 to sl.Count - 1 do
  begin
    Selected[i] := sl.Strings[i] = '1';
  end;

  sl.Free;
end;

procedure TListBox.SetSelected(AIndex: integer; AValue: boolean);
begin  
  while (AIndex >= FSelected.Count) do
    FSelected.Add(false);

  FSelected.Items[AIndex] := AValue;

  if Assigned(Container) then
    TJSHTMLOptionElement(TJSHTMLSelectElement(Container).options[Aindex]).selected := AValue;
end;

procedure TListBox.SetSorted(const Value: Boolean);
begin
  FSorted := Value;
  TStringList(Items).Sort;
end;

procedure TListBox.SetValues(AIndex: integer; const Value: string);
var
  opt: TJSHTMLOptionElement;
  el: TJSHTMLSelectElement;
begin
  if (AIndex >= 0) and (AIndex < Items.Count) then
  begin
    el := TJSHTMLSelectElement(ElementHandle);
    opt := TJSHTMLOptionElement(el.options[AIndex]);
    opt['value'] := Value;
  end;
end;

procedure TListBox.UpdateElementData;
begin
  inherited;

  if Assigned(ElementSelectHandle) then
  begin
    ElementSelectHandle.style.setProperty('overflow', 'auto');
    ElementSelectHandle.selectedIndex := FItemIndex;
  end;
end;

procedure TListBox.UpdateElementVisual;
begin
  inherited;

  if Assigned(ElementSelectHandle) then
  begin
    ElementSelectHandle.style.setProperty('overflow', 'auto');
    ElementSelectHandle['role'] := 'listbox';
    ElementSelectHandle['aria-busy'] := 'true';
  end;
end;

procedure TListBox.UpdateParent;
begin
  inherited;
  DoUpdateList;
end;

procedure TListBox.DoItemsChange(Sender: TObject);
begin
  DoUpdateList;
end;

procedure TListBox.DoUpdateList;
var
  i,j: integer;
  opt: TJSHTMLOptionElement;
  k,v,s: string;

begin
  if not Assigned(Container) then
    Exit;

  if IsUpdating then
    Exit;

  if ElementHandle.tagName <> 'SELECT' then
    Exit;

  for i := TJSHTMLSelectElement(Container).options.length - 1 downto 0 do
    TJSHTMLSelectElement(Container).remove(i);

  for i := 0 to FItems.Count - 1 do
  begin
    s := FItems[i];
    k := s;
    v := s;
    j := pos(FItems.NameValueSeparator,s);

    if j > 0 then
    begin
      v := copy(s,1,j-1);
      k := copy(s,j+1,$FFFF);
    end
    else
      k := '';

    opt := TJSHTMLOptionElement.New(v);
    if k <> '' then
      opt['value'] := k;

    opt['role'] := 'listitem';
    if ElementItemClassName <> '' then
      opt['class'] := ElementItemClassName;

    if DragMode = dmAutomatic then
      opt['draggable'] := 'true';
    TJSHTMLSelectElement(Container).add(opt);
    Selected[i] := false;
  end;

  UpdateElementData;
end;

procedure TListBox.EnableDrag;
var
  I: Integer;
begin
  if not Assigned(Container) then
    Exit;

  for I := 0 to TJSHTMLSelectElement(Container).options.length - 1 do
    TJSHTMLOptionElement(TJSHTMLSelectElement(Container).options[I]).Attrs['draggable'] := 'true';
end;

procedure TListBox.EndUpdate;
begin
  inherited;
  DoUpdateList;
end;

{ TCustomComboBox }

procedure TCustomComboBox.AddItem(Item: string; AObject: TObject);
begin
  FItems.AddObject(Item, AObject);
end;

procedure TCustomComboBox.BindEvents;
begin
  inherited;
  if Assigned(ElementHandle) then
  begin
    ElementHandle.addEventListener('input', FHandleInputPtr);
    ElementHandle.addEventListener('change', FHandleChangePtr);

    ElementHandle.addEventListener('focusin', FHandleFocusInPtr);
    ElementHandle.addEventListener('focusout', FHandleFocusOutPtr);
  end;
end;

procedure TCustomComboBox.UnBindEvents;
begin
  inherited;
  if Assigned(ElementHandle) then
  begin
    ElementHandle.removeEventListener('input', FHandleInputPtr);
    ElementHandle.removeEventListener('change', FHandleChangePtr);

    ElementHandle.removeEventListener('focusin', FHandleFocusInPtr);
    ElementHandle.removeEventListener('focusout', FHandleFocusOutPtr);
  end;
end;

procedure TCustomComboBox.Clear;
begin
  Items.Clear;
end;

procedure TCustomComboBox.ClearMethodPointers;
begin
  inherited;
  FHandleChangePtr := nil;
  FHandleInputPtr := nil;
end;

procedure TCustomComboBox.CreateInitialize;
begin
  inherited;
  FItems := TStringList.Create;
  TStringList(FItems).OnChange := DoItemsChange;
  FItemIndex := -1;
  FStyle := csDropDownList;
  ShowFocus := true;
  Height := 25;
end;

function TCustomComboBox.CreateElement: TJSElement;
begin
  if FStyle = csDropDownList then
    Result := document.createElement('SELECT')
  else
  begin
    Result := document.createElement('INPUT');
    FListName := Name+'_LIST';
    Result['list'] := FListName;
  end;
end;

destructor TCustomComboBox.Destroy;
begin
  FItems.Free;
  inherited;
end;

procedure TCustomComboBox.SetItems(AItems: TStrings);
begin
  FItems.Assign(AItems);
end;

procedure TCustomComboBox.SetStyle(const Value: TComboBoxStyle);
begin
  if FStyle <> Value then
  begin
    FStyle := Value;
    if not IsLinked then
      RecreateElement;
  end;
end;

procedure TCustomComboBox.SetText(const Value: string);
var
  I: Integer;
begin
  if Style = csDropDown then
  begin
    FText := Value;
    UpdateElement;
  end
  else
  begin
    for I := 0 to Items.Count - 1 do
    begin
      if Value = Items[I] then
        ItemIndex := I;
    end;
  end;
end;

procedure TCustomComboBox.SetTextHint(const Value: string);
begin
  if (FTextHint <> Value) then
  begin
    FTextHint := Value;
    DoUpdateList;
  end;
end;

procedure TCustomComboBox.SetValues(AIndex: integer; const Value: string);
var
  opt: TJSHTMLOptionElement;
  el: TJSHTMLSelectElement;
begin
  if (AIndex >= 0) and (AIndex < Items.Count) then
  begin
    el := TJSHTMLSelectElement(ElementHandle);
    opt := TJSHTMLOptionElement(el.options[AIndex]);
    opt['value'] := Value;
  end;
end;

procedure TCustomComboBox.UpdateElementData;
var
  d: integer;
begin
  inherited;

  if Style = csDropDown then
  begin
    if Assigned(ElementHandle) then
    begin
      TJSHTMLInputELement(ElementHandle).Value := FText;

      FListName := Name+'_LIST';
      ElementHandle['list'] := FListName;
    end;
  end
  else
    if Assigned(ElementSelectHandle) then
    begin
      d := 0;
      if (TextHint <> '') then
        d := 1;

      ElementSelectHandle.selectedIndex := FItemIndex + d;
      ElementSelectHandle['role'] := 'combobox';
    end;
end;

procedure TCustomComboBox.UpdateParent;
begin
  inherited;
  DoUpdateList;
end;

function TCustomComboBox.GetElementSelectHandle: TJSHTMLSelectElement;
begin
  Result := TJSHTMLSelectElement(Container);
end;

function TCustomComboBox.GetItemIndex: integer;
begin
  if Style = csDropDown then
  begin
    Result := Items.IndexOf(Text);
  end
  else
  begin
    Result := FItemIndex;

    if Assigned(Container) then
    begin
      Result := TJSHTMLSelectElement(Container).selectedIndex;
      if TextHint <> '' then
        Result := Result - 1;
    end;
  end;
end;

procedure TCustomComboBox.GetMethodPointers;
begin
  inherited;
  FHandleChangePtr := @DoHandleChange;
  FHandleInputPtr := @DoHandleInput;
  FHandleFocusInPtr := @DoHandleFocusIn;
  FHandleFocusOutPtr := @DoHandleFocusOut;
end;

function TCustomComboBox.GetText: string;
var
  el: TJSHTMLElement;
begin
  Result := '';
  if Style = csDropDown then
  begin
    if Assigned(ElementHandle) then
      Result := TJSHTMLInputElement(ElementHandle).value;
  end
  else
    if (ItemIndex >= 0) then
    begin
      if (ItemIndex < Items.Count) then
        Result := Items[ItemIndex]
      else
      begin
        if IsLinked then
        begin
          if ItemIndex < ElementHandle.childElementCount then
          begin
            el := TJSHTMLElement(ElementHandle.children[ItemIndex]);
            if el.tagName = 'OPTION' then
              Result := el.innerHTML;
          end;
        end;
      end;
    end;
end;

function TCustomComboBox.GetValues(AIndex: integer): string;
var
  opt: TJSHTMLOptionElement;
  el: TJSHTMLSelectElement;
begin
  if (AIndex >= 0) and (AIndex < Items.Count) then
  begin
    el := TJSHTMLSelectElement(ElementHandle);
    opt := TJSHTMLOptionElement(el.options[AIndex]);
    Result := opt['value'];
  end;
end;

procedure TCustomComboBox.Change;
begin
  FItemIndex := GetItemIndex;
  if Assigned(OnChange) then
    OnChange(Self);
end;

function TCustomComboBox.DoHandleChange(Event: TEventListenerEvent): Boolean;
begin
  Change;
  Result := True;
end;

function TCustomComboBox.DoHandleFocusIn(Event: TEventListenerEvent): Boolean;
begin
  if Assigned(OnFocusIn) then
    OnFocusIn(Self);
  Result := true;
end;

function TCustomComboBox.DoHandleFocusOut(Event: TEventListenerEvent): Boolean;
begin
  if Assigned(OnFocusOut) then
    OnFocusOut(Self);
  Result := true;
end;

function TCustomComboBox.DoHandleInput(Event: TEventListenerEvent): Boolean;
begin
  if Style = csDropDown then
    Change;
  Result := True;
end;

procedure TCustomComboBox.Loaded;
begin
  inherited;
  DoUpdateList;
end;

procedure TCustomComboBox.LoadState(AState: string);
var
  idx,e: integer;
begin
  val(AState, idx, e);
  if e = 0 then
    ItemIndex := idx;
end;

procedure TCustomComboBox.PersistinHTML;
var
  sel: TJSHTMLElement;
begin
  inherited;

  sel := TJSHTMLElement(ElementHandle.children[ItemIndex]);
  if Assigned(sel) then
    sel.setAttribute('selected','selected');
end;

function TCustomComboBox.SaveState: string;
begin
  Result := IntToStr(ItemIndex);
end;

procedure TCustomComboBox.SetDroppedDown(const Value: Boolean);
begin
  FDroppedDown := Value;
end;

procedure TCustomComboBox.SetItemIndex(AIndex: integer);
begin
  if (FItemIndex <> AIndex) then
  begin
    FItemIndex := AIndex;

    if (AIndex >= 0) and (Style in [csDropDown, csDropDownList]) and (AIndex < Items.Count) then
      FText := Items[AIndex];

    UpdateElement;
  end;
end;

procedure TCustomComboBox.DoItemsChange(Sender: TObject);
begin
  DoUpdateList;
end;

procedure TCustomComboBox.AddTextHint;
var
  opt: TJSElement;

begin
  if (TextHint <> '') and (Style = csDropDownList) then
  begin
    opt := document.createElement('OPTION');
    opt['value'] := '';
    opt['disabled'] := 'true';
    opt['selected'] := 'true';
    opt['hidden'] := 'true';
    opt.innerHTML := TextHint;
    Container.appendChild(opt);
  end;
end;

procedure TCustomComboBox.DoUpdateList;
var
  i,j: integer;
  s,k,v: string;
  opt: TJSElement;
  el: TJSElement;
begin
  if not Assigned(Container) then
    Exit;

  if ElementHandle.tagName = 'INPUT' then
  begin
    TJSHTMLInputElement(ElementHandle).value := Text;

    el := document.getElementById(Name+'_LIST');
    if Assigned(el) then
      el.parentNode.removeChild(el);

    if TextHint <> '' then
      TJSHTMLInputElement(ElementHandle).placeholder := TextHint;

    el := document.createElement('DATALIST');
    el['id'] := FListName;
    document.body.appendChild(el);

    for i := 0 to FItems.Count - 1 do
    begin
      s := FItems[i];
      opt := document.createElement('OPTION');

      k := s;
      v := s;
      j := pos(FItems.NameValueSeparator,s);

      if j > 0 then
      begin
        v := copy(s,1,j-1);
        k := copy(s,j+1,$FFFF);
      end;

      opt['value'] := k;
      opt.innerHTML := v;
      el.appendChild(opt);
    end;

  end
  else
  begin
    if ElementHandle.tagName <> 'SELECT' then
      Exit;

    for i := TJSHTMLSelectElement(Container).options.length - 1 downto 0 do
      TJSHTMLSelectElement(Container).remove(i);

    AddTextHint;

    for i := 0 to FItems.Count - 1 do
    begin
      s := FItems[i];
      opt := document.createElement('OPTION');

      k := s;
      v := s;
      j := pos(FItems.NameValueSeparator,s);

      if j > 0 then
      begin
        v := copy(s,1,j-1);
        k := copy(s,j+1,$FFFF);
      end;

      opt['value'] := k;
      opt.innerHTML := v;
      Container.appendChild(opt);
    end;
  end;

  UpdateElement;
end;


{ TFontPicker }

procedure TFontPicker.CreateInitialize;
var
  tst: boolean;
  
  procedure Add(s: string);
  begin
    asm
      tst = d.detect(s);
    end;
    if tst then
      Self.Items.Add(s);
  end;

begin
  inherited;

  asm
    var Detector = function() {
    // a font will be compared against all the three default fonts.
    // and if it doesn't match all 3 then that font is not available.
    var baseFonts = ['monospace', 'sans-serif', 'serif'];

    //we use m or w because these two characters take up the maximum width.
    // And we use a LLi so that the same matching fonts can get separated
    var testString = "mmmmmmmmmmlli";

    //we test using 72px font size, we may use any size. I guess larger the better.
    var testSize = '72px';

    var h = document.getElementsByTagName("body")[0];

    // create a SPAN in the document to get the width of the text we use to test
    var s = document.createElement("span");
    s.style.fontSize = testSize;
    s.innerHTML = testString;
    var defaultWidth = {};
    var defaultHeight = {};
    for (var index in baseFonts) {
        //get the default width for the three base fonts
        s.style.fontFamily = baseFonts[index];
        h.appendChild(s);
        defaultWidth[baseFonts[index]] = s.offsetWidth; //width for the default font
        defaultHeight[baseFonts[index]] = s.offsetHeight; //height for the defualt font
        h.removeChild(s);
    }

    function detect(font) {
        var detected = false;
        for (var index in baseFonts) {
            s.style.fontFamily = font + ',' + baseFonts[index]; // name of the font along with the base font for fallback.
            h.appendChild(s);
            var matched = (s.offsetWidth != defaultWidth[baseFonts[index]] || s.offsetHeight != defaultHeight[baseFonts[index]]);
            h.removeChild(s);
            detected = detected || matched;
        }
        return detected;
    }

    this.detect = detect;
    };

    var d = new Detector();
    //tst = d.detect("Arial");
  end;

  Add('Arial');
  Add('Arial Black');
  Add('Arial Narrow');
  Add('Courier');
  Add('Courier New');
  Add('Georgia');
  Add('Lucida Console');
  Add('Modena');
  Add('Monotype Corsiva');
  Add('Papyrus');
  Add('Tahoma');
  Add('Times');
  Add('Times New Roman');
  Add('Trebuchet MS');
  Add('Verdana');
  Add('Verona');
end;

{ TFontSizePicker }

procedure TFontSizePicker.CreateInitialize;
begin
  inherited;
  PickerMode := fmPointSize;
end;

procedure TFontSizePicker.Init;
begin
  Items.Clear;

  case PickerMode of
    fmPointSize:
    begin
      Items.Add('8');
      Items.Add('9');
      Items.Add('10');
      Items.Add('11');
      Items.Add('12');
      Items.Add('14');
      Items.Add('16');
      Items.Add('18');
      Items.Add('20');
      Items.Add('22');
      Items.Add('24');
      Items.Add('26');
      Items.Add('28');
      Items.Add('36');
      Items.Add('48');
      Items.Add('72');
    end;
    fmRelativeSize:
    begin
      Items.Add('8');
      Items.Add('9');
      Items.Add('10');
      Items.Add('14');
      Items.Add('18');
      Items.Add('24');
      Items.Add('36');
    end;
  end;
end;

procedure TFontSizePicker.SetPickerMode(const AValue: TFontSizePickerMode);
begin
  FPickerMode := AValue;
  Init;
end;


{ TCustomMemo }

function TCustomMemo.DoHandleChange(Event: TEventListenerEvent): Boolean;
begin
  FModified := true;
  Change;
  Result := true;
end;

function TCustomMemo.DoHandleCut(Event: TEventListenerEvent): Boolean;
begin
  if not CanCut then
  begin
    asm
      Event.preventDefault();
      Event.stopPropagation();
    end;
  end;

  Result := False;
end;

procedure TCustomMemo.BindEvents;
begin
  inherited;

  if Assigned(ElementInputHandle) then
  begin
    ElementInputHandle.addEventListener('input', FHandleInputPtr);
    ElementInputHandle.addEventListener('change', FHandleChangePtr);
    ElementInputHandle.addEventListener('paste', FHandlePastePtr);
    ElementInputHandle.addEventListener('cut', FHandleCutPtr);
  end;
end;

procedure TCustomMemo.UnBindEvents;
begin
  inherited;

  if Assigned(ElementInputHandle) then
  begin
    ElementInputHandle.removeEventListener('input', FHandleInputPtr);
    ElementInputHandle.removeEventListener('change', FHandleChangePtr);
    ElementInputHandle.removeEventListener('paste', FHandlePastePtr);
    ElementInputHandle.removeEventListener('cut', FHandleCutPtr);
  end;
end;


procedure TCustomMemo.Change;
begin
  if Assigned(OnChange) then
    OnChange(Self);
end;

procedure TCustomMemo.Clear;
begin
  Lines.Clear;
end;

procedure TCustomMemo.ClearMethodPointers;
begin
  inherited;
  FHandleChangePtr := nil;
  FHandleInputPtr := nil;
  FHandlePastePtr := nil;
  FHandleCutPtr := nil;
end;

{$HINTS OFF}
procedure TCustomMemo.CopyToClipboard;
var
  s: string;
begin
  s := Text;
  asm
    navigator.clipboard.writeText(s);
  end;
end;
{$HINTS ON}

procedure TCustomMemo.CreateInitialize;
begin
  inherited;
  FSpellCheck := true;
  FLines := TStringList.Create;
  FLines.SkipLastLineBreak := true;
  TStringList(FLines).OnChange := DoLinesChange;
  if (csDesigning in ComponentState) then
  begin
    Width := 400;
    Height := 300;
  end;
  ShowFocus := true;
  ClipChildren := false;
  FWantTabs := false;
  FAutoCompletion := acOff;
  FWordWrap := true;
  NoUserSelect := false;
end;

function TCustomMemo.CreateElement: TJSElement;
begin
  Result := document.createElement('TEXTAREA');
end;

destructor TCustomMemo.Destroy;
begin
  FLines.Free;
  inherited;
end;

function TCustomMemo.DoHandleInput(Event: TEventListenerEvent): Boolean;
begin
  GetText;
  Change;
  Result := True;
end;

function TCustomMemo.DoHandlePaste(Event: TEventListenerEvent): Boolean;
var
  s: string;
begin
  asm
    var clipboardData = Event.clipboardData || window.clipboardData;
    s = clipboardData.getData('Text');
  end;

  if not CanPaste(s) then
  begin
    asm
      Event.preventDefault();
      Event.stopPropagation();
    end;
  end;

  Result:= false;
end;

procedure TCustomMemo.DoLinesChange(Sender: TObject);
begin
  if Assigned(ElementInputHandle) and not FBlockChange then
  begin
    ElementInputHandle.value := GetDisplayText;
    ElementInputHandle.readOnly := IsReadOnly;
   end;
end;

function TCustomMemo.CanCut: boolean;
begin
  Result := True;
end;

function TCustomMemo.CanPaste(AValue: string): boolean;
begin
  Result := True;
end;

function TCustomMemo.SaveState: string;
begin
  Result := Lines.Text;
end;

procedure TCustomMemo.SelectAll;
begin
  if Assigned(ElementInputHandle) then
    ElementInputHandle.select;
end;

procedure TCustomMemo.SetAutoCompletion(const Value: TAutoCompletion);
begin
  if (FAutoCompletion <> Value) then
  begin
    FAutoCompletion := Value;
    UpdateElementData;
  end;
end;

procedure TCustomMemo.SetAutoSize(const Value: Boolean);
begin
  if FAutoSize <> Value then
  begin
    FAutoSize := Value;
    UpdateElement;
  end;
end;

procedure TCustomMemo.SetCaret(x, y: integer);
var
  i,l: integer;
begin
  l := 0;
  for i := 0 to Lines.Count -1 do
  begin
    if i < y then
      l := l + Length(Lines[i]) + 1;
  end;

  SelStart := l + x;
end;

procedure TCustomMemo.SetCaretPos(const Value: TPoint);
begin
  SetCaret(Value.X, Value.Y);
end;

procedure TCustomMemo.SetLines(ALines: TStrings);
begin
  FLines.Assign(ALines);
end;

procedure TCustomMemo.SetReadOnly(const Value: boolean);
begin
  if (FReadOnly <> Value) then
  begin
    FReadOnly := Value;
    UpdateElement;
  end;
end;

procedure TCustomMemo.SetSelLength(const Value: Integer);
begin
  if FSelLength <> Value then
  begin
    FSelLength := Value;
    UpdateElement;
  end;
end;

procedure TCustomMemo.SetSelStart(const Value: Integer);
begin
  if FSelStart <> Value then
  begin
    FSelStart := Value;
    UpdateElement;
  end;
end;

procedure TCustomMemo.SetSpellCheck(const Value: boolean);
begin
  if FSpellCheck <> Value then
  begin
    FSpellCheck := Value;
    UpdateElement;
  end;
end;

procedure TCustomMemo.SetText(const Value: String);
begin
  FLines.Text := Value;
end;

procedure TCustomMemo.SetTextHint(const Value: string);
begin
  if FTextHint <> Value then
  begin
    FTextHint := Value;
    UpdateElement;
  end;

end;

procedure TCustomMemo.SetWantTabs(const Value: boolean);
begin
  if (FWantTabs <> Value) then
  begin
    FWantTabs := Value;
    UpdateElement;
  end;
end;

procedure TCustomMemo.SetWordWrap(const Value: Boolean);
begin
  if (FWordWrap <> Value) then
  begin
    FWordWrap := Value;
    UpdateElement;
  end;
end;

{$HINTS OFF}
procedure TCustomMemo.UpdateElementData;
var
  ss,sl: integer;
  e: TJSHTMLElement;
begin
  inherited;
  if Assigned(ElementInputHandle) and not FBlockChange then
  begin
    ElementInputHandle.value := GetDisplayText;

    if (ElementInputHandle.TagName = 'TEXTAREA') then
    begin
      ElementInputHandle.setSelectionRange(SelStart, SelStart + SelLength);
    end;

    if not IsLinked then
    begin
      ElementInputHandle.style.setProperty('resize', 'none');
    end;

    ElementInputHandle.readOnly := IsReadOnly;

    if TextHint <> '' then
      ElementInputHandle.placeholder := TextHint;

    if not isLinked then
    begin
      if AutoCompletion = acNone then
      begin
        TJSHTMLElement(ElementInputHandle).setAttribute('autocomplete','none');
        if not IsLinked then
          TJSHTMLElement(ElementInputHandle).removeAttribute('name');
      end
      else
      if AutoCompletion <> acOff then
      begin
        TJSHTMLElement(ElementInputHandle).setAttribute('autocomplete','on');
        if not IsLinked then
          TJSHTMLElement(ElementInputHandle).setAttribute('name', GetAutoCompletionName(AutoCompletion));
      end
      else
      begin
        TJSHTMLElement(ElementInputHandle).removeAttribute('autocomplete');
        if not IsLinked then
          TJSHTMLElement(ElementInputHandle).removeAttribute('name');
      end;

      ss := FSelStart;
      sl := FSelStart + FSelLength;
      e := ElementInputHandle;
      asm
        setTimeout(function() {
          e.setSelectionRange(ss, sl);
        }, 1);
      end;
    end;
  end;
end;
{$HINTS ON}

procedure TCustomMemo.UpdateElementVisual;
begin
  inherited;
  if Assigned(ElementInputHandle) and not FBlockChange and not IsLinked then
  begin
    ElementInputHandle.style.setProperty('overflow', 'auto');
    ElementInputHandle.style.setProperty('margin', '0');

    if (Color <> clNone) and (Color <> clWindow) then
      ElementInputHandle.style.setProperty('background-color', ColorToHtml(Color))
    else
      ElementInputHandle.style.removeProperty('background-color');

    if not SpellCheck then
      ElementInputHandle['spellcheck'] := 'false'
    else
      if ElementInputHandle.hasAttribute('spellcheck') then
        ElementInputHandle.removeAttribute('spellcheck');

    if not WordWrap then
      ElementInputHandle['wrap'] := 'off'
    else
      if ElementInputHandle.hasAttribute('wrap') then
        ElementInputHandle.removeAttribute('wrap');
  end;
end;

function TCustomMemo.GetText: String;
begin
  if Assigned(ElementInputHandle) then
  begin
    FBlockChange := True;
    FLines.Text := ElementInputHandle.value;
    FBlockChange := False;
  end;

  Result := FLines.Text;
end;

{$HINTS OFF}
function TCustomMemo.HandleDoKeyDown(Event: TJSKeyBoardEvent): Boolean;
var
  eh: TJSHTMLElement;
begin
  if (GetKeyCode(Event.Key) = 9) and WantTabs then
  begin
    eh := ElementInputHandle;

    asm
      var sstart = eh.selectionStart;
      var send = eh.selectionEnd;

      eh.value = eh.value.substring(0, sstart) + "\t" +eh.value.substring(send);
      // put caret at right position again
      eh.selectionStart = eh.selectionEnd = sstart + 1;

      Event.stopPropagation();
      Event.preventDefault();
    end;
  end
  else
    inherited;
end;
{$HINTS ON}

function TCustomMemo.IsInputControl: Boolean;
begin
  Result := True;
end;

function TCustomMemo.IsReadOnly: boolean;
begin
  Result := FReadOnly;
end;

procedure TCustomMemo.LoadState(AState: string);
begin
  Lines.Text := AState;
end;

procedure TCustomMemo.PersistinHTML;
begin
  ElementInputHandle.innerHTML := Lines.Text;
end;

function TCustomMemo.GetCaret: TPoint;
var
  l,i,ll,tl: integer;
  ss: integer;
begin
  l := 0;
  tl := 0;
  ss := SelStart;
  for i := 0 to Lines.Count - 1 do
  begin
    ll := Length(Lines[i]) + 1;
    if ss > tl + ll then
    begin
      inc(l);
      tl := tl + ll;
    end
    else
    begin
      break;
    end;
  end;

  Result.X := ss - tl;
  Result.Y := l;
end;

function TCustomMemo.GetCaretPos: TPoint;
begin
  Result := GetCaret;
end;

function TCustomMemo.GetDisplayText: string;
begin
  Result := FLines.Text;
end;

function TCustomMemo.GetElementInputHandle: TJSHTMLInputElement;
begin
  Result := TJSHTMLInputElement(Container);
end;

procedure TCustomMemo.GetMethodPointers;
begin
  inherited;
  FHandleChangePtr := @DoHandleChange;
  FHandleInputPtr := @DoHandleInput;
  FHandlePastePtr := @DoHandlePaste;
  FHandleCutPtr := @DoHandleCut;
end;

function TCustomMemo.GetSelLength: Integer;
begin
  Result := -1;
  if Assigned(ElementInputHandle) then
    Result := ElementInputHandle.selectionEnd - ElementInputHandle.selectionStart;
end;

function TCustomMemo.GetSelStart: Integer;
begin
  Result := -1;
  if Assigned(ElementInputHandle) then
    Result := ElementInputHandle.selectionStart;
end;

{ TControlGroup }

procedure TControlGroup.CreateInitialize;
begin
  inherited;
  FColumns := 1;
  FControls := TList.Create;
  FItems := TStringList.Create;
  TStringList(FItems).OnChange := DoItemsChange;
  //BorderWidth := 1;
  FOldCount := -1;
  FControlPosition := epAbsolute;
  if (csDesigning in ComponentState) then
  begin
    Width := 200;
    Height := 200;
  end;
end;

procedure TControlGroup.Change;
begin
  if Assigned(OnChange) then
    OnChange(Self);
end;

function TControlGroup.CreateGroupControl(ALeft, ATop, AIndex: integer;
  ACaption: string; APosition: TElementPosition; AState, AEnabled: boolean): TCustomControl;
begin
  //
  Result := nil;
end;

function TControlGroup.CreateElement: TJSElement;
var
  legend: TJSElement;
begin
  Result := document.createElement('FIELDSET');
  legend := document.createElement('LEGEND');
  Result.appendChild(legend);
  legend.innerHTML := Caption;
  legend['class'] := 'w-auto ' + ElementLegendClassName;

  if Caption = '' then
    TJSHTMLElement(legend).style.setProperty('display', 'none')
  else
    TJSHTMLElement(legend).style.setProperty('display', '');

  TJSHTMLElement(legend).style.setProperty('float', 'none');

  TJSHTMLElement(Result).style.setProperty('display', 'inline-block');

  TJSHTMLElement(Result).style.setProperty('-webkit-padding-before','0px');
  TJSHTMLElement(Result).style.setProperty('-webkit-padding-after','0px');
  TJSHTMLElement(Result).style.setProperty('-webkit-padding-end','0px');
  TJSHTMLElement(Result).style.setProperty('-webkit-padding-start','0px');
  TJSHTMLElement(Result).style.setProperty('border','1px');
end;

destructor TControlGroup.Destroy;
begin
  FControls.Free;
  FItems.Free;
  inherited;
end;

procedure TControlGroup.Loaded;
begin
  inherited;
  DoUpdateList;
end;

procedure TControlGroup.SetCaption(const AValue: string);
var
  el: TJSHTMLElement;
begin
  inherited SetCaption(AValue);

  if not Assigned(Container) then
    Exit;

  el := TJSHTMLElement(Container.firstElementChild);

  if Assigned(el) then
  begin
    el.innerHTML := AValue;

    if AValue = '' then
      el.style.setProperty('display', 'none')
    else
      el.style.setProperty('display', '');
  end;
end;

procedure TControlGroup.SetColumns(AValue: integer);
begin
  if (FColumns > 0) and (FColumns <> AValue) then
  begin
    FColumns := AValue;
    DoUpdateList;
  end;
end;

procedure TControlGroup.SetControlPosition(const Value: TElementPosition);
begin
  if (FControlPosition <> Value) then
  begin
    FControlPosition := Value;
    DoUpdateList;
  end;
end;

procedure TControlGroup.SetEnabled(Value: Boolean);
var
  i: integer;
begin
  inherited;
  for i := 0 to FControls.Count - 1 do
  begin
    TCustomControl(FControls.Items[i]).Enabled := Value;
  end;
end;

procedure TControlGroup.SetHeight(AValue: Integer);
begin
  inherited;
  DoUpdateList;
end;

procedure TControlGroup.SetItems(AItems: TStrings);
begin
  FItems.Assign(AItems);
  DoUpdateList;
end;

procedure TControlGroup.SetWidth(AValue: Integer);
begin
  inherited;
  DoUpdateList;
end;

function TControlGroup.DoHandleChange(Event: TEventListenerEvent): Boolean;
begin
  Change;
  Result := true;
end;

procedure TControlGroup.DoHandleClick(AControl: TCustomControl);
begin
  Change;
end;

procedure TControlGroup.DoItemsChange(Sender: TObject);
begin
  DoUpdateList;
end;

procedure TControlGroup.DoControlClick(Sender: TObject);
begin
  DoHandleClick((Sender as TCustomControl));
end;

procedure TControlGroup.DoUpdateList;
var
  i: integer;
  s: string;
  rd: TCustomControl;
  dx, dy, dc, col, row, ypos: integer;
  legend: TJSElement;
  chk: array of boolean;
begin
  if not Assigned(Container) then
    Exit;

  if FItems.Count = 0 then
    Exit;

  dy := Round(Font.Size * 2.5);
  dx := Width div FColumns;
  if Caption <> '' then
    dc := Font.Size
  else
    dc := 4;

  col := 0;
  row := 0;

  if FOldCount > 0 then
    setLength(chk, FOldCount);

  for i := 0 to FOldCount - 1 do
  begin
    rd := TCustomControl(FControls[i]);

    if rd is TRadioButton then
      chk[i] := (rd as TRadioButton).Checked;
    if rd is TCheckBox then
      chk[i] := (rd as TCheckBox).Checked;

    rd.Free;
  end;
  FControls.Clear;

  while Container.childElementCount > 0 do
    Container.removeChild(Container.firstChild);

  if Caption <> '' then
  begin
    legend := document.createElement('LEGEND');
    ElementHandle.appendChild(legend);
    legend.innerHTML := Caption;
    legend['class'] := 'w-auto ' + ElementLegendClassName;

    TJSHTMLElement(legend).style.setProperty('float','none');

    SetHTMLElementFont(TJSHTMLElement(legend), Font, not((ElementFont = efProperty) and (ElementClassName = '')));
  end;

  if ElementGroupClassName <> '' then
    ElementHandle.setAttribute('class', ElementGroupClassName)
  else
    ElementHandle.setAttribute('class','border p-2');

  TJSHTMLElement(Container).style.setProperty('margin','0px');

  for i := 0 to FItems.Count - 1 do
  begin
    ypos := dc + row * dy;
    s := FItems[i];
    rd := CreateGroupControl(4 + col * dx, ypos, i, s, ControlPosition, GetGroupControlState(i), true);
    rd.Enabled := Enabled;
    FControls.Add(rd);

    if i <= FOldCount then
    begin
      if rd is TRadioButton then
        (rd as TRadioButton).Checked := chk[i];
      if rd is TCheckBox then
        (rd as TCheckBox).Checked := chk[i];
    end;

    if not IsLinked then
    begin
      TJSHTMLElement(rd.Container).style.setProperty('float', 'left');
      TJSHTMLElement(rd.Container).style.setProperty('width',  IntToStr(Trunc(100 / FColumns) - 5) + '%');
      if TabStop then
      begin
        TJSHTMLElement(rd.Container)['tabindex'] := TabOrder.toString;
        TJSHTMLElement(Container)['tabindex'] := '-1';
      end
      else
        TJSHTMLElement(rd.Container)['tabindex'] := '-1';
    end;

    if i mod FColumns = 0 then
      TJSHTMLElement(rd.Container).style.setProperty('clear', 'left');

    ElementHandle.appendChild(rd.Container);

    if col < FColumns - 1 then
      inc(col)
    else
    begin
      col := 0;
      inc(row);
    end;
  end;

  FOldCount := Items.Count;
end;

procedure TControlGroup.EndUpdate;
begin
  inherited;
  DoUpdateList;
end;

procedure TControlGroup.FontChanged;
begin
  inherited;
  DoUpdateList;
end;

function TControlGroup.GetGroupControl(AIndex: integer): TCustomControl;
begin
  Result := TCustomControl(FControls[AIndex]);
end;

function TControlGroup.GetGroupControlState(AIndex: integer): boolean;
begin
  Result := false;
end;

function TRadioGroup.CreateGroupControl(ALeft, ATop, AIndex: integer;
  ACaption: string; APosition: TElementPosition; AState, AEnabled: boolean): TCustomControl;
var
  rd: TRadioButton;
  id: string;
begin
  id := GetID;
  if (id = '') then
    id := Name;

  rd := TRadioButton.Create(id + IntToStr(AIndex));
  rd.ElementPosition := APosition;
  rd.ElementFont := ElementFont;
  rd.ElementClassName := ElementClassName;
  rd.ElementButtonClassName := ElementButtonClassName;
  rd.ElementLabelClassName := ElementLabelClassName;
  rd.HeightStyle := ssAuto;
  rd.ParentFont := false;
  rd.Font.Assign(Font);
  rd.Parent := Self;
  rd.Caption := ACaption;
  rd.GroupName := id;
  rd.Top := ATop; //dc + row * dy;
  rd.Left := ALeft; //4 + col * dx;
  rd.WidthStyle := ssAuto;
  rd.WidthPercent := 100;
  rd.OnClick := DoControlClick;
  rd.Checked := AState;
  rd.Enabled := AEnabled;
  rd.Tag := AIndex;
  rd.TabStop := true;
  Result := rd;
end;

procedure TRadioGroup.CreateInitialize;
begin
  inherited;
  FItemIndex := -1;
  FOldItemIndex := -2;
end;

procedure TRadioGroup.DoHandleClick(AControl: TCustomControl);
begin
  if ItemIndex = FOldItemIndex then
    Exit;

  Change;

  FOldItemIndex := ItemIndex;
end;

function TRadioGroup.GetGroupControlState(AIndex: integer): boolean;
begin
  Result := AIndex = FItemIndex;
end;

function TRadioGroup.GetItemIndex: integer;
var
  el: TJSElement;
  i: integer;
begin
  Result := FItemIndex;

  for i := 0 to FItems.Count -1 do
  begin
    el := document.getElementByID(GetID + IntToStr(i) + 'rd');
    if Assigned(el) then
    begin
      if TJSHTMLInputElement(el).checked then
        Result := i;
    end;
  end;
end;

procedure TRadioGroup.LoadState(AState: string);
var
  idx,e: integer;
begin
  val(AState, idx, e);

  if (e = 0) then
    ItemIndex := idx;
end;

function TRadioGroup.SaveState: string;
begin
  Result := IntToStr(ItemIndex);
end;

procedure TRadioGroup.SetFocus;
var
  idx: integer;
  rd: TRadioButton;
begin
  if Items.Count = 0 then
    Exit;

  idx := ItemIndex;
  if idx < 0 then
    idx := 0;

  rd := TRadioButton(GetGroupControl(idx));
  if Assigned(rd) then
    rd.SetFocus;
end;

procedure TRadioGroup.SetItemIndex(AIndex: integer);
var
  el: TJSElement;
begin
  // clear selection
  if (AIndex = -1) and (ItemIndex >= 0) then
  begin
    el := document.getElementByID(GetID + IntToStr(ItemIndex) + 'rd');

    if Assigned(el) then
      TJSHTMLInputElement(el).checked := False;

    FItemIndex := -1;
  end
  else
  begin
    // new selection
    FItemIndex := AIndex;
    if (AIndex >= 0) and (AIndex < Items.Count) then
    begin
      el := document.getElementByID(GetID + IntToStr(AIndex) + 'rd');
      if Assigned(el) then
        TJSHTMLInputElement(el).checked := True;
    end;
  end;
end;



{ TColorPicker }

procedure TColorPicker.ClearMethodPointers;
begin
  inherited;
  FHandleChangePtr := nil;
end;

procedure TColorPicker.CreateInitialize;
begin
  inherited;
  FColor := clBlack;
end;

procedure TColorPicker.BindEvents;
begin
  inherited;
  if Assigned(ElementInputHandle) then
  begin
    ElementInputHandle.addEventListener('input', FHandleChangePtr);
  end;
end;

function TColorPicker.GetElementInputHandle: TJSHTMLInputElement;
begin
  Result := TJSHTMLInputElement(Container);
end;

procedure TColorPicker.UnBindEvents;
begin
  inherited;
  if Assigned(ElementInputHandle) then
  begin
    ElementInputHandle.removeEventListener('input', FHandleChangePtr);
  end;

end;

procedure TColorPicker.UpdateElementVisual;
begin
  inherited;

  if Assigned(ElementHandle) then
  begin
    ElementHandle.style.setProperty('padding','0px'); 
    ElementHandle.style.setProperty('width', IntToStr(Width - 2) + 'px');
    ElementHandle.style.setProperty('height',IntToStr(Height - 2) + 'px');
  end;
end;

function TColorPicker.DoHandleChange(Event: TEventListenerEvent): Boolean;
begin   
  Select;
  Result := true;
end;

procedure TColorPicker.Select;
begin
  if Assigned(OnSelect) then
    OnSelect(Self);
end;

function TColorPicker.GetColor: TColor;
begin
  Result := FColor;

  if Assigned(Container) then
    Result := HexToColor(TJSHTMLInputElement(Container).value);
end;

procedure TColorPicker.SetColor(AValue: TColor);
begin
  FColor := AValue;

  if Assigned(Container) then
    TJSHTMLInputElement(Container).value := ColorToHTML(AValue);
end;

function TColorPicker.GetInputType: string;
begin 
  Result := 'COLOR';
end;

procedure TColorPicker.GetMethodPointers;
begin
  inherited;
  FHandleChangePtr := @DoHandleChange;
end;

{ TScrollBar }

procedure TScrollBar.CreateInitialize;
begin
  inherited;
  TabStop := True;
  FKind := sbHorizontal;
  FPosition := 0;
  FMin := 0;
  FMax := 100;
  FSmallChange := 1;
  FLargeChange := 1;

  FContent := TScrollBarContent.Create(Self);
  FContent.Parent := Self;
  FContent.Width := 121;
  FContent.Height := 17;

  if (csDesigning in ComponentState) then
  begin
    Width := 121;
    Height := 17;
  end;

  UpdateContent;
end;

procedure TScrollBar.BindEvents;
begin
  inherited;
  if Assigned(ElementHandle) then
    ElementHandle.addEventListener('scroll', FScrollPtr);
end;

procedure TScrollBar.ClearMethodPointers;
begin
  inherited;
  FScrollPtr := nil;
end;

function TScrollBar.CreateElement: TJSElement;
begin
  Result := document.createElement('SPAN');
end;

destructor TScrollBar.Destroy;
begin
  FContent.Free;
  inherited;
end;

function TScrollBar.DoScroll(Event: TJSUIEvent): Boolean;
begin
  FScrolling := True;
  if Assigned(OnChange) then
    OnChange(Self);

  FScrolling := False;
  Result := True;
end;

procedure TScrollBar.GetMethodPointers;
begin
  inherited;
  FScrollPtr := @DoScroll;
end;

function TScrollBar.GetPageSize: Integer;
begin
  Result := FPageSize;
  if Result = 0 then
    Result := 25;
end;

function TScrollBar.GetValue(XYPos: Double): Double;
begin
  Result := 0;
  if Assigned(FContent) then
  begin
    case Kind of
      sbHorizontal:
      begin
        if FContent.Width > 0 then
          Result := (XYPos / FContent.Width) * (Max - Min)
      end;
      sbVertical:
      begin
        if FContent.Height > 0 then
          Result := (XYPos / FContent.Height) * (Max - Min);
      end;
    end;
  end;
end;

function TScrollBar.GetPosition: Integer;
begin
  Result := 0;
  if Assigned(Element) then
  begin
    case Kind of
      sbHorizontal: Result := Round(GetValue(Element.scrollLeft));
      sbVertical: Result := Round(GetValue(Element.scrollTop));
    end;
  end
  else
    Result := FPosition;
end;

procedure TScrollBar.Loaded;
begin
  inherited;
  UpdateContent;
end;

procedure TScrollBar.SetBounds(X, Y, AWidth, AHeight: Integer);
begin
  inherited;
  UpdateContent;
end;

procedure TScrollBar.SetKind(const Value: TScrollBarKind);
begin
  if FKind <> Value then
  begin
    FKind := Value;
//    if not (csLoading in ComponentState) then
    begin
      case Kind of
        sbHorizontal:
        begin
          SetBounds(Left, Top, Height, 17)
        end;
        sbVertical:
        begin
          SetBounds(Left, Top, 17, Width)
        end;
      end;
    end;
    UpdateElement;
    UpdateContent;
  end;
end;

procedure TScrollBar.SetMax(const Value: Integer);
begin
  if FMax <> Value then
  begin
    FMax := Value;
    UpdateContent;
  end;
end;

procedure TScrollBar.SetMin(const Value: Integer);
begin
  if FMin <> Value then
  begin
    FMin := Value;
    UpdateContent;
  end;
end;

procedure TScrollBar.SetPageSize(const Value: Integer);
begin
  if FPageSize <> Value then
  begin
    FPageSize := Value;
    UpdateContent;
  end;
end;

procedure TScrollBar.SetPosition(const Value: Integer);
begin
  if FPosition <> Value then
  begin
    FPosition := Value;
    UpdateContent;
  end;
end;

procedure TScrollBar.UpdateElementVisual;
begin
  inherited;

  if Assigned(ElementHandle) then
  begin
    ElementHandle.style.setProperty('overflow', 'auto');
    case Kind of
      sbHorizontal:
      begin
        ElementHandle.style.setProperty('overflow-y', 'hidden');
        ElementHandle.style.setProperty('overflow-x', '');
      end;
      sbVertical:
      begin
        ElementHandle.style.setProperty('overflow-y', '');
        ElementHandle.style.setProperty('overflow-x', 'hidden');
      end;
    end;
  end;
end;

procedure TScrollBar.UnBindEvents;
begin
  inherited;
  if Assigned(ElementHandle) then
    ElementHandle.removeEventListener('scroll', FScrollPtr);
end;

procedure TScrollBar.UpdateContent;
var
  x, y, w, h: Integer;
  ps: Integer;
  v: Single;
begin
  if not Assigned(FContent) or not Assigned(ElementHandle) or FScrolling then
    Exit;

  ps := PageSize;
  v := (Max - Min) / ps;

  case Kind of
    sbHorizontal:
    begin
      y := 0;
      h := Height;
      w := Round(v * Width);
      x := Round(((FPosition - Min) / (Max - Min)) * w);
    end;
    sbVertical:
    begin
      x := 0;
      w := Width;
      h := Round(v * Height);
      y := Round(((FPosition - Min) / (Max - Min)) * h);
    end;
  end;

  FContent.SetBounds(0, 0, w, h);
  ElementHandle.scrollLeft := x;
  ElementHandle.scrollTop := y;
end;

{ TScrollBarContent }

function TScrollBarContent.CreateElement: TJSElement;
begin
  Result := document.createElement('SPAN');
end;

{ TCheckGroup }

function TCheckGroup.CreateGroupControl(ALeft, ATop, AIndex: integer;
  ACaption: string; APosition: TElementPosition; AState, AEnabled: boolean): TCustomControl;
var
  cb: TCheckBox;
  id: string;
begin
  id := GetID;
  if id = '' then
    id := Name;

  cb := TCheckBox.Create(id + IntToStr(AIndex));
  cb.Caption := ACaption;
  cb.ElementPosition := APosition;
  cb.ElementFont := ElementFont;
  cb.ElementClassName := ElementClassName;
  cb.ElementButtonClassName := ElementButtonClassName;
  cb.ElementLabelClassName := ElementLabelClassName;
  cb.ParentFont := false;
  cb.Font.Assign(Font);
  cb.Parent := Self;
  cb.Top := ATop;
  cb.Left := ALeft;
  cb.WidthStyle := ssPercent;
  cb.WidthPercent := 100;
  cb.HeightStyle := ssAuto;

  cb.OnCheckClick := DoControlClick;
  cb.Checked := AState;
  cb.Enabled := AEnabled;
  cb.Tag := AIndex;
  cb.TabStop := true;
  Result := cb;
end;

procedure TCheckGroup.CreateInitialize;
begin
  inherited;
end;

procedure TCheckGroup.DoHandleClick(AControl: TCustomControl);
begin
  inherited;
  if Assigned(OnCheckClick) then
    OnCheckClick(Self, AControl.Tag);
end;

function TCheckGroup.GetChecked(AIndex: integer): boolean;
begin
  Result := (GetGroupControl(AIndex) as TCheckBox).Checked;
end;

function TCheckGroup.GetGroupControlState(AIndex: integer): boolean;
begin
  Result := false;
end;

procedure TCheckGroup.LoadState(AState: string);
var
  sl: TStringList;
  i: integer;
begin
  sl := TStringList.Create;
  sl.Delimiter := ',';
  sl.CommaText := AState;

  for i := 0 to sl.Count - 1 do
  begin
    Checked[i] := sl.Strings[i] = '1';
  end;

  sl.Free;
end;

function TCheckGroup.SaveState: string;
var
  i: integer;
  s: string;
begin
  s := '';
  for i := 0 to Items.Count - 1 do
  begin
    if Checked[i] then
      s := s + ',1'
    else
      s := s + ',0';
  end;

  Delete(s,1,1);
  Result := s;
end;

procedure TCheckGroup.SetChecked(AIndex: integer; const Value: boolean);
begin
  (GetGroupControl(AIndex) as TCheckBox).Checked := Value;
end;

procedure TCheckGroup.SetFocus;
var
  cb: TCheckBox;
begin
  if Items.Count > 0 then
  begin
    cb := TCheckBox(GetGroupControl(0));
    if Assigned(cb) then
      cb.SetFocus;
  end;
end;


{ TLineSpacingPicker }

procedure TLineSpacingPicker.CreateInitialize;
begin
  inherited;
  Items.Clear;
  Items.Add('1');
  Items.Add('1.5');
  Items.Add('2');
end;

end.
