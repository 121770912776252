unit uDashBoard;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, Vcl.Controls, Vcl.StdCtrls, WEBLib.StdCtrls,
  WEBLib.ExtCtrls, WEBLib.WebCtrls, Vcl.Forms, WEBLib.DropDown,
  WEBLib.REST, WEBLib.JSON, WEBLib.Country, WEBLib.Storage, WEBLib.Lists;

type
  TLocationTodaysSalesDetails = record
    TodaySales, TodayThirdPartySales, TodayThirdParty: double;
    TodayTrans, TodayPersonsSales, TodayPersons: integer;

    LastWeekSales, LastWeekThirdPartySales: double;
    LastWeekSameTimeSales, LastWeekSameTimeThirdPartySales: double;
    LastWeekTrans, LastWeekSameTimeTrans, LastWeekPersonsSales, LastWeekSameTimePersonsSales, LastWeekPersons, LastWeekSameTimePersons: integer;

    LastMonthSales, LastMonthThirdPartySales: double;
    LastMonthSameTimeSales, LastMonthSameTimeThirdPartySales: double;
    LastMonthTrans, LastMonthSameTimeTrans, LastMonthPersonsSales, LastMonthSameTimePersonsSales, LastMonthPersons, LastMonthSameTimePersons: integer;
  end;

  TLocationDailySalesDetails = record
    InHouseDataArray: array of integer;
    InHouseTransDataArray: array of integer;

    ThirdPartyDataArray: array of integer;
    ThirdPartyTransDataArray: array of integer;
  end;

  TfrmDashboard = class(TForm)
    WebEdit1: TEdit;
    WebButton1: TButton;
    TodaysSalesRequest: THttpRequest;
    //Request: TWebHttpRequest;
    Request2: THttpRequest;
    TodaySalesLabel: TLabel;
    TodayTransLabel: TLabel;
    TodayAGCLabel: TLabel;
    TodayThirdPartyPercLabelLabel: TLabel;
    LastWeekSalesDiffLabel: TLabel;
    LastWeekTransDiffLabel: TLabel;
    LastWeekAGCDiffLabel: TLabel;
    LastWeekThirdPartyPercDiffLabel: TLabel;
    WebDateTimePicker1: TDateTimePicker;
    LastWeekSameTimeAGCDiffLabel: TLabel;
    LastWeekSameTimeSalesDiffLabel: TLabel;
    LastWeekSameTimeThirdPartyPercDiffLabel: TLabel;
    LastWeekSameTimeTransDiffLabel: TLabel;
    LastMonthSalesDiffLabel: TLabel;
    LastMonthSameTimeSalesDiffLabel: TLabel;
    LastMonthAGCDiffLabel: TLabel;
    LastMonthSameTimeAGCDiffLabel: TLabel;
    LastMonthSameTimeThirdPartyPercDiffLabel: TLabel;
    LastMonthSameTimeTransDiffLabel: TLabel;
    LastMonthThirdPartyPercDiffLabel: TLabel;
    LastMonthTransDiffLabel: TLabel;
    WebDateTimePicker2: TDateTimePicker;
    TransUpdatedLabel: TLabel;
    AGCUpdatedLabel: TLabel;
    SalesUpdatedLabel: TLabel;
    WebTimer1: TTimer;
    Request3: THttpRequest;
    ChooseLocationRowDiv: THTMLDiv;
    chooselocationdropdown: TListControl;
    TodayPersonsLabel: TLabel;
    TodayAPPLabel: TLabel;
    LastMonthAPPDiffLabel: TLabel;
    LastWeekAPPDiffLabel: TLabel;
    LastMonthPersonsDiffLabel: TLabel;
    LastWeekPersonsDiffLabel: TLabel;
    LastWeekSameTimeAPPDiffLabel: TLabel;
    LastMonthSameTimeAPPDiffLabel: TLabel;
    LastWeekSameTimePersonsDiffLabel: TLabel;
    LastMonthSameTimePersonsDiffLabel: TLabel;
    procedure WebButton1Click(Sender: TObject);
    procedure WebFormCreate(Sender: TObject);
    procedure WebDateTimePicker1Change(Sender: TObject);
    procedure WebEdit1Enter(Sender: TObject);
    procedure WebEdit1Exit(Sender: TObject);
    procedure WebDateTimePicker1Enter(Sender: TObject);
    procedure WebDropDownControl1Click(Sender: TObject);
    procedure WebFormShow(Sender: TObject);
    procedure WebTimer1Timer(Sender: TObject);
    procedure chooselocationdropdownItemClick(Sender: TObject;
      AListItem: TListItem);
  private
    { Private declarations }
    //SalesData: TJSArray;
    ThirdPartyDataLoaded: TJSArray;
    LocationTodaysSalesDetails: array of TLocationTodaysSalesDetails;
    LocationDailySalesDetails: array of TLocationDailySalesDetails;

  public
    { Public declarations }
    DateLabelsArray: array of string;
    procedure ProcessTodaysSalesResult ( AResponse: TJSXMLHttpRequest);
    procedure ProcessDailySalesResult ( AResponse: TJSXMLHttpRequest);
    procedure UpdateTodaysSalesGUI;
    procedure UpdateDailySalesGUI;
    procedure LoadLocationDetails;
    [async] procedure LoadTodaysandDailySales; async;
    [async] procedure LoadTodaysSales; async;
    [async] procedure LoadDailySales; async;
  protected procedure LoadDFMValues; override; end;


var
  frmDashboard: TfrmDashboard;

implementation

{$R *.dfm}

uses
  DateUtils;

var
  URLBase: string;


procedure TfrmDashboard.UpdateTodaysSalesGUI;
var
  TodaySales, TodayAGC, TodayThirdPartySales, TodayThirdPartyPerc, TodayAPP: double;
  TodayTrans: integer;

  LastWeekSales, LastWeekAGC, LastWeekThirdPartySales, LastWeekThirdPartyPerc, LastWeekApp: double;
  LastWeekSalesDiff, LastWeekAGCDiff, LastWeekThirdPartyPercDiff, LastWeekAPPDiff: double;
  LastWeekSameTimeSales, LastWeekSameTimeAGC, LastWeekSameTimeThirdPartySales, LastWeekSameTimeThirdPartyPerc, LastWeekSameTimeAPP: double;
  LastWeekSameTimeSalesDiff, LastWeekSameTimeAGCDiff, LastWeekSameTimeThirdPartyPercDiff, LastWeekSameTimeAPPDiff: double;
  LastWeekTrans, LastWeekSameTimeTrans: integer;
  LastWeekTransDiff, LastWeekSameTimeTransDiff, LastWeekPersonsDiff, LastWeekSameTimePersonsDiff : double;

  LastMonthSales, LastMonthAGC, LastMonthThirdPartySales, LastMonthThirdPartyPerc, LastMonthApp: double;
  LastMonthSalesDiff, LastMonthAGCDiff, LastMonthThirdPartyPercDiff, LastMonthAPPDiff: double;
  LastMonthSameTimeSales, LastMonthSameTimeAGC, LastMonthSameTimeThirdPartySales, LastMonthSameTimeThirdPartyPerc, LastMonthSameTimeAPP: double;
  LastMonthSameTimeSalesDiff, LastMonthSameTimeAGCDiff, LastMonthSameTimeThirdPartyPercDiff, LastMonthSameTimeAPPDiff: double;
  LastMonthTrans, LastMonthSameTimeTrans: integer;
  LastMonthTransDiff, LastMonthSameTimeTransDiff, LastMonthPersonsDiff, LastMonthSameTimePersonsDiff : double;

  TodayPersonsSales, LastWeekPersonsSales, LastWeekSameTimePersonsSales, LastMonthPersonsSales, LastMonthSameTimePersonsSales: double;
  TodayPersons, LastWeekPersons, LastWeekSameTimePersons, LastMonthPersons, LastMonthSameTimePersons: integer;

  el: TJSElement;
  CurrentClass: string;
  i, j: integer;
begin
  TodayThirdPartySales:= 0;
  TodaySales:= 0;
  TodayTrans:= 0;
  TodayPersonsSales:= 0;
  TodayPersons:= 0;

  LastWeekThirdPartySales:= 0;
  LastWeekSales:= 0;
  LastWeekTrans:= 0;
  LastWeekPersonsSales:= 0;
  LastWeekPersons:= 0;

  LastWeekSameTimeThirdPartySales:= 0;
  LastWeekSameTimeSales:= 0;
  LastWeekSameTimeTrans:= 0;
  LastWeekSameTimePersonsSales:= 0;
  LastWeekSameTimePersons:= 0;

  LastMonthThirdPartySales:= 0;
  LastMonthSales:= 0;
  LastMonthTrans:= 0;
  LastMonthPersonsSales:= 0;
  LastMonthPersons:= 0;

  LastMonthSameTimeThirdPartySales:= 0;
  LastMonthSameTimeSales:= 0;
  LastMonthSameTimeTrans:= 0;
  LastMonthSameTimePersonsSales:= 0;
  LastMonthSameTimePersons:= 0;

  console.log('Length(LocationTodaysSalesDetails): '+IntToStr(Length(LocationTodaysSalesDetails)));
  for i := 0 to Length(LocationTodaysSalesDetails) - 1 do
  begin
    TodaySales:= TodaySales + LocationTodaysSalesDetails[i].TodaySales;
    TodayTrans:= TodayTrans + LocationTodaysSalesDetails[i].TodayTrans;
    TodayThirdPartySales:= TodayThirdPartySales + LocationTodaysSalesDetails[i].TodayThirdPartySales;
    TodayPersonsSales:= TodayPersonsSales + LocationTodaysSalesDetails[i].TodayPersonsSales;
    TodayPersons:= TodayPersons + LocationTodaysSalesDetails[i].TodayPersons;

    LastWeekSales:= LastWeekSales + LocationTodaysSalesDetails[i].LastWeekSales;
    LastWeekTrans:= LastWeekTrans + LocationTodaysSalesDetails[i].LastWeekTrans;
    LastWeekThirdPartySales:= LastWeekThirdPartySales + LocationTodaysSalesDetails[i].LastWeekThirdPartySales;
    LastWeekPersonsSales:= LastWeekPersonsSales + LocationTodaysSalesDetails[i].LastWeekPersonsSales;
    LastWeekPersons:= LastWeekPersons + LocationTodaysSalesDetails[i].LastWeekPersons;

    LastWeekSameTimeSales:= LastWeekSameTimeSales + LocationTodaysSalesDetails[i].LastWeekSameTimeSales;
    LastWeekSameTimeTrans:= LastWeekSameTimeTrans + LocationTodaysSalesDetails[i].LastWeekSameTimeTrans;
    LastWeekSameTimeThirdPartySales:= LastWeekSameTimeThirdPartySales + LocationTodaysSalesDetails[i].LastWeekSameTimeThirdPartySales;
    LastWeekSameTimePersonsSales:= LastWeekSameTimePersonsSales + LocationTodaysSalesDetails[i].LastWeekSameTimePersonsSales;
    LastWeekSameTimePersons:= LastWeekSameTimePersons + LocationTodaysSalesDetails[i].LastWeekSameTimePersons;

    LastMonthSales:= LastMonthSales + LocationTodaysSalesDetails[i].LastMonthSales;
    LastMonthTrans:= LastMonthTrans + LocationTodaysSalesDetails[i].LastMonthTrans;
    LastMonthThirdPartySales:= LastMonthThirdPartySales + LocationTodaysSalesDetails[i].LastMonthThirdPartySales;
    LastMonthPersonsSales:= LastMonthPersonsSales + LocationTodaysSalesDetails[i].LastMonthPersonsSales;
    LastMonthPersons:= LastMonthPersons + LocationTodaysSalesDetails[i].LastMonthPersons;

    LastMonthSameTimeSales:= LastMonthSameTimeSales + LocationTodaysSalesDetails[i].LastMonthSameTimeSales;
    LastMonthSameTimeTrans:= LastMonthSameTimeTrans + LocationTodaysSalesDetails[i].LastMonthSameTimeTrans;
    LastMonthSameTimeThirdPartySales:= LastMonthSameTimeThirdPartySales + LocationTodaysSalesDetails[i].LastMonthSameTimeThirdPartySales;
    LastMonthSameTimePersonsSales:= LastMonthSameTimePersonsSales + LocationTodaysSalesDetails[i].LastMonthSameTimePersonsSales;
    LastMonthSameTimePersons:= LastMonthSameTimePersons + LocationTodaysSalesDetails[i].LastMonthSameTimePersons;
  end;

  if TodayTrans = 0 then TodayAGC:= 0
  else TodayAGC:= TodaySales / TodayTrans;
  if TodaySales = 0 then TodayThirdPartyPerc:= 0
  else TodayThirdPartyPerc:= 100 * TodayThirdPartySales / TodaySales;
  if TodayPersons = 0 then TodayAPP:= 0
  else TodayAPP:= TodayPersonsSales / TodayPersons;

  if LastWeekTrans = 0 then LastWeekAGC:= 0
  else LastWeekAGC:= LastWeekSales / LastWeekTrans;
  if LastWeekSales = 0 then LastWeekThirdPartyPerc:= 0
  else LastWeekThirdPartyPerc:= 100 * LastWeekThirdPartySales / LastWeekSales;
  if LastWeekPersons = 0 then LastWeekAPP:= 0
  else LastWeekAPP:= LastWeekPersonsSales / LastWeekPersons;

  if LastWeekSales = 0 then
  begin
    if TodaySales = 0 then LastWeekSalesDiff:= 0
    else LastWeekSalesDiff:= 100;
  end else LastWeekSalesDiff:= 100 * (TodaySales - LastWeekSales) / LastWeekSales;
  if LastWeekTrans = 0 then
  begin
    if TodayTrans = 0 then LastWeekTransDiff:= 0
    else LastWeekTransDiff:= 100;
  end else LastWeekTransDiff:= 100 * (TodayTrans - LastWeekTrans) / LastWeekTrans;
  if LastWeekPersons = 0 then
  begin
    if TodayPersons = 0 then LastWeekPersonsDiff:= 0
    else LastWeekPersonsDiff:= 100;
  end else LastWeekPersonsDiff:= 100 * (TodayPersons - LastWeekPersons) / LastWeekPersons;
  LastWeekAGCDiff:= TodayAGC - LastWeekAGC;
  LastWeekThirdPartyPercDiff:= TodayThirdPartyPerc - LastWeekThirdPartyPerc;
  LastWeekAPPDiff:= TodayAPP - LastWeekAPP;

  if LastWeekSameTimeTrans = 0 then LastWeekSameTimeAGC:= 0
  else LastWeekSameTimeAGC:= LastWeekSameTimeSales / LastWeekSameTimeTrans;
  if LastWeekSameTimeSales = 0 then LastWeekSameTimeThirdPartyPerc:= 0
  else LastWeekSameTimeThirdPartyPerc:= 100 * LastWeekSameTimeThirdPartySales / LastWeekSameTimeSales;
  if LastWeekSameTimePersons = 0 then LastWeekSameTimeAPP:= 0
  else LastWeekSameTimeAPP:= LastWeekSameTimePersonsSales / LastWeekSameTimePersons;

  if LastWeekSameTimeSales = 0 then
  begin
    if TodaySales = 0 then LastWeekSameTimeSalesDiff:= 0
    else LastWeekSameTimeSalesDiff:= 100;
  end else LastWeekSameTimeSalesDiff:= 100 * (TodaySales - LastWeekSameTimeSales) / LastWeekSameTimeSales;
  if LastWeekSameTimeTrans = 0 then
  begin
    if TodayTrans = 0 then LastWeekSameTimeTransDiff:= 0
    else LastWeekSameTimeTransDiff:= 100;
  end else LastWeekSameTimeTransDiff:= 100 * (TodayTrans - LastWeekSameTimeTrans) / LastWeekSameTimeTrans;
  if LastWeekSameTimePersons = 0 then
  begin
    if TodayPersons = 0 then LastWeekSameTimePersonsDiff:= 0
    else LastWeekSameTimePersonsDiff:= 100;
  end else LastWeekSameTimePersonsDiff:= 100 * (TodayPersons - LastWeekSameTimePersons) / LastWeekSameTimePersons;
  LastWeekSameTimeAGCDiff:= TodayAGC - LastWeekSameTimeAGC;
  LastWeekSameTimeThirdPartyPercDiff:= TodayThirdPartyPerc - LastWeekSameTimeThirdPartyPerc;
  LastWeekSameTimeAPPDiff:= TodayAPP - LastWeekSameTimeAPP;

  if LastMonthTrans = 0 then LastMonthAGC:= 0
  else LastMonthAGC:= LastMonthSales / LastMonthTrans;
  if LastMonthSales = 0 then LastMonthThirdPartyPerc:= 0
  else LastMonthThirdPartyPerc:= 100 * LastMonthThirdPartySales / LastMonthSales;
  if LastMonthPersons = 0 then LastMonthAPP:= 0
  else LastMonthAPP:= LastMonthPersonsSales / LastMonthPersons;

  if LastMonthSales = 0 then
  begin
    if TodaySales = 0 then LastMonthSalesDiff:= 0
    else LastMonthSalesDiff:= 100;
  end else LastMonthSalesDiff:= 100 * (TodaySales - LastMonthSales) / LastMonthSales;
  if LastMonthTrans = 0 then
  begin
    if TodayTrans = 0 then LastMonthTransDiff:= 0
    else LastMonthTransDiff:= 100;
  end else LastMonthTransDiff:= 100 * (TodayTrans - LastMonthTrans) / LastMonthTrans;
  if LastMonthPersons = 0 then
  begin
    if TodayPersons = 0 then LastMonthPersonsDiff:= 0
    else LastMonthPersonsDiff:= 100;
  end else LastMonthPersonsDiff:= 100 * (TodayPersons - LastMonthPersons) / LastMonthPersons;
  LastMonthAGCDiff:= TodayAGC - LastMonthAGC;
  LastMonthThirdPartyPercDiff:= TodayThirdPartyPerc - LastMonthThirdPartyPerc;
  LastMonthAPPDiff:= TodayAPP - LastMonthAPP;

  if LastMonthSameTimeTrans = 0 then LastMonthSameTimeAGC:= 0
  else LastMonthSameTimeAGC:= LastMonthSameTimeSales / LastMonthSameTimeTrans;
  if LastMonthSameTimeSales = 0 then LastMonthSameTimeThirdPartyPerc:= 0
  else LastMonthSameTimeThirdPartyPerc:= 100 * LastMonthSameTimeThirdPartySales / LastMonthSameTimeSales;
  if LastMonthSameTimePersons = 0 then LastMonthSameTimeAPP:= 0
  else LastMonthSameTimeAPP:= LastMonthSameTimePersonsSales / LastMonthSameTimePersons;

  if LastMonthSameTimeSales = 0 then
  begin
    if TodaySales = 0 then LastMonthSameTimeSalesDiff:= 0
    else LastMonthSameTimeSalesDiff:= 100;
  end else LastMonthSameTimeSalesDiff:= 100 * (TodaySales - LastMonthSameTimeSales) / LastMonthSameTimeSales;
  if LastMonthSameTimeTrans = 0 then
  begin
    if TodayTrans = 0 then LastMonthSameTimeTransDiff:= 0
    else LastMonthSameTimeTransDiff:= 100;
  end else LastMonthSameTimeTransDiff:= 100 * (TodayTrans - LastMonthSameTimeTrans) / LastMonthSameTimeTrans;
  if LastMonthSameTimePersons = 0 then
  begin
    if TodayPersons = 0 then LastMonthSameTimePersonsDiff:= 0
    else LastMonthSameTimePersonsDiff:= 100;
  end else LastMonthSameTimePersonsDiff:= 100 * (TodayPersons - LastMonthSameTimePersons) / LastMonthSameTimePersons;
  LastMonthSameTimeAGCDiff:= TodayAGC - LastMonthSameTimeAGC;
  LastMonthSameTimeThirdPartyPercDiff:= TodayThirdPartyPerc - LastMonthSameTimeThirdPartyPerc;
  LastMonthSameTimeAPPDiff:= TodayAPP - LastMonthSameTimeAPP;
  //***************************
  asm
    LoadCountUpDetails('TodaySalesLabel', TodaySales, 0);
    LoadCountUpDetails('TodayTransLabel', TodayTrans, 0);
    LoadCountUpDetails('TodayAGCLabel', TodayAGC, 1);
    LoadCountUpDetails('TodayThirdPartyPercLabel', TodayThirdPartyPerc, 0);
    LoadCountUpDetails('TodayPersonsLabel', TodayPersons, 0);
    LoadCountUpDetails('TodayAPPLabel', TodayAPP, 1);

  end;
  //***************************

  el := document.getElementById('LastWeekSalesDiff');
  CurrentClass:= el['class'];
  CurrentClass:= CurrentClass.Replace('text-success', '');
  CurrentClass:= CurrentClass.Replace('text-danger', '');
  if LastWeekSalesDiff >= 0 then CurrentClass:= CurrentClass+' text-success'
  else CurrentClass:= CurrentClass+' text-danger';
  el['class'] := CurrentClass;
  if (LastWeekSalesDiff <> 0) and (LastWeekSalesDiff > -1) and (LastWeekSalesDiff < 1) then LastWeekSalesDiffLabel.Caption:= FormatFloat('0.0%', LastWeekSalesDiff)+' '
  else LastWeekSalesDiffLabel.Caption:= FormatFloat('0%', LastWeekSalesDiff)+' ';

  el := document.getElementById('LastWeekTransDiff');
  CurrentClass:= el['class'];
  CurrentClass:= CurrentClass.Replace('text-success', '');
  CurrentClass:= CurrentClass.Replace('text-danger', '');
  if LastWeekTransDiff >= 0 then CurrentClass:= CurrentClass+' text-success'
  else CurrentClass:= CurrentClass+' text-danger';
  el['class'] := CurrentClass;
  if (LastWeekTransDiff <> 0) and (LastWeekTransDiff > -1) and (LastWeekTransDiff < 1) then LastWeekTransDiffLabel.Caption:= FormatFloat('0.0%', LastWeekTransDiff)+' '
  else LastWeekTransDiffLabel.Caption:= FormatFloat('0%', LastWeekTransDiff)+' ';

  el := document.getElementById('LastWeekAGCDiff');
  CurrentClass:= el['class'];
  CurrentClass:= CurrentClass.Replace('text-success', '');
  CurrentClass:= CurrentClass.Replace('text-danger', '');
  if LastWeekAGCDiff >= 0 then CurrentClass:= CurrentClass+' text-success'
  else CurrentClass:= CurrentClass+' text-danger';
  el['class'] := CurrentClass;
  LastWeekAGCDiffLabel.Caption:= FormatFloat('€0.0', LastWeekAGCDiff)+' ';

  el := document.getElementById('LastWeekAPPDiff');
  CurrentClass:= el['class'];
  CurrentClass:= CurrentClass.Replace('text-success', '');
  CurrentClass:= CurrentClass.Replace('text-danger', '');
  if LastWeekAPPDiff >= 0 then CurrentClass:= CurrentClass+' text-success'
  else CurrentClass:= CurrentClass+' text-danger';
  el['class'] := CurrentClass;
  LastWeekAPPDiffLabel.Caption:= FormatFloat('€0.0', LastWeekAPPDiff)+' ';

  el := document.getElementById('LastWeekPersonsDiff');
  CurrentClass:= el['class'];
  CurrentClass:= CurrentClass.Replace('text-success', '');
  CurrentClass:= CurrentClass.Replace('text-danger', '');
  if LastWeekPersonsDiff >= 0 then CurrentClass:= CurrentClass+' text-success'
  else CurrentClass:= CurrentClass+' text-danger';
  el['class'] := CurrentClass;
  if (LastWeekPersonsDiff <> 0) and (LastWeekPersonsDiff > -1) and (LastWeekPersonsDiff < 1) then LastWeekPersonsDiffLabel.Caption:= FormatFloat('0.0%', LastWeekPersonsDiff)+' '
  else LastWeekPersonsDiffLabel.Caption:= FormatFloat('0%', LastWeekPersonsDiff)+' ';

  el := document.getElementById('LastWeekThirdPartyPercDiff');
  CurrentClass:= el['class'];
  CurrentClass:= CurrentClass.Replace('text-success', '');
  CurrentClass:= CurrentClass.Replace('text-danger', '');
  if LastWeekThirdPartyPercDiff >= 0 then CurrentClass:= CurrentClass+' text-success'
  else CurrentClass:= CurrentClass+' text-danger';
  el['class'] := CurrentClass;
  if (LastWeekThirdPartyPercDiff <> 0) and (LastWeekThirdPartyPercDiff > -1) and (LastWeekThirdPartyPercDiff < 1) then LastWeekThirdPartyPercDiffLabel.Caption:= FormatFloat('0.0%', LastWeekThirdPartyPercDiff)+' '
  else LastWeekThirdPartyPercDiffLabel.Caption:= FormatFloat('0%', LastWeekThirdPartyPercDiff)+' ';


  el := document.getElementById('LastWeekSameTimeSalesDiff');
  CurrentClass:= el['class'];
  CurrentClass:= CurrentClass.Replace('text-success', '');
  CurrentClass:= CurrentClass.Replace('text-danger', '');
  if LastWeekSameTimeSalesDiff >= 0 then CurrentClass:= CurrentClass+' text-success'
  else CurrentClass:= CurrentClass+' text-danger';
  el['class'] := CurrentClass;
  if (LastWeekSameTimeSalesDiff <> 0) and (LastWeekSameTimeSalesDiff > -1) and (LastWeekSameTimeSalesDiff < 1) then LastWeekSameTimeSalesDiffLabel.Caption:= FormatFloat('0.0%', LastWeekSameTimeSalesDiff)+' '
  else LastWeekSameTimeSalesDiffLabel.Caption:= FormatFloat('0%', LastWeekSameTimeSalesDiff)+' ';

  el := document.getElementById('LastWeekSameTimeTransDiff');
  CurrentClass:= el['class'];
  CurrentClass:= CurrentClass.Replace('text-success', '');
  CurrentClass:= CurrentClass.Replace('text-danger', '');
  if LastWeekSameTimeTransDiff >= 0 then CurrentClass:= CurrentClass+' text-success'
  else CurrentClass:= CurrentClass+' text-danger';
  el['class'] := CurrentClass;
  if (LastWeekSameTimeTransDiff <> 0) and (LastWeekSameTimeTransDiff > -1) and (LastWeekSameTimeTransDiff < 1) then LastWeekSameTimeTransDiffLabel.Caption:= FormatFloat('0.0%', LastWeekSameTimeTransDiff)+' '
  else LastWeekSameTimeTransDiffLabel.Caption:= FormatFloat('0%', LastWeekSameTimeTransDiff)+' ';

  el := document.getElementById('LastWeekSameTimeAGCDiff');
  CurrentClass:= el['class'];
  CurrentClass:= CurrentClass.Replace('text-success', '');
  CurrentClass:= CurrentClass.Replace('text-danger', '');
  if LastWeekSameTimeAGCDiff >= 0 then CurrentClass:= CurrentClass+' text-success'
  else CurrentClass:= CurrentClass+' text-danger';
  el['class'] := CurrentClass;
  LastWeekSameTimeAGCDiffLabel.Caption:= FormatFloat('€0.0', LastWeekSameTimeAGCDiff)+' ';

  el := document.getElementById('LastWeekSameTimeAPPDiff');
  CurrentClass:= el['class'];
  CurrentClass:= CurrentClass.Replace('text-success', '');
  CurrentClass:= CurrentClass.Replace('text-danger', '');
  if LastWeekSameTimeAPPDiff >= 0 then CurrentClass:= CurrentClass+' text-success'
  else CurrentClass:= CurrentClass+' text-danger';
  el['class'] := CurrentClass;
  LastWeekSameTimeAPPDiffLabel.Caption:= FormatFloat('€0.0', LastWeekSameTimeAPPDiff)+' ';

  el := document.getElementById('LastWeekSameTimePersonsDiff');
  CurrentClass:= el['class'];
  CurrentClass:= CurrentClass.Replace('text-success', '');
  CurrentClass:= CurrentClass.Replace('text-danger', '');
  if LastWeekSameTimePersonsDiff >= 0 then CurrentClass:= CurrentClass+' text-success'
  else CurrentClass:= CurrentClass+' text-danger';
  el['class'] := CurrentClass;
  if (LastWeekSameTimePersonsDiff <> 0) and (LastWeekSameTimePersonsDiff > -1) and (LastWeekSameTimePersonsDiff < 1) then LastWeekSameTimePersonsDiffLabel.Caption:= FormatFloat('0.0%', LastWeekSameTimePersonsDiff)+' '
  else LastWeekSameTimePersonsDiffLabel.Caption:= FormatFloat('0%', LastWeekSameTimePersonsDiff)+' ';

  el := document.getElementById('LastWeekSameTimeThirdPartyPercDiff');
  CurrentClass:= el['class'];
  CurrentClass:= CurrentClass.Replace('text-success', '');
  CurrentClass:= CurrentClass.Replace('text-danger', '');
  if LastWeekSameTimeThirdPartyPercDiff >= 0 then CurrentClass:= CurrentClass+' text-success'
  else CurrentClass:= CurrentClass+' text-danger';
  el['class'] := CurrentClass;
  if (LastWeekSameTimeThirdPartyPercDiff <> 0) and (LastWeekSameTimeThirdPartyPercDiff > -1) and (LastWeekSameTimeThirdPartyPercDiff < 1) then LastWeekSameTimeThirdPartyPercDiffLabel.Caption:= FormatFloat('0.0%', LastWeekSameTimeThirdPartyPercDiff)+' '
  else LastWeekSameTimeThirdPartyPercDiffLabel.Caption:= FormatFloat('0%', LastWeekSameTimeThirdPartyPercDiff)+' ';

  //**************************

  el := document.getElementById('LastMonthSalesDiff');
  CurrentClass:= el['class'];
  CurrentClass:= CurrentClass.Replace('text-success', '');
  CurrentClass:= CurrentClass.Replace('text-danger', '');
  if LastMonthSalesDiff >= 0 then CurrentClass:= CurrentClass+' text-success'
  else CurrentClass:= CurrentClass+' text-danger';
  el['class'] := CurrentClass;
  if (LastMonthSalesDiff <> 0) and (LastMonthSalesDiff > -1) and (LastMonthSalesDiff < 1) then LastMonthSalesDiffLabel.Caption:= FormatFloat('0.0%', LastMonthSalesDiff)+' '
  else LastMonthSalesDiffLabel.Caption:= FormatFloat('0%', LastMonthSalesDiff)+' ';

  el := document.getElementById('LastMonthTransDiff');
  CurrentClass:= el['class'];
  CurrentClass:= CurrentClass.Replace('text-success', '');
  CurrentClass:= CurrentClass.Replace('text-danger', '');
  if LastMonthTransDiff >= 0 then CurrentClass:= CurrentClass+' text-success'
  else CurrentClass:= CurrentClass+' text-danger';
  el['class'] := CurrentClass;
  if (LastMonthTransDiff <> 0) and (LastMonthTransDiff > -1) and (LastMonthTransDiff < 1) then LastMonthTransDiffLabel.Caption:= FormatFloat('0.0%', LastMonthTransDiff)+' '
  else LastMonthTransDiffLabel.Caption:= FormatFloat('0%', LastMonthTransDiff)+' ';

  el := document.getElementById('LastMonthAGCDiff');
  CurrentClass:= el['class'];
  CurrentClass:= CurrentClass.Replace('text-success', '');
  CurrentClass:= CurrentClass.Replace('text-danger', '');
  if LastMonthAGCDiff >= 0 then CurrentClass:= CurrentClass+' text-success'
  else CurrentClass:= CurrentClass+' text-danger';
  el['class'] := CurrentClass;
  LastMonthAGCDiffLabel.Caption:= FormatFloat('€0.0', LastMonthAGCDiff)+' ';

  el := document.getElementById('LastMonthAPPDiff');
  CurrentClass:= el['class'];
  CurrentClass:= CurrentClass.Replace('text-success', '');
  CurrentClass:= CurrentClass.Replace('text-danger', '');
  if LastMonthAPPDiff >= 0 then CurrentClass:= CurrentClass+' text-success'
  else CurrentClass:= CurrentClass+' text-danger';
  el['class'] := CurrentClass;
  LastMonthAPPDiffLabel.Caption:= FormatFloat('€0.0', LastMonthAPPDiff)+' ';

  el := document.getElementById('LastMonthPersonsDiff');
  CurrentClass:= el['class'];
  CurrentClass:= CurrentClass.Replace('text-success', '');
  CurrentClass:= CurrentClass.Replace('text-danger', '');
  if LastMonthPersonsDiff >= 0 then CurrentClass:= CurrentClass+' text-success'
  else CurrentClass:= CurrentClass+' text-danger';
  el['class'] := CurrentClass;
  if (LastMonthPersonsDiff <> 0) and (LastMonthPersonsDiff > -1) and (LastMonthPersonsDiff < 1) then LastMonthPersonsDiffLabel.Caption:= FormatFloat('0.0%', LastMonthPersonsDiff)+' '
  else LastMonthPersonsDiffLabel.Caption:= FormatFloat('0%', LastMonthPersonsDiff)+' ';

  el := document.getElementById('LastMonthThirdPartyPercDiff');
  CurrentClass:= el['class'];
  CurrentClass:= CurrentClass.Replace('text-success', '');
  CurrentClass:= CurrentClass.Replace('text-danger', '');
  if LastMonthThirdPartyPercDiff >= 0 then CurrentClass:= CurrentClass+' text-success'
  else CurrentClass:= CurrentClass+' text-danger';
  el['class'] := CurrentClass;
  if (LastMonthThirdPartyPercDiff <> 0) and (LastMonthThirdPartyPercDiff > -1) and (LastMonthThirdPartyPercDiff < 1) then LastMonthThirdPartyPercDiffLabel.Caption:= FormatFloat('0.0%', LastMonthThirdPartyPercDiff)+' '
  else LastMonthThirdPartyPercDiffLabel.Caption:= FormatFloat('0%', LastMonthThirdPartyPercDiff)+' ';


  el := document.getElementById('LastMonthSameTimeSalesDiff');
  CurrentClass:= el['class'];
  CurrentClass:= CurrentClass.Replace('text-success', '');
  CurrentClass:= CurrentClass.Replace('text-danger', '');
  if LastMonthSameTimeSalesDiff >= 0 then CurrentClass:= CurrentClass+' text-success'
  else CurrentClass:= CurrentClass+' text-danger';
  el['class'] := CurrentClass;
  if (LastMonthSameTimeSalesDiff <> 0) and (LastMonthSameTimeSalesDiff > -1) and (LastMonthSameTimeSalesDiff < 1) then LastMonthSameTimeSalesDiffLabel.Caption:= FormatFloat('0.0%', LastMonthSameTimeSalesDiff)+' '
  else LastMonthSameTimeSalesDiffLabel.Caption:= FormatFloat('0%', LastMonthSameTimeSalesDiff)+' ';

  el := document.getElementById('LastMonthSameTimeTransDiff');
  CurrentClass:= el['class'];
  CurrentClass:= CurrentClass.Replace('text-success', '');
  CurrentClass:= CurrentClass.Replace('text-danger', '');
  if LastMonthSameTimeTransDiff >= 0 then CurrentClass:= CurrentClass+' text-success'
  else CurrentClass:= CurrentClass+' text-danger';
  el['class'] := CurrentClass;
  if (LastMonthSameTimeTransDiff <> 0) and (LastMonthSameTimeTransDiff > -1) and (LastMonthSameTimeTransDiff < 1) then LastMonthSameTimeTransDiffLabel.Caption:= FormatFloat('0.0%', LastMonthSameTimeTransDiff)+' '
  else LastMonthSameTimeTransDiffLabel.Caption:= FormatFloat('0%', LastMonthSameTimeTransDiff)+' ';

  el := document.getElementById('LastMonthSameTimeAGCDiff');
  CurrentClass:= el['class'];
  CurrentClass:= CurrentClass.Replace('text-success', '');
  CurrentClass:= CurrentClass.Replace('text-danger', '');
  if LastMonthSameTimeAGCDiff >= 0 then CurrentClass:= CurrentClass+' text-success'
  else CurrentClass:= CurrentClass+' text-danger';
  el['class'] := CurrentClass;
  LastMonthSameTimeAGCDiffLabel.Caption:= FormatFloat('€0.0', LastMonthSameTimeAGCDiff)+' ';

  el := document.getElementById('LastMonthSameTimeAPPDiff');
  CurrentClass:= el['class'];
  CurrentClass:= CurrentClass.Replace('text-success', '');
  CurrentClass:= CurrentClass.Replace('text-danger', '');
  if LastMonthSameTimeAPPDiff >= 0 then CurrentClass:= CurrentClass+' text-success'
  else CurrentClass:= CurrentClass+' text-danger';
  el['class'] := CurrentClass;
  LastMonthSameTimeAPPDiffLabel.Caption:= FormatFloat('€0.0', LastMonthSameTimeAPPDiff)+' ';

  el := document.getElementById('LastMonthSameTimePersonsDiff');
  CurrentClass:= el['class'];
  CurrentClass:= CurrentClass.Replace('text-success', '');
  CurrentClass:= CurrentClass.Replace('text-danger', '');
  if LastMonthSameTimePersonsDiff >= 0 then CurrentClass:= CurrentClass+' text-success'
  else CurrentClass:= CurrentClass+' text-danger';
  el['class'] := CurrentClass;
  if (LastMonthSameTimePersonsDiff <> 0) and (LastMonthSameTimePersonsDiff > -1) and (LastMonthSameTimePersonsDiff < 1) then LastMonthSameTimePersonsDiffLabel.Caption:= FormatFloat('0.0%', LastMonthSameTimePersonsDiff)+' '
  else LastMonthSameTimePersonsDiffLabel.Caption:= FormatFloat('0%', LastMonthSameTimePersonsDiff)+' ';

  el := document.getElementById('LastMonthSameTimeThirdPartyPercDiff');
  CurrentClass:= el['class'];
  CurrentClass:= CurrentClass.Replace('text-success', '');
  CurrentClass:= CurrentClass.Replace('text-danger', '');
  if LastMonthSameTimeThirdPartyPercDiff >= 0 then CurrentClass:= CurrentClass+' text-success'
  else CurrentClass:= CurrentClass+' text-danger';
  el['class'] := CurrentClass;
  if (LastMonthSameTimeThirdPartyPercDiff <> 0) and (LastMonthSameTimeThirdPartyPercDiff > -1) and (LastMonthSameTimeThirdPartyPercDiff < 1) then LastMonthSameTimeThirdPartyPercDiffLabel.Caption:= FormatFloat('0.0%', LastMonthSameTimeThirdPartyPercDiff)+' '
  else LastMonthSameTimeThirdPartyPercDiffLabel.Caption:= FormatFloat('0%', LastMonthSameTimeThirdPartyPercDiff)+' ';
end;

procedure TfrmDashboard.UpdateDailySalesGUI;
var
  InHouseDataArray: array of integer;
  InHouseTransDataArray: array of integer;
  InHouseAGCDataArray: array of double;

  ThirdPartyDataArray: array of integer;
  ThirdPartyTransDataArray: array of integer;
  ThirdPartyAGCDataArray: array of double;

  TotalAGCDataArray: array of double;

  el: TJSElement;
  CurrentClass: string;
  i, j: integer;
begin
  SetLength(InHouseDataArray, 0);
  SetLength(ThirdPartyDataArray, 0);

  SetLength(InHouseDataArray, Length(DateLabelsArray));
  SetLength(InHouseTransDataArray, Length(DateLabelsArray));
  SetLength(ThirdPartyDataArray, Length(DateLabelsArray));
  SetLength(ThirdPartyTransDataArray, Length(DateLabelsArray));

  SetLength(InHouseAGCDataArray, Length(DateLabelsArray));
  SetLength(ThirdPartyAGCDataArray, Length(DateLabelsArray));
  SetLength(TotalAGCDataArray, Length(DateLabelsArray));

  //Console.log('DateLabelsArray:'+IntToStr(Length(DateLabelsArray)));
  for i := 0 to Length(DateLabelsArray) - 1 do
  begin
    InHouseDataArray[i]:= 0;

    InHouseTransDataArray[i]:= 0;
    ThirdPartyDataArray[i]:= 0;
    ThirdPartyTransDataArray[i]:= 0;

  end;
  //Console.log(InHouseDataArray);
  {for j := 0 to Length(DateLabelsArray) - 1 do
  begin
    Console.log('InHouseDataArray['+IntToStr(j)+']: '+IntToStr(InHouseDataArray[j]));
  end;}

  for i := 0 to Length(LocationDailySalesDetails) - 1 do
  begin
    for j := 0 to Length(DateLabelsArray) - 1 do
    begin
      //Console.log(LocationDetails[i].InHouseDataArray[j]);
      InHouseDataArray[j]:= InHouseDataArray[j] + LocationDailySalesDetails[i].InHouseDataArray[j];
      InHouseTransDataArray[j]:= InHouseTransDataArray[j] + LocationDailySalesDetails[i].InHouseTransDataArray[j];
      ThirdPartyDataArray[j]:= ThirdPartyDataArray[j] + LocationDailySalesDetails[i].ThirdPartyDataArray[j];
      ThirdPartyTransDataArray[j]:= ThirdPartyTransDataArray[j] + LocationDailySalesDetails[i].ThirdPartyTransDataArray[j];
    end;
    //Console.log('===');
  end;


  for i := 0 to Length(DateLabelsArray) - 1 do
  begin
    if InHouseTransDataArray[i] = 0 then InHouseAGCDataArray[i]:= 0
    else InHouseAGCDataArray[i]:= InHouseDataArray[i] / InHouseTransDataArray[i];

    if ThirdPartyTransDataArray[i] = 0 then ThirdPartyAGCDataArray[j]:= 0
    else ThirdPartyAGCDataArray[i]:= ThirdPartyDataArray[i] / ThirdPartyTransDataArray[i];

    if (InHouseTransDataArray[i] + ThirdPartyTransDataArray[i]) = 0 then TotalAGCDataArray[i]:= 0
    else TotalAGCDataArray[i]:= (InHouseDataArray[i] + ThirdPartyDataArray[i])/ (InHouseTransDataArray[i] + ThirdPartyTransDataArray[i]);
  end;
  //Console.log(InHouseDataArray);
  asm
    LoadGraphs(this.DateLabelsArray, InHouseDataArray, InHouseTransDataArray, InHouseAGCDataArray, ThirdPartyDataArray, ThirdPartyTransDataArray, ThirdPartyAGCDataArray, TotalAGCDataArray);
  end;
end;

procedure TfrmDashboard.ProcessTodaysSalesResult ( AResponse: TJSXMLHttpRequest);
var
  i, j: integer;
  TodaySalesArray, TodaySameTimeSalesArray: TJSArray;

  CurrentLocationDetailsArrayNumber: integer;
  CurrentDateLabel: string;
begin
  SetLength(LocationTodaysSalesDetails, Length(LocationTodaysSalesDetails) + 1);
  CurrentLocationDetailsArrayNumber:= Length(LocationTodaysSalesDetails) - 1;

  //console.log(AResponse.response);

  TodaySalesArray:= TJSArray(TJSObject(Aresponse.response)['TodaySales']);
  TodaySameTimeSalesArray:= TJSArray(TJSObject(Aresponse.response)['TodaySalesSameTime']);

  with LocationTodaysSalesDetails[CurrentLocationDetailsArrayNumber] do begin
    if TodaySalesArray.Length = 0 then
    begin

      TodayThirdPartySales:= 0;
      TodaySales:= 0;
      TodayTrans:= 0;
      TodayPersonsSales:= 0;
      TodayPersons:= 0;

      LastWeekThirdPartySales:= 0;
      LastWeekSales:= 0;
      LastWeekTrans:= 0;
      LastWeekPersonsSales:= 0;
      LastWeekPersons:= 0;

      LastWeekSameTimeThirdPartySales:= 0;
      LastWeekSameTimeSales:= 0;
      LastWeekSameTimeTrans:= 0;
      LastWeekSameTimePersonsSales:= 0;
      LastWeekSameTimePersons:= 0;

      LastMonthThirdPartySales:= 0;
      LastMonthSales:= 0;
      LastMonthTrans:= 0;
      LastMonthPersonsSales:= 0;
      LastMonthPersons:= 0;

      LastMonthSameTimeThirdPartySales:= 0;
      LastMonthSameTimeSales:= 0;
      LastMonthSameTimeTrans:= 0;
      LastMonthSameTimePersonsSales:= 0;
      LastMonthSameTimePersons:= 0;

    end else
    begin

      if string(TJSObject(TodaySalesArray.Elements[0])['TODAYTHIRDPARTYTOTAL']) = 'null' then TodayThirdPartySales:= 0
      else TodayThirdPartySales:= double(TJSObject(TodaySalesArray.Elements[0])['TODAYTHIRDPARTYTOTAL']);
      if string(TJSObject(TodaySalesArray.Elements[0])['TODAYINHOUSENETTOTAL']) = 'null' then TodaySales:= TodayThirdPartySales
      else TodaySales:= double(TJSObject(TodaySalesArray.Elements[0])['TODAYINHOUSENETTOTAL']) + TodayThirdPartySales;
      if string(TJSObject(TodaySalesArray.Elements[0])['TODAYINHOUSETRANSTOTAL']) = 'null' then TodayTrans:= 0
      else TodayTrans:= integer(TJSObject(TodaySalesArray.Elements[0])['TODAYINHOUSETRANSTOTAL']);
      if string(TJSObject(TodaySalesArray.Elements[0])['TODAYTHIRDPARTYTRANSTOTAL']) <> 'null' then TodayTrans:= TodayTrans + integer(TJSObject(TodaySalesArray.Elements[0])['TODAYTHIRDPARTYTRANSTOTAL']);
      if string(TJSObject(TodaySalesArray.Elements[0])['TODAYPERSONSSALESTOTAL']) = 'null' then TodayPersonsSales:= 0
      else TodayPersonsSales:= integer(TJSObject(TodaySalesArray.Elements[0])['TODAYPERSONSSALESTOTAL']);
      if string(TJSObject(TodaySalesArray.Elements[0])['TODAYPERSONSTOTAL']) = 'null' then TodayPersons:= 0
      else TodayPersons:= integer(TJSObject(TodaySalesArray.Elements[0])['TODAYPERSONSTOTAL']);

      //console.log(string(TJSObject(TodaySalesArray.Elements[0])['LASTWEEKINHOUSENETTOTAL']));
      if string(TJSObject(TodaySalesArray.Elements[0])['LASTWEEKTHIRDPARTYTOTAL']) = 'null' then LastWeekThirdPartySales:= 0
      else LastWeekThirdPartySales:= double(TJSObject(TodaySalesArray.Elements[0])['LASTWEEKTHIRDPARTYTOTAL']);
      if string(TJSObject(TodaySalesArray.Elements[0])['LASTWEEKINHOUSENETTOTAL']) = 'null' then LastWeekSales:= LastWeekThirdPartySales
      else LastWeekSales:= double(TJSObject(TodaySalesArray.Elements[0])['LASTWEEKINHOUSENETTOTAL']) + LastWeekThirdPartySales;
      if string(TJSObject(TodaySalesArray.Elements[0])['LASTWEEKINHOUSETRANSTOTAL']) = 'null' then LastWeekTrans:= 0
      else LastWeekTrans:= integer(TJSObject(TodaySalesArray.Elements[0])['LASTWEEKINHOUSETRANSTOTAL']);
      if string(TJSObject(TodaySalesArray.Elements[0])['LASTWEEKTHIRDPARTYTRANSTOTAL']) <> 'null' then LastWeekTrans:= LastWeekTrans + integer(TJSObject(TodaySalesArray.Elements[0])['LASTWEEKTHIRDPARTYTRANSTOTAL']);
      if string(TJSObject(TodaySalesArray.Elements[0])['LASTWEEKPERSONSSALESTOTAL']) = 'null' then LastWeekPersonsSales:= 0
      else LastWeekPersonsSales:= integer(TJSObject(TodaySalesArray.Elements[0])['LASTWEEKPERSONSSALESTOTAL']);
      if string(TJSObject(TodaySalesArray.Elements[0])['LASTWEEKPERSONSTOTAL']) = 'null' then LastWeekPersons:= 0
      else LastWeekPersons:= integer(TJSObject(TodaySalesArray.Elements[0])['LASTWEEKPERSONSTOTAL']);

      if string(TJSObject(TodaySameTimeSalesArray.Elements[0])['LASTWEEKTHIRDPARTYTOTAL']) = 'null' then LastWeekSameTimeThirdPartySales:= 0
      else LastWeekSameTimeThirdPartySales:= double(TJSObject(TodaySameTimeSalesArray.Elements[0])['LASTWEEKTHIRDPARTYTOTAL']);
      if string(TJSObject(TodaySameTimeSalesArray.Elements[0])['LASTWEEKINHOUSENETTOTAL']) = 'null' then LastWeekSameTimeSales:= LastWeekSameTimeThirdPartySales
      else LastWeekSameTimeSales:= double(TJSObject(TodaySameTimeSalesArray.Elements[0])['LASTWEEKINHOUSENETTOTAL']) + LastWeekSameTimeThirdPartySales;
      if string(TJSObject(TodaySameTimeSalesArray.Elements[0])['LASTWEEKINHOUSETRANSTOTAL']) = 'null' then LastWeekSameTimeTrans:= 0
      else LastWeekSameTimeTrans:= integer(TJSObject(TodaySameTimeSalesArray.Elements[0])['LASTWEEKINHOUSETRANSTOTAL']);
      if string(TJSObject(TodaySameTimeSalesArray.Elements[0])['LASTWEEKTHIRDPARTYTRANSTOTAL']) <> 'null' then LastWeekSameTimeTrans:= LastWeekSameTimeTrans + integer(TJSObject(TodaySameTimeSalesArray.Elements[0])['LASTWEEKTHIRDPARTYTRANSTOTAL']);
      if string(TJSObject(TodaySameTimeSalesArray.Elements[0])['LASTWEEKPERSONSSALESTOTAL']) = 'null' then LastWeekSameTimePersonsSales:= 0
      else LastWeekSameTimePersonsSales:= integer(TJSObject(TodaySameTimeSalesArray.Elements[0])['LASTWEEKPERSONSSALESTOTAL']);
      if string(TJSObject(TodaySameTimeSalesArray.Elements[0])['LASTWEEKPERSONSTOTAL']) = 'null' then LastWeekSameTimePersons:= 0
      else LastWeekSameTimePersons:= integer(TJSObject(TodaySameTimeSalesArray.Elements[0])['LASTWEEKPERSONSTOTAL']);
      //***************************

      //console.log(string(TJSObject(TodaySalesArray.Elements[0])['LASTMONTHINHOUSENETTOTAL']));
      if string(TJSObject(TodaySalesArray.Elements[0])['LASTMONTHTHIRDPARTYTOTAL']) = 'null' then LastMonthThirdPartySales:= 0
      else LastMonthThirdPartySales:= double(TJSObject(TodaySalesArray.Elements[0])['LASTMONTHTHIRDPARTYTOTAL']);
      if string(TJSObject(TodaySalesArray.Elements[0])['LASTMONTHINHOUSENETTOTAL']) = 'null' then LastMonthSales:= LastMonthThirdPartySales
      else LastMonthSales:= double(TJSObject(TodaySalesArray.Elements[0])['LASTMONTHINHOUSENETTOTAL']) + LastMonthThirdPartySales;
      if string(TJSObject(TodaySalesArray.Elements[0])['LASTMONTHINHOUSETRANSTOTAL']) = 'null' then LastMonthTrans:= 0
      else LastMonthTrans:= integer(TJSObject(TodaySalesArray.Elements[0])['LASTMONTHINHOUSETRANSTOTAL']);
      if string(TJSObject(TodaySalesArray.Elements[0])['LASTMONTHTHIRDPARTYTRANSTOTAL']) <> 'null' then LastMonthTrans:= LastMonthTrans + integer(TJSObject(TodaySalesArray.Elements[0])['LASTMONTHTHIRDPARTYTRANSTOTAL']);
      if string(TJSObject(TodaySalesArray.Elements[0])['LASTMONTHPERSONSSALESTOTAL']) = 'null' then LastMonthPersonsSales:= 0
      else LastMonthPersonsSales:= integer(TJSObject(TodaySalesArray.Elements[0])['LASTMONTHPERSONSSALESTOTAL']);
      if string(TJSObject(TodaySalesArray.Elements[0])['LASTMONTHPERSONSTOTAL']) = 'null' then LastMonthPersons:= 0
      else LastMonthPersons:= integer(TJSObject(TodaySalesArray.Elements[0])['LASTMONTHPERSONSTOTAL']);

      if string(TJSObject(TodaySameTimeSalesArray.Elements[0])['LASTMONTHTHIRDPARTYTOTAL']) = 'null' then LastMonthSameTimeThirdPartySales:= 0
      else LastMonthSameTimeThirdPartySales:= double(TJSObject(TodaySameTimeSalesArray.Elements[0])['LASTMONTHTHIRDPARTYTOTAL']);
      if string(TJSObject(TodaySameTimeSalesArray.Elements[0])['LASTMONTHINHOUSENETTOTAL']) = 'null' then LastMonthSameTimeSales:= LastMonthSameTimeThirdPartySales
      else LastMonthSameTimeSales:= double(TJSObject(TodaySameTimeSalesArray.Elements[0])['LASTMONTHINHOUSENETTOTAL']) + LastMonthSameTimeThirdPartySales;
      if string(TJSObject(TodaySameTimeSalesArray.Elements[0])['LASTMONTHINHOUSETRANSTOTAL']) = 'null' then LastMonthSameTimeTrans:= 0
      else LastMonthSameTimeTrans:= integer(TJSObject(TodaySameTimeSalesArray.Elements[0])['LASTMONTHINHOUSETRANSTOTAL']);
      if string(TJSObject(TodaySameTimeSalesArray.Elements[0])['LASTMONTHTHIRDPARTYTRANSTOTAL']) <> 'null' then LastMonthSameTimeTrans:= LastMonthSameTimeTrans + integer(TJSObject(TodaySameTimeSalesArray.Elements[0])['LASTMONTHTHIRDPARTYTRANSTOTAL']);
      if string(TJSObject(TodaySameTimeSalesArray.Elements[0])['LASTMONTHPERSONSSALESTOTAL']) = 'null' then LastMonthSameTimePersonsSales:= 0
      else LastMonthSameTimePersonsSales:= integer(TJSObject(TodaySameTimeSalesArray.Elements[0])['LASTMONTHPERSONSSALESTOTAL']);
      if string(TJSObject(TodaySameTimeSalesArray.Elements[0])['LASTMONTHPERSONSTOTAL']) = 'null' then LastMonthSameTimePersons:= 0
      else LastMonthSameTimePersons:= integer(TJSObject(TodaySameTimeSalesArray.Elements[0])['LASTMONTHPERSONSTOTAL']);

    end;

  end;

end;

procedure TfrmDashboard.ProcessDailySalesResult ( AResponse: TJSXMLHttpRequest);
var
  i, j: integer;
  //TodaySalesArray, TodaySameTimeSalesArray: TJSArray;

  CurrentLocationDetailsArrayNumber: integer;
  CurrentDateLabel: string;
begin
  //console.log('91');
  SetLength(LocationDailySalesDetails, Length(LocationDailySalesDetails) + 1);
  //console.log('92');
  CurrentLocationDetailsArrayNumber:= Length(LocationDailySalesDetails) - 1;
  //console.log('93');
  //console.log(AResponse.response);

  with LocationDailySalesDetails[CurrentLocationDetailsArrayNumber] do begin
    //SalesData:= TJSArray(TJSObject(Aresponse.response)['DailySales']);
    //console.log(SalesData);
    //console.log(TJSObject(SalesData.Elements[0]));

    ThirdPartyDataLoaded:= TJSArray(TJSObject(Aresponse.response)['3rdPartySales']);
    //Console.log(ThirdPartyDataLoaded);

    SetLength(InHouseDataArray, Length(DateLabelsArray));
    SetLength(InHouseTransDataArray, Length(DateLabelsArray));
    SetLength(ThirdPartyDataArray, Length(DateLabelsArray));
    SetLength(ThirdPartyTransDataArray, Length(DateLabelsArray));

    for j := 0 to Length(DateLabelsArray) - 1 do
    begin
      InHouseDataArray[j]:= 0;
      InHouseTransDataArray[j]:= 0;
      ThirdPartyDataArray[j]:= 0;
      ThirdPartyTransDataArray[j]:= 0;
    end;

    j:= 0;
    for i := 0 to ThirdPartyDataLoaded.Length - 1 do
    begin
      CurrentDateLabel:= Copy(string(TJSObject(ThirdPartyDataLoaded.Elements[i])['DATE']), 9, 2)+'/'+Copy(string(TJSObject(ThirdPartyDataLoaded.Elements[i])['DATE']), 6, 2);
      while DateLabelsArray[j] <> CurrentDateLabel do inc(j);
      //Console.log('CurrentDateLabel: '+CurrentDateLabel);

      InHouseDataArray[j]:= Round(double(TJSObject(ThirdPartyDataLoaded.Elements[i])['INHOUSENETTOTAL']));
      InHouseTransDataArray[j]:= integer(TJSObject(ThirdPartyDataLoaded.Elements[i])['INHOUSETRANSTOTAL']);

      ThirdPartyDataArray[j]:= Round(double(TJSObject(ThirdPartyDataLoaded.Elements[i])['THIRDPARTYTOTAL']));
      ThirdPartyTransDataArray[j]:= integer(TJSObject(ThirdPartyDataLoaded.Elements[i])['THIRDPARTYTRANSTOTAL']);

      inc(j);
    end;
  end;

end;

{procedure TfrmDashboard.ProcessResult ( AResponse: TJSXMLHttpRequest);
var
  i: integer;
  TodaySalesArray, TodaySameTimeSalesArray: TJSArray;

  TodaySales, TodayAGC, TodayThirdPartySales, TodayThirdParty: double;
  TodayTrans: integer;

  LastWeekSales, LastWeekAGC, LastWeekThirdPartySales, LastWeekThirdParty: double;
  LastWeekSalesDiff, LastWeekAGCDiff, LastWeekThirdPartyDiff: double;
  LastWeekSameTimeSales, LastWeekSameTimeAGC, LastWeekSameTimeThirdPartySales, LastWeekSameTimeThirdParty: double;
  LastWeekSameTimeSalesDiff, LastWeekSameTimeAGCDiff, LastWeekSameTimeThirdPartyDiff: double;
  LastWeekTrans, LastWeekSameTimeTrans: integer;
  LastWeekTransDiff, LastWeekSameTimeTransDiff : double;

  LastMonthSales, LastMonthAGC, LastMonthThirdPartySales, LastMonthThirdParty: double;
  LastMonthSalesDiff, LastMonthAGCDiff, LastMonthThirdPartyDiff: double;
  LastMonthSameTimeSales, LastMonthSameTimeAGC, LastMonthSameTimeThirdPartySales, LastMonthSameTimeThirdParty: double;
  LastMonthSameTimeSalesDiff, LastMonthSameTimeAGCDiff, LastMonthSameTimeThirdPartyDiff: double;
  LastMonthTrans, LastMonthSameTimeTrans: integer;
  LastMonthTransDiff, LastMonthSameTimeTransDiff : double;

  SalesLabelsArray: array of string;
  SalesDataArray: array of integer;
  TransDataArray: array of integer;
  AGCDataArray: array of double;

  InHouseLabelsArray: array of string;
  InHouseDataArray: array of integer;
  InHouseTransDataArray: array of integer;
  InHouseAGCDataArray: array of double;

  ThirdPartyDataArray: array of integer;
  ThirdPartyTransDataArray: array of integer;
  ThirdPartyAGCDataArray: array of double;

  TotalAGCDataArray: array of double;

  el: TJSElement;
  CurrentClass: string;
begin
  console.log(AResponse.response);

  TodaySalesArray:= TJSArray(TJSObject(Aresponse.response)['TodaySales']);
  TodaySameTimeSalesArray:= TJSArray(TJSObject(Aresponse.response)['TodaySalesSameTime']);

  if TodaySalesArray.Length = 0 then
  begin
    TodaySales:= 0;
    TodayTrans:= 0;
    TodayAGC:= 0;
    TodayThirdParty:= 0;

    LastWeekSalesDiff:= 0;
    LastWeekTransDiff:= 0;
    LastWeekAGCDiff:= 0;
    LastWeekThirdPartyDiff:= 0;

    LastWeekSameTimeSalesDiff:= 0;
    LastWeekSameTimeTransDiff:= 0;
    LastWeekSameTimeAGCDiff:= 0;
    LastWeekSameTimeThirdPartyDiff:= 0;

    //************

    LastMonthSalesDiff:= 0;
    LastMonthTransDiff:= 0;
    LastMonthAGCDiff:= 0;
    LastMonthThirdPartyDiff:= 0;

    LastMonthSameTimeSalesDiff:= 0;
    LastMonthSameTimeTransDiff:= 0;
    LastMonthSameTimeAGCDiff:= 0;
    LastMonthSameTimeThirdPartyDiff:= 0;

  end else
  begin

    if string(TJSObject(TodaySalesArray.Elements[0])['TODAYTHIRDPARTYTOTAL']) = 'null' then TodayThirdPartySales:= 0
    else TodayThirdPartySales:= double(TJSObject(TodaySalesArray.Elements[0])['TODAYTHIRDPARTYTOTAL']);
    if string(TJSObject(TodaySalesArray.Elements[0])['TODAYINHOUSENETTOTAL']) = 'null' then TodaySales:= TodayThirdPartySales
    else TodaySales:= double(TJSObject(TodaySalesArray.Elements[0])['TODAYINHOUSENETTOTAL']) + TodayThirdPartySales;
    if string(TJSObject(TodaySalesArray.Elements[0])['TODAYINHOUSETRANSTOTAL']) = 'null' then TodayTrans:= 0
    else TodayTrans:= integer(TJSObject(TodaySalesArray.Elements[0])['TODAYINHOUSETRANSTOTAL']);
    if string(TJSObject(TodaySalesArray.Elements[0])['TODAYTHIRDPARTYTRANSTOTAL']) <> 'null' then TodayTrans:= TodayTrans + integer(TJSObject(TodaySalesArray.Elements[0])['TODAYTHIRDPARTYTRANSTOTAL']);
    if TodayTrans = 0 then TodayAGC:= 0
    else TodayAGC:= TodaySales / TodayTrans;
    if TodaySales = 0 then TodayThirdParty:= 0
    else TodayThirdParty:= 100 * TodayThirdPartySales / TodaySales;

    //***************************

    //console.log(string(TJSObject(TodaySalesArray.Elements[0])['LASTWEEKINHOUSENETTOTAL']));
    if string(TJSObject(TodaySalesArray.Elements[0])['LASTWEEKTHIRDPARTYTOTAL']) = 'null' then LastWeekThirdPartySales:= 0
    else LastWeekThirdPartySales:= double(TJSObject(TodaySalesArray.Elements[0])['LASTWEEKTHIRDPARTYTOTAL']);
    if string(TJSObject(TodaySalesArray.Elements[0])['LASTWEEKINHOUSENETTOTAL']) = 'null' then LastWeekSales:= LastWeekThirdPartySales
    else LastWeekSales:= double(TJSObject(TodaySalesArray.Elements[0])['LASTWEEKINHOUSENETTOTAL']) + LastWeekThirdPartySales;
    if string(TJSObject(TodaySalesArray.Elements[0])['LASTWEEKINHOUSETRANSTOTAL']) = 'null' then LastWeekTrans:= 0
    else LastWeekTrans:= integer(TJSObject(TodaySalesArray.Elements[0])['LASTWEEKINHOUSETRANSTOTAL']);
    if string(TJSObject(TodaySalesArray.Elements[0])['LASTWEEKTHIRDPARTYTRANSTOTAL']) <> 'null' then LastWeekTrans:= LastWeekTrans + integer(TJSObject(TodaySalesArray.Elements[0])['LASTWEEKTHIRDPARTYTRANSTOTAL']);
    if LastWeekTrans = 0 then LastWeekAGC:= 0
    else LastWeekAGC:= LastWeekSales / LastWeekTrans;
    if LastWeekSales = 0 then LastWeekThirdParty:= 0
    else LastWeekThirdParty:= 100 * LastWeekThirdPartySales / LastWeekSales;

    if LastWeekSales = 0 then
    begin
      if TodaySales = 0 then LastWeekSalesDiff:= 0
      else LastWeekSalesDiff:= 100;
    end else LastWeekSalesDiff:= 100 * (TodaySales - LastWeekSales) / LastWeekSales;
    if LastWeekTrans = 0 then
    begin
      if TodayTrans = 0 then LastWeekTransDiff:= 0
      else LastWeekTransDiff:= 100;
    end else LastWeekTransDiff:= 100 * (TodayTrans - LastWeekTrans) / LastWeekTrans;
    LastWeekAGCDiff:= TodayAGC - LastWeekAGC;
    LastWeekThirdPartyDiff:= TodayThirdParty - LastWeekThirdParty;

    if string(TJSObject(TodaySameTimeSalesArray.Elements[0])['LASTWEEKTHIRDPARTYTOTAL']) = 'null' then LastWeekSameTimeThirdPartySales:= 0
    else LastWeekSameTimeThirdPartySales:= double(TJSObject(TodaySameTimeSalesArray.Elements[0])['LASTWEEKTHIRDPARTYTOTAL']);
    if string(TJSObject(TodaySameTimeSalesArray.Elements[0])['LASTWEEKINHOUSENETTOTAL']) = 'null' then LastWeekSameTimeSales:= LastWeekSameTimeThirdPartySales
    else LastWeekSameTimeSales:= double(TJSObject(TodaySameTimeSalesArray.Elements[0])['LASTWEEKINHOUSENETTOTAL']) + LastWeekSameTimeThirdPartySales;
    if string(TJSObject(TodaySameTimeSalesArray.Elements[0])['LASTWEEKINHOUSETRANSTOTAL']) = 'null' then LastWeekSameTimeTrans:= 0
    else LastWeekSameTimeTrans:= integer(TJSObject(TodaySameTimeSalesArray.Elements[0])['LASTWEEKINHOUSETRANSTOTAL']);
    if string(TJSObject(TodaySameTimeSalesArray.Elements[0])['LASTWEEKTHIRDPARTYTRANSTOTAL']) <> 'null' then LastWeekSameTimeTrans:= LastWeekSameTimeTrans + integer(TJSObject(TodaySameTimeSalesArray.Elements[0])['LASTWEEKTHIRDPARTYTRANSTOTAL']);
    if LastWeekSameTimeTrans = 0 then LastWeekSameTimeAGC:= 0
    else LastWeekSameTimeAGC:= LastWeekSameTimeSales / LastWeekSameTimeTrans;
    if LastWeekSameTimeSales = 0 then LastWeekSameTimeThirdParty:= 0
    else LastWeekSameTimeThirdParty:= 100 * LastWeekSameTimeThirdPartySales / LastWeekSameTimeSales;

    if LastWeekSameTimeSales = 0 then
    begin
      if TodaySales = 0 then LastWeekSameTimeSalesDiff:= 0
      else LastWeekSameTimeSalesDiff:= 100;
    end else LastWeekSameTimeSalesDiff:= 100 * (TodaySales - LastWeekSameTimeSales) / LastWeekSameTimeSales;
    if LastWeekSameTimeTrans = 0 then
    begin
      if TodayTrans = 0 then LastWeekSameTimeTransDiff:= 0
      else LastWeekSameTimeTransDiff:= 100;
    end else LastWeekSameTimeTransDiff:= 100 * (TodayTrans - LastWeekSameTimeTrans) / LastWeekSameTimeTrans;
    LastWeekSameTimeAGCDiff:= TodayAGC - LastWeekSameTimeAGC;
    LastWeekSameTimeThirdPartyDiff:= TodayThirdParty - LastWeekSameTimeThirdParty;

    //***************************

    //console.log(string(TJSObject(TodaySalesArray.Elements[0])['LASTMONTHINHOUSENETTOTAL']));
    if string(TJSObject(TodaySalesArray.Elements[0])['LASTMONTHTHIRDPARTYTOTAL']) = 'null' then LastMonthThirdPartySales:= 0
    else LastMonthThirdPartySales:= double(TJSObject(TodaySalesArray.Elements[0])['LASTMONTHTHIRDPARTYTOTAL']);
    if string(TJSObject(TodaySalesArray.Elements[0])['LASTMONTHINHOUSENETTOTAL']) = 'null' then LastMonthSales:= LastMonthThirdPartySales
    else LastMonthSales:= double(TJSObject(TodaySalesArray.Elements[0])['LASTMONTHINHOUSENETTOTAL']) + LastMonthThirdPartySales;
    if string(TJSObject(TodaySalesArray.Elements[0])['LASTMONTHINHOUSETRANSTOTAL']) = 'null' then LastMonthTrans:= 0
    else LastMonthTrans:= integer(TJSObject(TodaySalesArray.Elements[0])['LASTMONTHINHOUSETRANSTOTAL']);
    if string(TJSObject(TodaySalesArray.Elements[0])['LASTMONTHTHIRDPARTYTRANSTOTAL']) <> 'null' then LastMonthTrans:= LastMonthTrans + integer(TJSObject(TodaySalesArray.Elements[0])['LASTMONTHTHIRDPARTYTRANSTOTAL']);
    if LastMonthTrans = 0 then LastMonthAGC:= 0
    else LastMonthAGC:= LastMonthSales / LastMonthTrans;
    if LastMonthSales = 0 then LastMonthThirdParty:= 0
    else LastMonthThirdParty:= 100 * LastMonthThirdPartySales / LastMonthSales;

    if LastMonthSales = 0 then
    begin
      if TodaySales = 0 then LastMonthSalesDiff:= 0
      else LastMonthSalesDiff:= 100;
    end else LastMonthSalesDiff:= 100 * (TodaySales - LastMonthSales) / LastMonthSales;
    if LastMonthTrans = 0 then
    begin
      if TodayTrans = 0 then LastMonthTransDiff:= 0
      else LastMonthTransDiff:= 100;
    end else LastMonthTransDiff:= 100 * (TodayTrans - LastMonthTrans) / LastMonthTrans;
    LastMonthAGCDiff:= TodayAGC - LastMonthAGC;
    LastMonthThirdPartyDiff:= TodayThirdParty - LastMonthThirdParty;

    if string(TJSObject(TodaySameTimeSalesArray.Elements[0])['LASTMONTHTHIRDPARTYTOTAL']) = 'null' then LastMonthSameTimeThirdPartySales:= 0
    else LastMonthSameTimeThirdPartySales:= double(TJSObject(TodaySameTimeSalesArray.Elements[0])['LASTMONTHTHIRDPARTYTOTAL']);
    if string(TJSObject(TodaySameTimeSalesArray.Elements[0])['LASTMONTHINHOUSENETTOTAL']) = 'null' then LastMonthSameTimeSales:= LastMonthSameTimeThirdPartySales
    else LastMonthSameTimeSales:= double(TJSObject(TodaySameTimeSalesArray.Elements[0])['LASTMONTHINHOUSENETTOTAL']) + LastMonthSameTimeThirdPartySales;
    if string(TJSObject(TodaySameTimeSalesArray.Elements[0])['LASTMONTHINHOUSETRANSTOTAL']) = 'null' then LastMonthSameTimeTrans:= 0
    else LastMonthSameTimeTrans:= integer(TJSObject(TodaySameTimeSalesArray.Elements[0])['LASTMONTHINHOUSETRANSTOTAL']);
    if string(TJSObject(TodaySameTimeSalesArray.Elements[0])['LASTMONTHTHIRDPARTYTRANSTOTAL']) <> 'null' then LastMonthSameTimeTrans:= LastMonthSameTimeTrans + integer(TJSObject(TodaySameTimeSalesArray.Elements[0])['LASTMONTHTHIRDPARTYTRANSTOTAL']);
    if LastMonthSameTimeTrans = 0 then LastMonthSameTimeAGC:= 0
    else LastMonthSameTimeAGC:= LastMonthSameTimeSales / LastMonthSameTimeTrans;
    if LastMonthSameTimeSales = 0 then LastMonthSameTimeThirdParty:= 0
    else LastMonthSameTimeThirdParty:= 100 * LastMonthSameTimeThirdPartySales / LastMonthSameTimeSales;

    if LastMonthSameTimeSales = 0 then
    begin
      if TodaySales = 0 then LastMonthSameTimeSalesDiff:= 0
      else LastMonthSameTimeSalesDiff:= 100;
    end else LastMonthSameTimeSalesDiff:= 100 * (TodaySales - LastMonthSameTimeSales) / LastMonthSameTimeSales;
    if LastMonthSameTimeTrans = 0 then
    begin
      if TodayTrans = 0 then LastMonthSameTimeTransDiff:= 0
      else LastMonthSameTimeTransDiff:= 100;
    end else LastMonthSameTimeTransDiff:= 100 * (TodayTrans - LastMonthSameTimeTrans) / LastMonthSameTimeTrans;
    LastMonthSameTimeAGCDiff:= TodayAGC - LastMonthSameTimeAGC;
    LastMonthSameTimeThirdPartyDiff:= TodayThirdParty - LastMonthSameTimeThirdParty;

  end;

  asm
    LoadCountUpDetails('TodaySalesLabel', TodaySales, 0);
    LoadCountUpDetails('TodayTransLabel', TodayTrans, 0);
    LoadCountUpDetails('TodayAGCLabel', TodayAGC, 1);
    LoadCountUpDetails('TodayThirdPartyLabel', TodayThirdParty, 0);
  end;
  //***************************

  el := document.getElementById('LastWeekSalesDiff');
  CurrentClass:= el['class'];
  CurrentClass:= CurrentClass.Replace('text-success', '');
  CurrentClass:= CurrentClass.Replace('text-danger', '');
  if LastWeekSalesDiff >= 0 then CurrentClass:= CurrentClass+' text-success'
  else CurrentClass:= CurrentClass+' text-danger';
  el['class'] := CurrentClass;
  if (LastWeekSalesDiff <> 0) and (LastWeekSalesDiff > -1) and (LastWeekSalesDiff < 1) then LastWeekSalesDiffLabel.Caption:= FormatFloat('0.0%', LastWeekSalesDiff)+' '
  else LastWeekSalesDiffLabel.Caption:= FormatFloat('0%', LastWeekSalesDiff)+' ';

  el := document.getElementById('LastWeekTransDiff');
  CurrentClass:= el['class'];
  CurrentClass:= CurrentClass.Replace('text-success', '');
  CurrentClass:= CurrentClass.Replace('text-danger', '');
  if LastWeekTransDiff >= 0 then CurrentClass:= CurrentClass+' text-success'
  else CurrentClass:= CurrentClass+' text-danger';
  el['class'] := CurrentClass;
  if (LastWeekTransDiff <> 0) and (LastWeekTransDiff > -1) and (LastWeekTransDiff < 1) then LastWeekTransDiffLabel.Caption:= FormatFloat('0.0%', LastWeekTransDiff)+' '
  else LastWeekTransDiffLabel.Caption:= FormatFloat('0%', LastWeekTransDiff)+' ';

  el := document.getElementById('LastWeekAGCDiff');
  CurrentClass:= el['class'];
  CurrentClass:= CurrentClass.Replace('text-success', '');
  CurrentClass:= CurrentClass.Replace('text-danger', '');
  if LastWeekAGCDiff >= 0 then CurrentClass:= CurrentClass+' text-success'
  else CurrentClass:= CurrentClass+' text-danger';
  el['class'] := CurrentClass;
  LastWeekAGCDiffLabel.Caption:= FormatFloat('€0.0', LastWeekAGCDiff)+' ';

  el := document.getElementById('LastWeekThirdPartyDiff');
  CurrentClass:= el['class'];
  CurrentClass:= CurrentClass.Replace('text-success', '');
  CurrentClass:= CurrentClass.Replace('text-danger', '');
  if LastWeekThirdPartyDiff >= 0 then CurrentClass:= CurrentClass+' text-success'
  else CurrentClass:= CurrentClass+' text-danger';
  el['class'] := CurrentClass;
  if (LastWeekThirdPartyDiff <> 0) and (LastWeekThirdPartyDiff > -1) and (LastWeekThirdPartyDiff < 1) then LastWeekThirdPartyDiffLabel.Caption:= FormatFloat('0.0%', LastWeekThirdPartyDiff)+' '
  else LastWeekThirdPartyDiffLabel.Caption:= FormatFloat('0%', LastWeekThirdPartyDiff)+' ';


  el := document.getElementById('LastWeekSameTimeSalesDiff');
  CurrentClass:= el['class'];
  CurrentClass:= CurrentClass.Replace('text-success', '');
  CurrentClass:= CurrentClass.Replace('text-danger', '');
  if LastWeekSameTimeSalesDiff >= 0 then CurrentClass:= CurrentClass+' text-success'
  else CurrentClass:= CurrentClass+' text-danger';
  el['class'] := CurrentClass;
  if (LastWeekSameTimeSalesDiff <> 0) and (LastWeekSameTimeSalesDiff > -1) and (LastWeekSameTimeSalesDiff < 1) then LastWeekSameTimeSalesDiffLabel.Caption:= FormatFloat('0.0%', LastWeekSameTimeSalesDiff)+' '
  else LastWeekSameTimeSalesDiffLabel.Caption:= FormatFloat('0%', LastWeekSameTimeSalesDiff)+' ';

  el := document.getElementById('LastWeekSameTimeTransDiff');
  CurrentClass:= el['class'];
  CurrentClass:= CurrentClass.Replace('text-success', '');
  CurrentClass:= CurrentClass.Replace('text-danger', '');
  if LastWeekSameTimeTransDiff >= 0 then CurrentClass:= CurrentClass+' text-success'
  else CurrentClass:= CurrentClass+' text-danger';
  el['class'] := CurrentClass;
  if (LastWeekSameTimeTransDiff <> 0) and (LastWeekSameTimeTransDiff > -1) and (LastWeekSameTimeTransDiff < 1) then LastWeekSameTimeTransDiffLabel.Caption:= FormatFloat('0.0%', LastWeekSameTimeTransDiff)+' '
  else LastWeekSameTimeTransDiffLabel.Caption:= FormatFloat('0%', LastWeekSameTimeTransDiff)+' ';

  el := document.getElementById('LastWeekSameTimeAGCDiff');
  CurrentClass:= el['class'];
  CurrentClass:= CurrentClass.Replace('text-success', '');
  CurrentClass:= CurrentClass.Replace('text-danger', '');
  if LastWeekSameTimeAGCDiff >= 0 then CurrentClass:= CurrentClass+' text-success'
  else CurrentClass:= CurrentClass+' text-danger';
  el['class'] := CurrentClass;
  LastWeekSameTimeAGCDiffLabel.Caption:= FormatFloat('€0.0', LastWeekSameTimeAGCDiff)+' ';

  el := document.getElementById('LastWeekSameTimeThirdPartyDiff');
  CurrentClass:= el['class'];
  CurrentClass:= CurrentClass.Replace('text-success', '');
  CurrentClass:= CurrentClass.Replace('text-danger', '');
  if LastWeekSameTimeThirdPartyDiff >= 0 then CurrentClass:= CurrentClass+' text-success'
  else CurrentClass:= CurrentClass+' text-danger';
  el['class'] := CurrentClass;
  if (LastWeekSameTimeThirdPartyDiff <> 0) and (LastWeekSameTimeThirdPartyDiff > -1) and (LastWeekSameTimeThirdPartyDiff < 1) then LastWeekSameTimeThirdPartyDiffLabel.Caption:= FormatFloat('0.0%', LastWeekSameTimeThirdPartyDiff)+' '
  else LastWeekSameTimeThirdPartyDiffLabel.Caption:= FormatFloat('0%', LastWeekSameTimeThirdPartyDiff)+' ';

  //**************************

  el := document.getElementById('LastMonthSalesDiff');
  CurrentClass:= el['class'];
  CurrentClass:= CurrentClass.Replace('text-success', '');
  CurrentClass:= CurrentClass.Replace('text-danger', '');
  if LastMonthSalesDiff >= 0 then CurrentClass:= CurrentClass+' text-success'
  else CurrentClass:= CurrentClass+' text-danger';
  el['class'] := CurrentClass;
  if (LastMonthSalesDiff <> 0) and (LastMonthSalesDiff > -1) and (LastMonthSalesDiff < 1) then LastMonthSalesDiffLabel.Caption:= FormatFloat('0.0%', LastMonthSalesDiff)+' '
  else LastMonthSalesDiffLabel.Caption:= FormatFloat('0%', LastMonthSalesDiff)+' ';

  el := document.getElementById('LastMonthTransDiff');
  CurrentClass:= el['class'];
  CurrentClass:= CurrentClass.Replace('text-success', '');
  CurrentClass:= CurrentClass.Replace('text-danger', '');
  if LastMonthTransDiff >= 0 then CurrentClass:= CurrentClass+' text-success'
  else CurrentClass:= CurrentClass+' text-danger';
  el['class'] := CurrentClass;
  if (LastMonthTransDiff <> 0) and (LastMonthTransDiff > -1) and (LastMonthTransDiff < 1) then LastMonthTransDiffLabel.Caption:= FormatFloat('0.0%', LastMonthTransDiff)+' '
  else LastMonthTransDiffLabel.Caption:= FormatFloat('0%', LastMonthTransDiff)+' ';

  el := document.getElementById('LastMonthAGCDiff');
  CurrentClass:= el['class'];
  CurrentClass:= CurrentClass.Replace('text-success', '');
  CurrentClass:= CurrentClass.Replace('text-danger', '');
  if LastMonthAGCDiff >= 0 then CurrentClass:= CurrentClass+' text-success'
  else CurrentClass:= CurrentClass+' text-danger';
  el['class'] := CurrentClass;
  LastMonthAGCDiffLabel.Caption:= FormatFloat('€0.0', LastMonthAGCDiff)+' ';

  el := document.getElementById('LastMonthThirdPartyDiff');
  CurrentClass:= el['class'];
  CurrentClass:= CurrentClass.Replace('text-success', '');
  CurrentClass:= CurrentClass.Replace('text-danger', '');
  if LastMonthThirdPartyDiff >= 0 then CurrentClass:= CurrentClass+' text-success'
  else CurrentClass:= CurrentClass+' text-danger';
  el['class'] := CurrentClass;
  if (LastMonthThirdPartyDiff <> 0) and (LastMonthThirdPartyDiff > -1) and (LastMonthThirdPartyDiff < 1) then LastMonthThirdPartyDiffLabel.Caption:= FormatFloat('0.0%', LastMonthThirdPartyDiff)+' '
  else LastMonthThirdPartyDiffLabel.Caption:= FormatFloat('0%', LastMonthThirdPartyDiff)+' ';


  el := document.getElementById('LastMonthSameTimeSalesDiff');
  CurrentClass:= el['class'];
  CurrentClass:= CurrentClass.Replace('text-success', '');
  CurrentClass:= CurrentClass.Replace('text-danger', '');
  if LastMonthSameTimeSalesDiff >= 0 then CurrentClass:= CurrentClass+' text-success'
  else CurrentClass:= CurrentClass+' text-danger';
  el['class'] := CurrentClass;
  if (LastMonthSameTimeSalesDiff <> 0) and (LastMonthSameTimeSalesDiff > -1) and (LastMonthSameTimeSalesDiff < 1) then LastMonthSameTimeSalesDiffLabel.Caption:= FormatFloat('0.0%', LastMonthSameTimeSalesDiff)+' '
  else LastMonthSameTimeSalesDiffLabel.Caption:= FormatFloat('0%', LastMonthSameTimeSalesDiff)+' ';

  el := document.getElementById('LastMonthSameTimeTransDiff');
  CurrentClass:= el['class'];
  CurrentClass:= CurrentClass.Replace('text-success', '');
  CurrentClass:= CurrentClass.Replace('text-danger', '');
  if LastMonthSameTimeTransDiff >= 0 then CurrentClass:= CurrentClass+' text-success'
  else CurrentClass:= CurrentClass+' text-danger';
  el['class'] := CurrentClass;
  if (LastMonthSameTimeTransDiff <> 0) and (LastMonthSameTimeTransDiff > -1) and (LastMonthSameTimeTransDiff < 1) then LastMonthSameTimeTransDiffLabel.Caption:= FormatFloat('0.0%', LastMonthSameTimeTransDiff)+' '
  else LastMonthSameTimeTransDiffLabel.Caption:= FormatFloat('0%', LastMonthSameTimeTransDiff)+' ';

  el := document.getElementById('LastMonthSameTimeAGCDiff');
  CurrentClass:= el['class'];
  CurrentClass:= CurrentClass.Replace('text-success', '');
  CurrentClass:= CurrentClass.Replace('text-danger', '');
  if LastMonthSameTimeAGCDiff >= 0 then CurrentClass:= CurrentClass+' text-success'
  else CurrentClass:= CurrentClass+' text-danger';
  el['class'] := CurrentClass;
  LastMonthSameTimeAGCDiffLabel.Caption:= FormatFloat('€0.0', LastMonthSameTimeAGCDiff)+' ';

  el := document.getElementById('LastMonthSameTimeThirdPartyDiff');
  CurrentClass:= el['class'];
  CurrentClass:= CurrentClass.Replace('text-success', '');
  CurrentClass:= CurrentClass.Replace('text-danger', '');
  if LastMonthSameTimeThirdPartyDiff >= 0 then CurrentClass:= CurrentClass+' text-success'
  else CurrentClass:= CurrentClass+' text-danger';
  el['class'] := CurrentClass;
  if (LastMonthSameTimeThirdPartyDiff <> 0) and (LastMonthSameTimeThirdPartyDiff > -1) and (LastMonthSameTimeThirdPartyDiff < 1) then LastMonthSameTimeThirdPartyDiffLabel.Caption:= FormatFloat('0.0%', LastMonthSameTimeThirdPartyDiff)+' '
  else LastMonthSameTimeThirdPartyDiffLabel.Caption:= FormatFloat('0%', LastMonthSameTimeThirdPartyDiff)+' ';

  //**********************************************


  SalesData:= TJSArray(TJSObject(Aresponse.response)['DailySales']);
  //console.log(SalesData);
  //console.log(TJSObject(SalesData.Elements[0]));

  SetLength(SalesLabelsArray, SalesData.Length);
  SetLength(SalesDataArray, SalesData.Length);
  SetLength(TransDataArray, SalesData.Length);
  SetLength(AGCDataArray, SalesData.Length);

  for i := 0 to SalesData.Length - 1 do
  begin
    SalesLabelsArray[i]:= Copy(string(TJSObject(SalesData.Elements[i])['DATE']), 9, 2)+'/'+Copy(string(TJSObject(SalesData.Elements[i])['DATE']), 6, 2);
    SalesDataArray[i]:= Round(double(TJSObject(SalesData.Elements[i])['NETTOTAL']));
    TransDataArray[i]:= integer(TJSObject(SalesData.Elements[i])['TRANSTOTAL']);

    if TransDataArray[i] = 0 then AGCDataArray[i]:= 0
    else AGCDataArray[i]:= SalesDataArray[i] / TransDataArray[i];

  end;

  ThirdPartyData:= TJSArray(TJSObject(Aresponse.response)['3rdPartySales']);

  SetLength(InHouseLabelsArray, ThirdPartyData.Length);
  SetLength(InHouseDataArray, ThirdPartyData.Length);
  SetLength(InHouseTransDataArray, ThirdPartyData.Length);
  SetLength(InHouseAGCDataArray, ThirdPartyData.Length);
  SetLength(ThirdPartyDataArray, ThirdPartyData.Length);
  SetLength(ThirdPartyTransDataArray, ThirdPartyData.Length);
  SetLength(ThirdPartyAGCDataArray, ThirdPartyData.Length);
  SetLength(TotalAGCDataArray, ThirdPartyData.Length);

  for i := 0 to ThirdPartyData.Length - 1 do
  begin
    InHouseLabelsArray[i]:= Copy(string(TJSObject(ThirdPartyData.Elements[i])['DATE']), 9, 2)+'/'+Copy(string(TJSObject(ThirdPartyData.Elements[i])['DATE']), 6, 2);

    InHouseDataArray[i]:= Round(double(TJSObject(ThirdPartyData.Elements[i])['INHOUSENETTOTAL']));
    InHouseTransDataArray[i]:= integer(TJSObject(ThirdPartyData.Elements[i])['INHOUSETRANSTOTAL']);

    if InHouseTransDataArray[i] = 0 then InHouseAGCDataArray[i]:= 0
    else InHouseAGCDataArray[i]:= InHouseDataArray[i] / InHouseTransDataArray[i];

    ThirdPartyDataArray[i]:= Round(double(TJSObject(ThirdPartyData.Elements[i])['THIRDPARTYTOTAL']));
    ThirdPartyTransDataArray[i]:= integer(TJSObject(ThirdPartyData.Elements[i])['THIRDPARTYTRANSTOTAL']);

    if ThirdPartyTransDataArray[i] = 0 then ThirdPartyAGCDataArray[i]:= 0
    else ThirdPartyAGCDataArray[i]:= ThirdPartyDataArray[i] / ThirdPartyTransDataArray[i];
    //ShowMessage('-1');
    if (InHouseTransDataArray[i] + ThirdPartyTransDataArray[i]) = 0 then begin
      //ShowMessage('0');
      TotalAGCDataArray[i]:= 0
    end else begin
      //ShowMessage('1');
      TotalAGCDataArray[i]:= (InHouseDataArray[i] + ThirdPartyDataArray[i]) / (InHouseTransDataArray[i] + ThirdPartyTransDataArray[i]);
    end;
  end;

  asm
    LoadGraphs(SalesLabelsArray, SalesDataArray, TransDataArray, AGCDataArray, InHouseLabelsArray, InHouseDataArray, InHouseTransDataArray, InHouseAGCDataArray, ThirdPartyDataArray, ThirdPartyTransDataArray, ThirdPartyAGCDataArray, TotalAGCDataArray);
  end;
  //console.log(SalesDataTotalSales);
  //for i := 0 to SalesData.Length - 1 do
  //begin
  //  console.log(string(TJSObject(SalesData.Elements[i])['NETTOTAL']));
  //end;
  //ShowMessage(string(TJSObject(SalesData.Elements[0])['NETTOTAL']));

  //console.log(TJSObject(SalesData.Elements[0])['NETTOTAL']);
end;}

procedure TfrmDashboard.LoadLocationDetails;
var
  Email: string;
  CurrentItem: TListItem;
begin
  Email:= TLocalStorage.GetValue('Login.Username').ToUpper;
  chooselocationdropdown.Items.Clear;
  if (Email = 'MIKE@NOVASERO.COM') then
  begin
    CurrentItem:= chooselocationdropdown.Items.Add;
    CurrentItem.LinkClassName:= 'dropdown-item';
    CurrentItem.Link:= '#';
    CurrentItem.Text:= 'BAO PAU NICOSIA';
    CurrentItem.Tag:= 1;
    CurrentItem.LinkTarget:= 'http://api.baopau.com:8900';

    CurrentItem:= chooselocationdropdown.Items.Add;
    CurrentItem.LinkClassName:= 'dropdown-item';
    CurrentItem.Link:= '#';
    CurrentItem.Text:= 'BAO PAU LIMASSOL';
    CurrentItem.Tag:= 2;
    CurrentItem.LinkTarget:= 'http://apilim.baopau.com:8900';

    CurrentItem:= chooselocationdropdown.Items.Add;
    CurrentItem.LinkClassName:= 'dropdown-item';
    CurrentItem.Link:= '#';
    CurrentItem.Text:= 'CITO EXPRESS NICOSIA';
    CurrentItem.Tag:= 1;
    CurrentItem.LinkTarget:= 'http://213.149.172.164:8900';

    CurrentItem:= chooselocationdropdown.Items.Add;
    CurrentItem.LinkClassName:= 'dropdown-item';
    CurrentItem.Link:= '#';
    CurrentItem.Text:= 'PIATSA GOUROUNAKI ONASAGOROU';
    CurrentItem.Tag:= 1;
    CurrentItem.LinkTarget:= 'http://ona.piatsaexpress.com:8900';

    CurrentItem:= chooselocationdropdown.Items.Add;
    CurrentItem.LinkClassName:= 'dropdown-item';
    CurrentItem.Link:= '#';
    CurrentItem.Text:= 'PIATSA GOUROUNAKI EXPRESS';
    CurrentItem.Tag:= 2;
    CurrentItem.LinkTarget:= 'http://express.piatsaexpress.com:8900';

    CurrentItem:= chooselocationdropdown.Items.Add;
    CurrentItem.LinkClassName:= 'dropdown-item';
    CurrentItem.Link:= '#';
    CurrentItem.Text:= 'PIATSA GOUROUNAKI ENGOMI';
    CurrentItem.Tag:= 4;
    CurrentItem.LinkTarget:= 'http://engomi.piatsaexpress.com:8900';

    CurrentItem:= chooselocationdropdown.Items.Add;
    CurrentItem.LinkClassName:= 'dropdown-item';
    CurrentItem.Link:= '#';
    CurrentItem.Text:= 'PARADOSIAKO ENGOMI';
    CurrentItem.Tag:= 1;
    CurrentItem.LinkTarget:= 'http://212.31.112.98:8900';
  end else if (Email = 'INFO@CITO.EXPRESS') then
  begin

    {chooselocationdropdown.Items[1].Text:= 'CITO EXPRESS NICOSIA';
    chooselocationdropdown.Items[1].Tag:= 1;
    chooselocationdropdown.Items[0].LinkTarget:= 'http://213.149.172.164:8900';
    chooselocationdropdown.Items[1].LinkTarget:= 'http://213.149.172.164:8900';}

    CurrentItem:= chooselocationdropdown.Items.Add;
    CurrentItem.LinkClassName:= 'dropdown-item';
    CurrentItem.Link:= '#';
    CurrentItem.Text:= 'CITO EXPRESS NICOSIA';
    CurrentItem.Tag:= 1;
    CurrentItem.LinkTarget:= 'http://213.149.172.164:8900';
  end else if (Email = 'CONSTANTINOS@NICHEADV.COM') or (Email = 'CGEORGIOU@PIATSAEXPRESS.COM') then
  begin
    {chooselocationdropdown.Items[1].Text:= 'PIATSA GOUROUNAKI ONASAGOROU';
    chooselocationdropdown.Items[1].Tag:= 1;
    chooselocationdropdown.Items[0].LinkTarget:= 'http://ona.piatsaexpress.com:8900';
    chooselocationdropdown.Items[1].LinkTarget:= 'http://ona.piatsaexpress.com:8900';}

    CurrentItem:= chooselocationdropdown.Items.Add;
    CurrentItem.LinkClassName:= 'dropdown-item';
    CurrentItem.Link:= '#';
    CurrentItem.Text:= 'PIATSA GOUROUNAKI ONASAGOROU';
    CurrentItem.Tag:= 1;
    CurrentItem.LinkTarget:= 'http://ona.piatsaexpress.com:8900';

    CurrentItem:= chooselocationdropdown.Items.Add;
    CurrentItem.LinkClassName:= 'dropdown-item';
    CurrentItem.Link:= '#';
    CurrentItem.Text:= 'PIATSA GOUROUNAKI EXPRESS';
    CurrentItem.Tag:= 2;
    CurrentItem.LinkTarget:= 'http://express.piatsaexpress.com:8900';

    CurrentItem:= chooselocationdropdown.Items.Add;
    CurrentItem.LinkClassName:= 'dropdown-item';
    CurrentItem.Link:= '#';
    CurrentItem.Text:= 'PIATSA GOUROUNAKI ENGOMI';
    CurrentItem.Tag:= 4;
    CurrentItem.LinkTarget:= 'http://engomi.piatsaexpress.com:8900';
  end else if (Email = 'MIKE.PANTELI@GMAIL.COM') or (Email = 'IOANNOU.ANTONIS1969@GMAIL.COM') then
  begin
    CurrentItem:= chooselocationdropdown.Items.Add;
    CurrentItem.LinkClassName:= 'dropdown-item';
    CurrentItem.Link:= '#';
    CurrentItem.Text:= 'PARADOSIAKO ENGOMI';
    CurrentItem.Tag:= 1;
    CurrentItem.LinkTarget:= 'http://212.31.112.98:8900';
    //CurrentItem.Active:= True;
    {CurrentItem:= chooselocationdropdown.Items.Add;
    CurrentItem.LinkClassName:= 'dropdown-item';
    CurrentItem.Link:= '#';
    CurrentItem.Text:= 'PARADOSIAKO ENGOMI';
    CurrentItem.Tag:= 1;
    CurrentItem.LinkTarget:= 'http://212.31.112.98:8900';}
  end else
  begin
    //URLBase:= 'http://api.baopau.com:8900';
    {chooselocationdropdown.Items[1].Text:= 'BAO PAU NICOSIA';
    chooselocationdropdown.Items[1].Tag:= 1;
    chooselocationdropdown.Items[0].LinkTarget:= 'http://api.baopau.com:8900';
    chooselocationdropdown.Items[1].LinkTarget:= 'http://api.baopau.com:8900';}

    chooselocationdropdown.Items.Clear;

    CurrentItem:= chooselocationdropdown.Items.Add;
    CurrentItem.LinkClassName:= 'dropdown-item';
    CurrentItem.Link:= '#';
    CurrentItem.Text:= 'BAO PAU NICOSIA';
    CurrentItem.Tag:= 1;
    CurrentItem.LinkTarget:= 'http://api.baopau.com:8900';

    CurrentItem:= chooselocationdropdown.Items.Add;
    CurrentItem.LinkClassName:= 'dropdown-item';
    CurrentItem.Link:= '#';
    CurrentItem.Text:= 'BAO PAU LIMASSOL';
    CurrentItem.Tag:= 2;
    CurrentItem.LinkTarget:= 'http://apilim.baopau.com:8900';
  end;
  //chooselocationdropdown.ItemIndex:= 0;
  chooselocationdropdown.Items[0].Active:= True;
  document.getElementById('chooselocationcaption').innerText:= chooselocationdropdown.Items[0].Text;
  URLBase:= chooselocationdropdown.Items[0].LinkTarget;
end;

procedure TfrmDashboard.LoadTodaysSales;
var
  Location: string;
  LResponse: TJSXMLHttpRequest;
begin
  {asm
    FromDate = document.getElementById( 'from-date' ).value;
    ToDate = document.getElementById( 'to-date' ).value;
  end;}

  SetLength(LocationTodaysSalesDetails, 0);

  //FromDate:= '2023-01-01';
  //ToDate:= '2023-07-07';
  Location:= chooselocationdropdown.Items[chooselocationdropdown.ItemIndex].Tag.ToString;
  //TodaysSalesRequest.URL:= 'http://api-dev.novasero.com:8900/SMProDashboardXData/DashboardService/GetTodaysSalesInfo?Location='+Location;
  TodaysSalesRequest.URL:= URLBase+'/SMProDashboardXData/DashboardService/GetTodaysSalesInfo?Location='+Location;
  //TodaysSalesRequest.URL:= 'http://ona.piatsaexpress.com:8900/SMProDashboardXData/DashboardService/GetTodaysSalesInfo?Location='+Location;

  LResponse:= await(TJSXMLHttpRequest, TodaysSalesRequest.Perform);

  if LResponse.Status = 200 then
  begin
    console.log(LResponse.response);
    ProcessTodaysSalesResult(LResponse);
  end;

  {TodaysSalesRequest.URL:= 'http://express.piatsaexpress.com:8900/SMProDashboardXData/DashboardService/GetTodaysSalesInfo?Location='+Location;

  LResponse:= await(TJSXMLHttpRequest, TodaysSalesRequest.Perform);

  if LResponse.Status = 200 then
  begin
    ProcessResult(LResponse);
  end;

  TodaysSalesRequest.URL:= 'http://engomi.piatsaexpress.com:8900/SMProDashboardXData/DashboardService/GetTodaysSalesInfo?Location='+Location;

  LResponse:= await(TJSXMLHttpRequest, TodaysSalesRequest.Perform);

  if LResponse.Status = 200 then
  begin
    ProcessResult(LResponse);
  end;}

  UpdateTodaysSalesGUI;
end;

procedure TfrmDashboard.chooselocationdropdownItemClick(Sender: TObject;
  AListItem: TListItem);
var
  el: TJSElement;
begin
  //ShowMessage(AListItem.Text+sLineBreak+AListItem.LinkTarget);
  document.getElementById('chooselocationcaption').innerText:= AListItem.Text;
  URLBase:= AListItem.LinkTarget;
  chooselocationdropdown.ItemIndex:= AListItem.Index;
  el:= document.getElementById('chooselocationcaption');
  //el.removeAttribute('show');
  el.className:= el.className.Replace('show', '');
  LoadTodaysAndDailySales;
end;

procedure TfrmDashboard.LoadDailySales;
var
  LResponse: TJSXMLHttpRequest;
  FromDate, ToDate, Location: string;
  DaysInRange, i: integer;
  CurrentDate: TDate;
begin
  {asm
    FromDate = document.getElementById( 'from-date' ).value;
    ToDate = document.getElementById( 'to-date' ).value;
  end;}

  TSessionStorage.SetValue('Dashboard.FromDate', DateToStr(WebDateTimePicker1.Date));
  TSessionStorage.SetValue('Dashboard.ToDate', DateToStr(WebDateTimePicker2.Date));

  FromDate:= FormatDateTime('yyyy-mm-dd', Dateof(WebDateTimePicker1.Date));
  ToDate:= FormatDateTime('yyyy-mm-dd', Dateof(WebDateTimePicker2.Date));

  //console.log('from-date value: '+FromDate);
  //console.log('to-date value: '+ToDate);
  //console.log('Wassuuuuuuuuppp?');
  SalesUpdatedLabel.Caption:= FormatDateTime('dddddd t', Now);
  //console.log(SalesUpdatedLabel.Caption);
  TransUpdatedLabel.Caption:= SalesUpdatedLabel.Caption;
  AGCUpdatedLabel.Caption:= SalesUpdatedLabel.Caption;
  DaysInRange:= DaysBetween(WebDateTimePicker2.Date, WebDateTimePicker1.Date) + 1;
  //Console.log('Number of Days: '+IntToStr(DaysInRange));
  SetLength(DateLabelsArray, DaysInRange);
  CurrentDate:= Dateof(WebDateTimePicker1.Date);
  for i := 0 to Length(DateLabelsArray) - 1 do
  begin
    DateLabelsArray[i]:= FormatDateTime('dd/mm', CurrentDate);
    CurrentDate:= CurrentDate + 1;
  end;
  //Console.log(DateLabelsArray);
  SetLength(LocationDailySalesDetails, 0);
  //FromDate:= '2023-01-01';
  //ToDate:= '2023-07-07';
  Location:= chooselocationdropdown.Items[chooselocationdropdown.ItemIndex].Tag.ToString;
  //Request2.URL:= 'http://api-dev.novasero.com:8900/SMProDashboardXData/DashboardService/GetDailySalesInfo?Location='+Location+'&FromDate='+FromDate+'&ToDate='+ToDate;
  Request2.URL:= URLBase+'/SMProDashboardXData/DashboardService/GetDailySalesInfo?Location='+Location+'&FromDate='+FromDate+'&ToDate='+ToDate;
  //Request2.URL:= 'http://ona.piatsaexpress.com:8900/SMProDashboardXData/DashboardService/GetDailySalesInfo?Location='+Location+'&FromDate='+FromDate+'&ToDate='+ToDate;
  LResponse:= await(TJSXMLHttpRequest, Request2.Perform);
  if LResponse.Status = 200 then
  begin
    console.log(LResponse.response);
    ProcessDailySalesResult(LResponse);
  end;
  //console.log('7');
  {Request2.URL:= 'http://express.piatsaexpress.com:8900/SMProDashboardXData/DashboardService/GetDailySalesInfo?Location='+Location+'&FromDate='+FromDate+'&ToDate='+ToDate;

  LResponse:= await(TJSXMLHttpRequest, Request2.Perform);

  if LResponse.Status = 200 then
  begin
    ProcessResult(LResponse);
  end;

  Request2.URL:= 'http://engomi.piatsaexpress.com:8900/SMProDashboardXData/DashboardService/GetDailySalesInfo?Location='+Location+'&FromDate='+FromDate+'&ToDate='+ToDate;

  LResponse:= await(TJSXMLHttpRequest, Request2.Perform);

  if LResponse.Status = 200 then
  begin
    ProcessResult(LResponse);
  end;}

  UpdateDailySalesGUI;
  //console.log('8');
end;

procedure TfrmDashboard.LoadTodaysandDailySales;
var
  LResponse: TJSXMLHttpRequest;
  FromDate, ToDate, Location: string;
  DaysInRange, i: integer;
  CurrentDate: TDate;
begin
  {asm
    FromDate = document.getElementById( 'from-date' ).value;
    ToDate = document.getElementById( 'to-date' ).value;
  end;}

  TSessionStorage.SetValue('Dashboard.FromDate', DateToStr(WebDateTimePicker1.Date));
  TSessionStorage.SetValue('Dashboard.ToDate', DateToStr(WebDateTimePicker2.Date));

  FromDate:= FormatDateTime('yyyy-mm-dd', Dateof(WebDateTimePicker1.Date));
  ToDate:= FormatDateTime('yyyy-mm-dd', Dateof(WebDateTimePicker2.Date));

  //console.log('from-date value: '+FromDate);
  //console.log('to-date value: '+ToDate);
  //console.log('Wassuuuuuuuuppp?');
  SalesUpdatedLabel.Caption:= FormatDateTime('dddddd t', Now);
  //console.log(SalesUpdatedLabel.Caption);
  TransUpdatedLabel.Caption:= SalesUpdatedLabel.Caption;
  AGCUpdatedLabel.Caption:= SalesUpdatedLabel.Caption;
  DaysInRange:= DaysBetween(WebDateTimePicker2.Date, WebDateTimePicker1.Date) + 1;
  //Console.log('Number of Days: '+IntToStr(DaysInRange));
  SetLength(DateLabelsArray, DaysInRange);
  CurrentDate:= Dateof(WebDateTimePicker1.Date);
  for i := 0 to Length(DateLabelsArray) - 1 do
  begin
    DateLabelsArray[i]:= FormatDateTime('dd/mm', CurrentDate);
    CurrentDate:= CurrentDate + 1;
  end;
  //Console.log(DateLabelsArray);
  SetLength(LocationDailySalesDetails, 0);
  SetLength(LocationTodaysSalesDetails, 0);
  //FromDate:= '2023-01-01';
  //ToDate:= '2023-07-07';
  Location:= chooselocationdropdown.Items[chooselocationdropdown.ItemIndex].Tag.ToString;
  //Request2.URL:= 'http://api-dev.novasero.com:8900/SMProDashboardXData/DashboardService/GetTodaysandDailySalesInfo?Location='+Location+'&FromDate='+FromDate+'&ToDate='+ToDate;
  Request3.URL:= URLBase+'/SMProDashboardXData/DashboardService/GetTodaysandDailySalesInfo?Location='+Location+'&FromDate='+FromDate+'&ToDate='+ToDate;
  //Request2.URL:= 'http://ona.piatsaexpress.com:8900/SMProDashboardXData/DashboardService/GetTodaysandDailySalesInfo?Location='+Location+'&FromDate='+FromDate+'&ToDate='+ToDate;
  LResponse:= await(TJSXMLHttpRequest, Request3.Perform);
  if LResponse.Status = 200 then
  begin
    console.log(LResponse.response);
    ProcessTodaysSalesResult(LResponse);
    ProcessDailySalesResult(LResponse);
  end;
  //console.log('7');

  UpdateTodaysSalesGUI;
  UpdateDailySalesGUI;
  //console.log('8');
end;

procedure TfrmDashboard.WebDateTimePicker1Change(Sender: TObject);
begin
  ShowMessage('OK');
end;

procedure TfrmDashboard.WebDateTimePicker1Enter(Sender: TObject);
begin
  //document.getElementById('from-date').setAttribute('style', 'outline: none;');
end;

procedure TfrmDashboard.WebDropDownControl1Click(Sender: TObject);
begin
  ShowMessage('Clicked');
end;

procedure TfrmDashboard.WebEdit1Enter(Sender: TObject);
var
  el: TJSElement;
  CurrentClass: string;
begin
  el := document.getElementById('type-here-text-group');
  //ShowMessage(el['class']);
  CurrentClass:= el['class'];
  CurrentClass:= CurrentClass.Replace('is-focused', '');
  CurrentClass:= CurrentClass.Replace('focused', '');
  CurrentClass:= CurrentClass+' focused is-focused';
  el['class'] := CurrentClass;
  //ShowMessage(CurrentClass+'!!'+CurrentClass.IndexOf('g-sidenav-pinned').ToString);
end;

procedure TfrmDashboard.WebEdit1Exit(Sender: TObject);
var
  el: TJSElement;
  CurrentClass: string;
begin
  if trim(WebEdit1.Text) = '' then begin

    el := document.getElementById('type-here-text-group');
    //ShowMessage(el['class']);
    CurrentClass:= el['class'];
    CurrentClass:= CurrentClass.Replace('is-focused', '');
    CurrentClass:= CurrentClass.Replace('focused', '');
    el['class'] := CurrentClass;
    //ShowMessage(CurrentClass);
  end
end;

procedure TfrmDashboard.WebFormCreate(Sender: TObject);
begin
  //ChooseLocationRowDiv.Visible:= False;
  {document.getElementById('chooselocationdropdown').innerHTML:= '<li><a class="dropdown-item" href="#" id="first-action2">Action</a></li>'
              +'<li><a class="dropdown-item" href="#" id="second-action2">Another action</a></li>'
              +'<li><a class="dropdown-item" href="#" id="third-action2">Something else here</a></li>';}

  document.getElementById('chooselocationcaption').innerText:= chooselocationdropdown.Items[chooselocationdropdown.ItemIndex].Text;

  LoadLocationDetails;

  if TSessionStorage.GetValue('Dashboard.FromDate') = '' then begin

    WebDateTimePicker1.Date:= Date - 28; //DayOf(Date) + 1;
    WebDateTimePicker2.Date:= Date;

    TSessionStorage.SetValue('Dashboard.FromDate', DateToStr(WebDateTimePicker1.Date));
    TSessionStorage.SetValue('Dashboard.ToDate', DateToStr(WebDateTimePicker2.Date));
  end else begin
    WebDateTimePicker1.Date:= StrToDate(TSessionStorage.GetValue('Dashboard.FromDate'));
    WebDateTimePicker2.Date:= StrToDate(TSessionStorage.GetValue('Dashboard.ToDate'));;
  end;

  document.getElementById('footer-year').innerText:= (YearOf(Date)).ToString;

  LoadTodaysandDailySales;

  //el := document.getElementById('location-list');
  //el['aria-label'] := 'Default select example';
//  asm
//    var win = navigator.platform.indexOf('Win') > -1;
//    if (win && document.querySelector('#sidenav-scrollbar')) {
//      var options = {
//        damping: '0.5'
//      }
//      Scrollbar.init(document.querySelector('#sidenav-scrollbar'), options);
//    }
//  end;
end;

procedure TfrmDashboard.WebFormShow(Sender: TObject);
begin
  //WebTimer1.Enabled:= True;
  //chooselocationdropdown.ItemIndex:= 0;
end;

procedure TfrmDashboard.WebTimer1Timer(Sender: TObject);
begin
  WebTimer1.Enabled:= False;
  //LoadDailySales;
end;

procedure TfrmDashboard.WebButton1Click(Sender: TObject);
begin
  LoadDailySales;
end;

procedure TfrmDashboard.LoadDFMValues;
begin
  inherited LoadDFMValues;

  TodaySalesLabel := TLabel.Create('TodaySalesLabel');
  TodayTransLabel := TLabel.Create('TodayTransLabel');
  TodayAGCLabel := TLabel.Create('TodayAGCLabel');
  TodayThirdPartyPercLabelLabel := TLabel.Create('TodayThirdPartyPercLabel');
  LastWeekSalesDiffLabel := TLabel.Create('LastWeekSalesDiff');
  LastWeekTransDiffLabel := TLabel.Create('LastWeekTransDiff');
  LastWeekAGCDiffLabel := TLabel.Create('LastWeekAGCDiff');
  LastWeekThirdPartyPercDiffLabel := TLabel.Create('LastWeekThirdPartyPercDiff');
  LastWeekSameTimeAGCDiffLabel := TLabel.Create('LastWeekSameTimeAGCDiff');
  LastWeekSameTimeSalesDiffLabel := TLabel.Create('LastWeekSameTimeSalesDiff');
  LastWeekSameTimeThirdPartyPercDiffLabel := TLabel.Create('LastWeekSameTimeThirdPartyPercDiff');
  LastWeekSameTimeTransDiffLabel := TLabel.Create('LastWeekSameTimeTransDiff');
  LastMonthSalesDiffLabel := TLabel.Create('LastMonthSalesDiff');
  LastMonthSameTimeSalesDiffLabel := TLabel.Create('LastMonthSameTimeSalesDiff');
  LastMonthAGCDiffLabel := TLabel.Create('LastMonthAGCDiff');
  LastMonthSameTimeAGCDiffLabel := TLabel.Create('LastMonthSameTimeAGCDiff');
  LastMonthSameTimeThirdPartyPercDiffLabel := TLabel.Create('LastMonthSameTimeThirdPartyPercDiff');
  LastMonthSameTimeTransDiffLabel := TLabel.Create('LastMonthSameTimeTransDiff');
  LastMonthThirdPartyPercDiffLabel := TLabel.Create('LastMonthThirdPartyPercDiff');
  LastMonthTransDiffLabel := TLabel.Create('LastMonthTransDiff');
  TransUpdatedLabel := TLabel.Create('trans-updated');
  AGCUpdatedLabel := TLabel.Create('agc-updated');
  SalesUpdatedLabel := TLabel.Create('sales-updated');
  TodayPersonsLabel := TLabel.Create('TodayPersonsLabel');
  TodayAPPLabel := TLabel.Create('TodayAPPLabel');
  LastMonthAPPDiffLabel := TLabel.Create('LastMonthAPPDiff');
  LastWeekAPPDiffLabel := TLabel.Create('LastWeekAPPDiff');
  LastMonthPersonsDiffLabel := TLabel.Create('LastMonthPersonsDiff');
  LastWeekPersonsDiffLabel := TLabel.Create('LastWeekPersonsDiff');
  LastWeekSameTimeAPPDiffLabel := TLabel.Create('LastWeekSameTimeAPPDiff');
  LastMonthSameTimeAPPDiffLabel := TLabel.Create('LastMonthSameTimeAPPDiff');
  LastWeekSameTimePersonsDiffLabel := TLabel.Create('LastWeekSameTimePersonsDiff');
  LastMonthSameTimePersonsDiffLabel := TLabel.Create('LastMonthSameTimePersonsDiff');
  WebEdit1 := TEdit.Create('type-here-text');
  WebButton1 := TButton.Create('load-btn');
  WebDateTimePicker1 := TDateTimePicker.Create('from-date');
  WebDateTimePicker2 := TDateTimePicker.Create('to-date');
  ChooseLocationRowDiv := THTMLDiv.Create('chooselocationrow');
  chooselocationdropdown := TListControl.Create('chooselocationdropdown');
  TodaysSalesRequest := THttpRequest.Create(Self);
  WebTimer1 := TTimer.Create(Self);
  Request2 := THttpRequest.Create(Self);
  Request3 := THttpRequest.Create(Self);

  TodaySalesLabel.BeforeLoadDFMValues;
  TodayTransLabel.BeforeLoadDFMValues;
  TodayAGCLabel.BeforeLoadDFMValues;
  TodayThirdPartyPercLabelLabel.BeforeLoadDFMValues;
  LastWeekSalesDiffLabel.BeforeLoadDFMValues;
  LastWeekTransDiffLabel.BeforeLoadDFMValues;
  LastWeekAGCDiffLabel.BeforeLoadDFMValues;
  LastWeekThirdPartyPercDiffLabel.BeforeLoadDFMValues;
  LastWeekSameTimeAGCDiffLabel.BeforeLoadDFMValues;
  LastWeekSameTimeSalesDiffLabel.BeforeLoadDFMValues;
  LastWeekSameTimeThirdPartyPercDiffLabel.BeforeLoadDFMValues;
  LastWeekSameTimeTransDiffLabel.BeforeLoadDFMValues;
  LastMonthSalesDiffLabel.BeforeLoadDFMValues;
  LastMonthSameTimeSalesDiffLabel.BeforeLoadDFMValues;
  LastMonthAGCDiffLabel.BeforeLoadDFMValues;
  LastMonthSameTimeAGCDiffLabel.BeforeLoadDFMValues;
  LastMonthSameTimeThirdPartyPercDiffLabel.BeforeLoadDFMValues;
  LastMonthSameTimeTransDiffLabel.BeforeLoadDFMValues;
  LastMonthThirdPartyPercDiffLabel.BeforeLoadDFMValues;
  LastMonthTransDiffLabel.BeforeLoadDFMValues;
  TransUpdatedLabel.BeforeLoadDFMValues;
  AGCUpdatedLabel.BeforeLoadDFMValues;
  SalesUpdatedLabel.BeforeLoadDFMValues;
  TodayPersonsLabel.BeforeLoadDFMValues;
  TodayAPPLabel.BeforeLoadDFMValues;
  LastMonthAPPDiffLabel.BeforeLoadDFMValues;
  LastWeekAPPDiffLabel.BeforeLoadDFMValues;
  LastMonthPersonsDiffLabel.BeforeLoadDFMValues;
  LastWeekPersonsDiffLabel.BeforeLoadDFMValues;
  LastWeekSameTimeAPPDiffLabel.BeforeLoadDFMValues;
  LastMonthSameTimeAPPDiffLabel.BeforeLoadDFMValues;
  LastWeekSameTimePersonsDiffLabel.BeforeLoadDFMValues;
  LastMonthSameTimePersonsDiffLabel.BeforeLoadDFMValues;
  WebEdit1.BeforeLoadDFMValues;
  WebButton1.BeforeLoadDFMValues;
  WebDateTimePicker1.BeforeLoadDFMValues;
  WebDateTimePicker2.BeforeLoadDFMValues;
  ChooseLocationRowDiv.BeforeLoadDFMValues;
  chooselocationdropdown.BeforeLoadDFMValues;
  TodaysSalesRequest.BeforeLoadDFMValues;
  WebTimer1.BeforeLoadDFMValues;
  Request2.BeforeLoadDFMValues;
  Request3.BeforeLoadDFMValues;
  try
    Name := 'frmDashboard';
    Width := 1013;
    Height := 685;
    Caption := 'Dashboard';
    CSSLibrary := cssBootstrap;
    ElementFont := efCSS;
    FormContainer := 'webform';
    SetEvent(Self, 'OnCreate', 'WebFormCreate');
    SetEvent(Self, 'OnShow', 'WebFormShow');
    TodaySalesLabel.SetParentComponent(Self);
    TodaySalesLabel.Name := 'TodaySalesLabel';
    TodaySalesLabel.Left := 176;
    TodaySalesLabel.Top := 338;
    TodaySalesLabel.Width := 3;
    TodaySalesLabel.Height := 15;
    TodaySalesLabel.ElementFont := efCSS;
    TodaySalesLabel.HeightStyle := ssAuto;
    TodaySalesLabel.HeightPercent := 100.000000000000000000;
    TodaySalesLabel.WidthPercent := 100.000000000000000000;
    TodayTransLabel.SetParentComponent(Self);
    TodayTransLabel.Name := 'TodayTransLabel';
    TodayTransLabel.Left := 176;
    TodayTransLabel.Top := 359;
    TodayTransLabel.Width := 3;
    TodayTransLabel.Height := 15;
    TodayTransLabel.ElementFont := efCSS;
    TodayTransLabel.HeightStyle := ssAuto;
    TodayTransLabel.HeightPercent := 100.000000000000000000;
    TodayTransLabel.WidthPercent := 100.000000000000000000;
    TodayAGCLabel.SetParentComponent(Self);
    TodayAGCLabel.Name := 'TodayAGCLabel';
    TodayAGCLabel.Left := 176;
    TodayAGCLabel.Top := 380;
    TodayAGCLabel.Width := 3;
    TodayAGCLabel.Height := 15;
    TodayAGCLabel.ElementFont := efCSS;
    TodayAGCLabel.HeightStyle := ssAuto;
    TodayAGCLabel.HeightPercent := 100.000000000000000000;
    TodayAGCLabel.WidthPercent := 100.000000000000000000;
    TodayThirdPartyPercLabelLabel.SetParentComponent(Self);
    TodayThirdPartyPercLabelLabel.Name := 'TodayThirdPartyPercLabelLabel';
    TodayThirdPartyPercLabelLabel.Left := 176;
    TodayThirdPartyPercLabelLabel.Top := 401;
    TodayThirdPartyPercLabelLabel.Width := 3;
    TodayThirdPartyPercLabelLabel.Height := 15;
    TodayThirdPartyPercLabelLabel.ElementFont := efCSS;
    TodayThirdPartyPercLabelLabel.HeightStyle := ssAuto;
    TodayThirdPartyPercLabelLabel.HeightPercent := 100.000000000000000000;
    TodayThirdPartyPercLabelLabel.WidthPercent := 100.000000000000000000;
    LastWeekSalesDiffLabel.SetParentComponent(Self);
    LastWeekSalesDiffLabel.Name := 'LastWeekSalesDiffLabel';
    LastWeekSalesDiffLabel.Left := 193;
    LastWeekSalesDiffLabel.Top := 401;
    LastWeekSalesDiffLabel.Width := 3;
    LastWeekSalesDiffLabel.Height := 15;
    LastWeekSalesDiffLabel.ElementFont := efCSS;
    LastWeekSalesDiffLabel.HeightStyle := ssAuto;
    LastWeekSalesDiffLabel.HeightPercent := 100.000000000000000000;
    LastWeekSalesDiffLabel.WidthPercent := 100.000000000000000000;
    LastWeekTransDiffLabel.SetParentComponent(Self);
    LastWeekTransDiffLabel.Name := 'LastWeekTransDiffLabel';
    LastWeekTransDiffLabel.Left := 193;
    LastWeekTransDiffLabel.Top := 433;
    LastWeekTransDiffLabel.Width := 3;
    LastWeekTransDiffLabel.Height := 15;
    LastWeekTransDiffLabel.ElementFont := efCSS;
    LastWeekTransDiffLabel.HeightStyle := ssAuto;
    LastWeekTransDiffLabel.HeightPercent := 100.000000000000000000;
    LastWeekTransDiffLabel.WidthPercent := 100.000000000000000000;
    LastWeekAGCDiffLabel.SetParentComponent(Self);
    LastWeekAGCDiffLabel.Name := 'LastWeekAGCDiffLabel';
    LastWeekAGCDiffLabel.Left := 193;
    LastWeekAGCDiffLabel.Top := 473;
    LastWeekAGCDiffLabel.Width := 3;
    LastWeekAGCDiffLabel.Height := 15;
    LastWeekAGCDiffLabel.ElementFont := efCSS;
    LastWeekAGCDiffLabel.HeightStyle := ssAuto;
    LastWeekAGCDiffLabel.HeightPercent := 100.000000000000000000;
    LastWeekAGCDiffLabel.WidthPercent := 100.000000000000000000;
    LastWeekThirdPartyPercDiffLabel.SetParentComponent(Self);
    LastWeekThirdPartyPercDiffLabel.Name := 'LastWeekThirdPartyPercDiffLabel';
    LastWeekThirdPartyPercDiffLabel.Left := 193;
    LastWeekThirdPartyPercDiffLabel.Top := 513;
    LastWeekThirdPartyPercDiffLabel.Width := 3;
    LastWeekThirdPartyPercDiffLabel.Height := 15;
    LastWeekThirdPartyPercDiffLabel.ElementFont := efCSS;
    LastWeekThirdPartyPercDiffLabel.HeightStyle := ssAuto;
    LastWeekThirdPartyPercDiffLabel.HeightPercent := 100.000000000000000000;
    LastWeekThirdPartyPercDiffLabel.WidthPercent := 100.000000000000000000;
    LastWeekSameTimeAGCDiffLabel.SetParentComponent(Self);
    LastWeekSameTimeAGCDiffLabel.Name := 'LastWeekSameTimeAGCDiffLabel';
    LastWeekSameTimeAGCDiffLabel.Left := 400;
    LastWeekSameTimeAGCDiffLabel.Top := 481;
    LastWeekSameTimeAGCDiffLabel.Width := 3;
    LastWeekSameTimeAGCDiffLabel.Height := 15;
    LastWeekSameTimeAGCDiffLabel.ElementFont := efCSS;
    LastWeekSameTimeAGCDiffLabel.HeightStyle := ssAuto;
    LastWeekSameTimeAGCDiffLabel.HeightPercent := 100.000000000000000000;
    LastWeekSameTimeAGCDiffLabel.WidthPercent := 100.000000000000000000;
    LastWeekSameTimeSalesDiffLabel.SetParentComponent(Self);
    LastWeekSameTimeSalesDiffLabel.Name := 'LastWeekSameTimeSalesDiffLabel';
    LastWeekSameTimeSalesDiffLabel.Left := 400;
    LastWeekSameTimeSalesDiffLabel.Top := 409;
    LastWeekSameTimeSalesDiffLabel.Width := 3;
    LastWeekSameTimeSalesDiffLabel.Height := 15;
    LastWeekSameTimeSalesDiffLabel.ElementFont := efCSS;
    LastWeekSameTimeSalesDiffLabel.HeightStyle := ssAuto;
    LastWeekSameTimeSalesDiffLabel.HeightPercent := 100.000000000000000000;
    LastWeekSameTimeSalesDiffLabel.WidthPercent := 100.000000000000000000;
    LastWeekSameTimeThirdPartyPercDiffLabel.SetParentComponent(Self);
    LastWeekSameTimeThirdPartyPercDiffLabel.Name := 'LastWeekSameTimeThirdPartyPercDiffLabel';
    LastWeekSameTimeThirdPartyPercDiffLabel.Left := 400;
    LastWeekSameTimeThirdPartyPercDiffLabel.Top := 521;
    LastWeekSameTimeThirdPartyPercDiffLabel.Width := 3;
    LastWeekSameTimeThirdPartyPercDiffLabel.Height := 15;
    LastWeekSameTimeThirdPartyPercDiffLabel.ElementFont := efCSS;
    LastWeekSameTimeThirdPartyPercDiffLabel.HeightStyle := ssAuto;
    LastWeekSameTimeThirdPartyPercDiffLabel.HeightPercent := 100.000000000000000000;
    LastWeekSameTimeThirdPartyPercDiffLabel.WidthPercent := 100.000000000000000000;
    LastWeekSameTimeTransDiffLabel.SetParentComponent(Self);
    LastWeekSameTimeTransDiffLabel.Name := 'LastWeekSameTimeTransDiffLabel';
    LastWeekSameTimeTransDiffLabel.Left := 400;
    LastWeekSameTimeTransDiffLabel.Top := 441;
    LastWeekSameTimeTransDiffLabel.Width := 3;
    LastWeekSameTimeTransDiffLabel.Height := 15;
    LastWeekSameTimeTransDiffLabel.ElementFont := efCSS;
    LastWeekSameTimeTransDiffLabel.HeightStyle := ssAuto;
    LastWeekSameTimeTransDiffLabel.HeightPercent := 100.000000000000000000;
    LastWeekSameTimeTransDiffLabel.WidthPercent := 100.000000000000000000;
    LastMonthSalesDiffLabel.SetParentComponent(Self);
    LastMonthSalesDiffLabel.Name := 'LastMonthSalesDiffLabel';
    LastMonthSalesDiffLabel.Left := 201;
    LastMonthSalesDiffLabel.Top := 600;
    LastMonthSalesDiffLabel.Width := 3;
    LastMonthSalesDiffLabel.Height := 15;
    LastMonthSalesDiffLabel.ElementFont := efCSS;
    LastMonthSalesDiffLabel.HeightStyle := ssAuto;
    LastMonthSalesDiffLabel.HeightPercent := 100.000000000000000000;
    LastMonthSalesDiffLabel.WidthPercent := 100.000000000000000000;
    LastMonthSameTimeSalesDiffLabel.SetParentComponent(Self);
    LastMonthSameTimeSalesDiffLabel.Name := 'LastMonthSameTimeSalesDiffLabel';
    LastMonthSameTimeSalesDiffLabel.Left := 408;
    LastMonthSameTimeSalesDiffLabel.Top := 600;
    LastMonthSameTimeSalesDiffLabel.Width := 3;
    LastMonthSameTimeSalesDiffLabel.Height := 15;
    LastMonthSameTimeSalesDiffLabel.ElementFont := efCSS;
    LastMonthSameTimeSalesDiffLabel.HeightStyle := ssAuto;
    LastMonthSameTimeSalesDiffLabel.HeightPercent := 100.000000000000000000;
    LastMonthSameTimeSalesDiffLabel.WidthPercent := 100.000000000000000000;
    LastMonthAGCDiffLabel.SetParentComponent(Self);
    LastMonthAGCDiffLabel.Name := 'LastMonthAGCDiffLabel';
    LastMonthAGCDiffLabel.Left := 201;
    LastMonthAGCDiffLabel.Top := 481;
    LastMonthAGCDiffLabel.Width := 3;
    LastMonthAGCDiffLabel.Height := 15;
    LastMonthAGCDiffLabel.ElementFont := efCSS;
    LastMonthAGCDiffLabel.HeightStyle := ssAuto;
    LastMonthAGCDiffLabel.HeightPercent := 100.000000000000000000;
    LastMonthAGCDiffLabel.WidthPercent := 100.000000000000000000;
    LastMonthSameTimeAGCDiffLabel.SetParentComponent(Self);
    LastMonthSameTimeAGCDiffLabel.Name := 'LastMonthSameTimeAGCDiffLabel';
    LastMonthSameTimeAGCDiffLabel.Left := 408;
    LastMonthSameTimeAGCDiffLabel.Top := 489;
    LastMonthSameTimeAGCDiffLabel.Width := 3;
    LastMonthSameTimeAGCDiffLabel.Height := 15;
    LastMonthSameTimeAGCDiffLabel.ElementFont := efCSS;
    LastMonthSameTimeAGCDiffLabel.HeightStyle := ssAuto;
    LastMonthSameTimeAGCDiffLabel.HeightPercent := 100.000000000000000000;
    LastMonthSameTimeAGCDiffLabel.WidthPercent := 100.000000000000000000;
    LastMonthSameTimeThirdPartyPercDiffLabel.SetParentComponent(Self);
    LastMonthSameTimeThirdPartyPercDiffLabel.Name := 'LastMonthSameTimeThirdPartyPercDiffLabel';
    LastMonthSameTimeThirdPartyPercDiffLabel.Left := 408;
    LastMonthSameTimeThirdPartyPercDiffLabel.Top := 529;
    LastMonthSameTimeThirdPartyPercDiffLabel.Width := 3;
    LastMonthSameTimeThirdPartyPercDiffLabel.Height := 15;
    LastMonthSameTimeThirdPartyPercDiffLabel.ElementFont := efCSS;
    LastMonthSameTimeThirdPartyPercDiffLabel.HeightStyle := ssAuto;
    LastMonthSameTimeThirdPartyPercDiffLabel.HeightPercent := 100.000000000000000000;
    LastMonthSameTimeThirdPartyPercDiffLabel.WidthPercent := 100.000000000000000000;
    LastMonthSameTimeTransDiffLabel.SetParentComponent(Self);
    LastMonthSameTimeTransDiffLabel.Name := 'LastMonthSameTimeTransDiffLabel';
    LastMonthSameTimeTransDiffLabel.Left := 408;
    LastMonthSameTimeTransDiffLabel.Top := 449;
    LastMonthSameTimeTransDiffLabel.Width := 3;
    LastMonthSameTimeTransDiffLabel.Height := 15;
    LastMonthSameTimeTransDiffLabel.ElementFont := efCSS;
    LastMonthSameTimeTransDiffLabel.HeightStyle := ssAuto;
    LastMonthSameTimeTransDiffLabel.HeightPercent := 100.000000000000000000;
    LastMonthSameTimeTransDiffLabel.WidthPercent := 100.000000000000000000;
    LastMonthThirdPartyPercDiffLabel.SetParentComponent(Self);
    LastMonthThirdPartyPercDiffLabel.Name := 'LastMonthThirdPartyPercDiffLabel';
    LastMonthThirdPartyPercDiffLabel.Left := 201;
    LastMonthThirdPartyPercDiffLabel.Top := 521;
    LastMonthThirdPartyPercDiffLabel.Width := 3;
    LastMonthThirdPartyPercDiffLabel.Height := 15;
    LastMonthThirdPartyPercDiffLabel.ElementFont := efCSS;
    LastMonthThirdPartyPercDiffLabel.HeightStyle := ssAuto;
    LastMonthThirdPartyPercDiffLabel.HeightPercent := 100.000000000000000000;
    LastMonthThirdPartyPercDiffLabel.WidthPercent := 100.000000000000000000;
    LastMonthTransDiffLabel.SetParentComponent(Self);
    LastMonthTransDiffLabel.Name := 'LastMonthTransDiffLabel';
    LastMonthTransDiffLabel.Left := 201;
    LastMonthTransDiffLabel.Top := 441;
    LastMonthTransDiffLabel.Width := 3;
    LastMonthTransDiffLabel.Height := 15;
    LastMonthTransDiffLabel.ElementFont := efCSS;
    LastMonthTransDiffLabel.HeightStyle := ssAuto;
    LastMonthTransDiffLabel.HeightPercent := 100.000000000000000000;
    LastMonthTransDiffLabel.WidthPercent := 100.000000000000000000;
    TransUpdatedLabel.SetParentComponent(Self);
    TransUpdatedLabel.Name := 'TransUpdatedLabel';
    TransUpdatedLabel.Left := 217;
    TransUpdatedLabel.Top := 497;
    TransUpdatedLabel.Width := 3;
    TransUpdatedLabel.Height := 15;
    TransUpdatedLabel.ElementFont := efCSS;
    TransUpdatedLabel.HeightStyle := ssAuto;
    TransUpdatedLabel.HeightPercent := 100.000000000000000000;
    TransUpdatedLabel.WidthPercent := 100.000000000000000000;
    AGCUpdatedLabel.SetParentComponent(Self);
    AGCUpdatedLabel.Name := 'AGCUpdatedLabel';
    AGCUpdatedLabel.Left := 225;
    AGCUpdatedLabel.Top := 505;
    AGCUpdatedLabel.Width := 3;
    AGCUpdatedLabel.Height := 15;
    AGCUpdatedLabel.ElementFont := efCSS;
    AGCUpdatedLabel.HeightStyle := ssAuto;
    AGCUpdatedLabel.HeightPercent := 100.000000000000000000;
    AGCUpdatedLabel.WidthPercent := 100.000000000000000000;
    SalesUpdatedLabel.SetParentComponent(Self);
    SalesUpdatedLabel.Name := 'SalesUpdatedLabel';
    SalesUpdatedLabel.Left := 806;
    SalesUpdatedLabel.Top := 628;
    SalesUpdatedLabel.Width := 99;
    SalesUpdatedLabel.Height := 15;
    SalesUpdatedLabel.Caption := 'SalesUpdatedLabel';
    SalesUpdatedLabel.ElementFont := efCSS;
    SalesUpdatedLabel.ElementPosition := epIgnore;
    SalesUpdatedLabel.HeightStyle := ssAuto;
    SalesUpdatedLabel.HeightPercent := 100.000000000000000000;
    SalesUpdatedLabel.WidthStyle := ssAuto;
    SalesUpdatedLabel.WidthPercent := 100.000000000000000000;
    TodayPersonsLabel.SetParentComponent(Self);
    TodayPersonsLabel.Name := 'TodayPersonsLabel';
    TodayPersonsLabel.Left := 184;
    TodayPersonsLabel.Top := 346;
    TodayPersonsLabel.Width := 3;
    TodayPersonsLabel.Height := 15;
    TodayPersonsLabel.ElementFont := efCSS;
    TodayPersonsLabel.HeightStyle := ssAuto;
    TodayPersonsLabel.HeightPercent := 100.000000000000000000;
    TodayPersonsLabel.WidthPercent := 100.000000000000000000;
    TodayAPPLabel.SetParentComponent(Self);
    TodayAPPLabel.Name := 'TodayAPPLabel';
    TodayAPPLabel.Left := 184;
    TodayAPPLabel.Top := 388;
    TodayAPPLabel.Width := 3;
    TodayAPPLabel.Height := 15;
    TodayAPPLabel.ElementFont := efCSS;
    TodayAPPLabel.HeightStyle := ssAuto;
    TodayAPPLabel.HeightPercent := 100.000000000000000000;
    TodayAPPLabel.WidthPercent := 100.000000000000000000;
    LastMonthAPPDiffLabel.SetParentComponent(Self);
    LastMonthAPPDiffLabel.Name := 'LastMonthAPPDiffLabel';
    LastMonthAPPDiffLabel.Left := 209;
    LastMonthAPPDiffLabel.Top := 489;
    LastMonthAPPDiffLabel.Width := 3;
    LastMonthAPPDiffLabel.Height := 15;
    LastMonthAPPDiffLabel.ElementFont := efCSS;
    LastMonthAPPDiffLabel.HeightStyle := ssAuto;
    LastMonthAPPDiffLabel.HeightPercent := 100.000000000000000000;
    LastMonthAPPDiffLabel.WidthPercent := 100.000000000000000000;
    LastWeekAPPDiffLabel.SetParentComponent(Self);
    LastWeekAPPDiffLabel.Name := 'LastWeekAPPDiffLabel';
    LastWeekAPPDiffLabel.Left := 233;
    LastWeekAPPDiffLabel.Top := 513;
    LastWeekAPPDiffLabel.Width := 3;
    LastWeekAPPDiffLabel.Height := 15;
    LastWeekAPPDiffLabel.ElementFont := efCSS;
    LastWeekAPPDiffLabel.HeightStyle := ssAuto;
    LastWeekAPPDiffLabel.HeightPercent := 100.000000000000000000;
    LastWeekAPPDiffLabel.WidthPercent := 100.000000000000000000;
    LastMonthPersonsDiffLabel.SetParentComponent(Self);
    LastMonthPersonsDiffLabel.Name := 'LastMonthPersonsDiffLabel';
    LastMonthPersonsDiffLabel.Left := 209;
    LastMonthPersonsDiffLabel.Top := 449;
    LastMonthPersonsDiffLabel.Width := 3;
    LastMonthPersonsDiffLabel.Height := 15;
    LastMonthPersonsDiffLabel.ElementFont := efCSS;
    LastMonthPersonsDiffLabel.HeightStyle := ssAuto;
    LastMonthPersonsDiffLabel.HeightPercent := 100.000000000000000000;
    LastMonthPersonsDiffLabel.WidthPercent := 100.000000000000000000;
    LastWeekPersonsDiffLabel.SetParentComponent(Self);
    LastWeekPersonsDiffLabel.Name := 'LastWeekPersonsDiffLabel';
    LastWeekPersonsDiffLabel.Left := 217;
    LastWeekPersonsDiffLabel.Top := 457;
    LastWeekPersonsDiffLabel.Width := 3;
    LastWeekPersonsDiffLabel.Height := 15;
    LastWeekPersonsDiffLabel.ElementFont := efCSS;
    LastWeekPersonsDiffLabel.HeightStyle := ssAuto;
    LastWeekPersonsDiffLabel.HeightPercent := 100.000000000000000000;
    LastWeekPersonsDiffLabel.WidthPercent := 100.000000000000000000;
    LastWeekSameTimeAPPDiffLabel.SetParentComponent(Self);
    LastWeekSameTimeAPPDiffLabel.Name := 'LastWeekSameTimeAPPDiffLabel';
    LastWeekSameTimeAPPDiffLabel.Left := 241;
    LastWeekSameTimeAPPDiffLabel.Top := 521;
    LastWeekSameTimeAPPDiffLabel.Width := 3;
    LastWeekSameTimeAPPDiffLabel.Height := 15;
    LastWeekSameTimeAPPDiffLabel.ElementFont := efCSS;
    LastWeekSameTimeAPPDiffLabel.HeightStyle := ssAuto;
    LastWeekSameTimeAPPDiffLabel.HeightPercent := 100.000000000000000000;
    LastWeekSameTimeAPPDiffLabel.WidthPercent := 100.000000000000000000;
    LastMonthSameTimeAPPDiffLabel.SetParentComponent(Self);
    LastMonthSameTimeAPPDiffLabel.Name := 'LastMonthSameTimeAPPDiffLabel';
    LastMonthSameTimeAPPDiffLabel.Left := 249;
    LastMonthSameTimeAPPDiffLabel.Top := 529;
    LastMonthSameTimeAPPDiffLabel.Width := 3;
    LastMonthSameTimeAPPDiffLabel.Height := 15;
    LastMonthSameTimeAPPDiffLabel.ElementFont := efCSS;
    LastMonthSameTimeAPPDiffLabel.HeightStyle := ssAuto;
    LastMonthSameTimeAPPDiffLabel.HeightPercent := 100.000000000000000000;
    LastMonthSameTimeAPPDiffLabel.WidthPercent := 100.000000000000000000;
    LastWeekSameTimePersonsDiffLabel.SetParentComponent(Self);
    LastWeekSameTimePersonsDiffLabel.Name := 'LastWeekSameTimePersonsDiffLabel';
    LastWeekSameTimePersonsDiffLabel.Left := 225;
    LastWeekSameTimePersonsDiffLabel.Top := 465;
    LastWeekSameTimePersonsDiffLabel.Width := 3;
    LastWeekSameTimePersonsDiffLabel.Height := 15;
    LastWeekSameTimePersonsDiffLabel.ElementFont := efCSS;
    LastWeekSameTimePersonsDiffLabel.HeightStyle := ssAuto;
    LastWeekSameTimePersonsDiffLabel.HeightPercent := 100.000000000000000000;
    LastWeekSameTimePersonsDiffLabel.WidthPercent := 100.000000000000000000;
    LastMonthSameTimePersonsDiffLabel.SetParentComponent(Self);
    LastMonthSameTimePersonsDiffLabel.Name := 'LastMonthSameTimePersonsDiffLabel';
    LastMonthSameTimePersonsDiffLabel.Left := 233;
    LastMonthSameTimePersonsDiffLabel.Top := 473;
    LastMonthSameTimePersonsDiffLabel.Width := 3;
    LastMonthSameTimePersonsDiffLabel.Height := 15;
    LastMonthSameTimePersonsDiffLabel.ElementFont := efCSS;
    LastMonthSameTimePersonsDiffLabel.HeightStyle := ssAuto;
    LastMonthSameTimePersonsDiffLabel.HeightPercent := 100.000000000000000000;
    LastMonthSameTimePersonsDiffLabel.WidthPercent := 100.000000000000000000;
    WebEdit1.SetParentComponent(Self);
    WebEdit1.Name := 'WebEdit1';
    WebEdit1.Left := 546;
    WebEdit1.Top := 597;
    WebEdit1.Width := 121;
    WebEdit1.Height := 22;
    WebEdit1.ChildOrder := 6;
    WebEdit1.ElementClassName := 'form-control';
    WebEdit1.ElementFont := efCSS;
    WebEdit1.HeightStyle := ssAuto;
    WebEdit1.HeightPercent := 100.000000000000000000;
    WebEdit1.WidthPercent := 100.000000000000000000;
    SetEvent(WebEdit1, Self, 'OnEnter', 'WebEdit1Enter');
    SetEvent(WebEdit1, Self, 'OnExit', 'WebEdit1Exit');
    WebButton1.SetParentComponent(Self);
    WebButton1.Name := 'WebButton1';
    WebButton1.Left := 673;
    WebButton1.Top := 596;
    WebButton1.Width := 96;
    WebButton1.Height := 25;
    WebButton1.ChildOrder := 5;
    WebButton1.ElementFont := efCSS;
    WebButton1.HeightStyle := ssAuto;
    WebButton1.HeightPercent := 100.000000000000000000;
    WebButton1.WidthPercent := 100.000000000000000000;
    SetEvent(WebButton1, Self, 'OnClick', 'WebButton1Click');
    WebDateTimePicker1.SetParentComponent(Self);
    WebDateTimePicker1.Name := 'WebDateTimePicker1';
    WebDateTimePicker1.Left := 835;
    WebDateTimePicker1.Top := 434;
    WebDateTimePicker1.Width := 170;
    WebDateTimePicker1.Height := 22;
    WebDateTimePicker1.HeightStyle := ssAuto;
    WebDateTimePicker1.BorderStyle := bsSingle;
    WebDateTimePicker1.ChildOrder := 14;
    WebDateTimePicker1.Color := clWhite;
    WebDateTimePicker1.Date := 45117.587342303240000000;
    WebDateTimePicker1.ElementFont := efCSS;
    WebDateTimePicker1.Role := '';
    WebDateTimePicker1.Text := '';
    SetEvent(WebDateTimePicker1, Self, 'OnEnter', 'WebDateTimePicker1Enter');
    WebDateTimePicker2.SetParentComponent(Self);
    WebDateTimePicker2.Name := 'WebDateTimePicker2';
    WebDateTimePicker2.Left := 835;
    WebDateTimePicker2.Top := 462;
    WebDateTimePicker2.Width := 170;
    WebDateTimePicker2.Height := 22;
    WebDateTimePicker2.ElementClassName := 'form-control';
    WebDateTimePicker2.HeightStyle := ssAuto;
    WebDateTimePicker2.BorderStyle := bsSingle;
    WebDateTimePicker2.ChildOrder := 14;
    WebDateTimePicker2.Color := clWhite;
    WebDateTimePicker2.Date := 45117.587342303240000000;
    WebDateTimePicker2.ElementFont := efCSS;
    WebDateTimePicker2.Role := '';
    WebDateTimePicker2.Text := '';
    SetEvent(WebDateTimePicker2, Self, 'OnEnter', 'WebDateTimePicker1Enter');
    ChooseLocationRowDiv.SetParentComponent(Self);
    ChooseLocationRowDiv.Name := 'ChooseLocationRowDiv';
    ChooseLocationRowDiv.Left := 417;
    ChooseLocationRowDiv.Top := 581;
    ChooseLocationRowDiv.Width := 100;
    ChooseLocationRowDiv.Height := 40;
    ChooseLocationRowDiv.ChildOrder := 30;
    ChooseLocationRowDiv.ElementFont := efCSS;
    ChooseLocationRowDiv.Role := '';
    chooselocationdropdown.SetParentComponent(Self);
    chooselocationdropdown.Name := 'chooselocationdropdown';
    chooselocationdropdown.Left := 298;
    chooselocationdropdown.Top := 459;
    chooselocationdropdown.Width := 96;
    chooselocationdropdown.Height := 37;
    chooselocationdropdown.HeightStyle := ssAuto;
    chooselocationdropdown.WidthStyle := ssAuto;
    chooselocationdropdown.HeightPercent := 100.000000000000000000;
    chooselocationdropdown.WidthPercent := 100.000000000000000000;
    chooselocationdropdown.ChildOrder := 36;
    chooselocationdropdown.DefaultItemLinkClassName := 'list-group-link';
    chooselocationdropdown.ElementFont := efCSS;
    chooselocationdropdown.ElementPosition := epIgnore;
    chooselocationdropdown.ElementListClassName := 'dropdown-menu';
    chooselocationdropdown.Items.Clear;
    with chooselocationdropdown.Items.Add do
    begin
      Active := True;
      Link := '#';
      LinkClassName := 'dropdown-item';
      Tag := -999;
      Text := 'ALL LOCATION S';
    end;
    with chooselocationdropdown.Items.Add do
    begin
      Link := '#';
      LinkClassName := 'dropdown-item';
    end;
    chooselocationdropdown.Style := lsListGroup;
    SetEvent(chooselocationdropdown, Self, 'OnItemClick', 'chooselocationdropdownItemClick');
    TodaysSalesRequest.SetParentComponent(Self);
    TodaysSalesRequest.Name := 'TodaysSalesRequest';
    TodaysSalesRequest.Headers.BeginUpdate;
    try
      TodaysSalesRequest.Headers.Clear;
      TodaysSalesRequest.Headers.Add('Cache-Control=no-cache, no-store, must-revalidate');
    finally
      TodaysSalesRequest.Headers.EndUpdate;
    end;
    TodaysSalesRequest.ResponseType := rtJSON;
    TodaysSalesRequest.Left := 48;
    TodaysSalesRequest.Top := 48;
    WebTimer1.SetParentComponent(Self);
    WebTimer1.Name := 'WebTimer1';
    WebTimer1.Enabled := False;
    WebTimer1.Interval := 500;
    SetEvent(WebTimer1, Self, 'OnTimer', 'WebTimer1Timer');
    WebTimer1.Left := 496;
    WebTimer1.Top := 440;
    Request2.SetParentComponent(Self);
    Request2.Name := 'Request2';
    Request2.Headers.BeginUpdate;
    try
      Request2.Headers.Clear;
      Request2.Headers.Add('Cache-Control=no-cache, no-store, must-revalidate');
    finally
      Request2.Headers.EndUpdate;
    end;
    Request2.ResponseType := rtJSON;
    Request2.Left := 48;
    Request2.Top := 112;
    Request3.SetParentComponent(Self);
    Request3.Name := 'Request3';
    Request3.Headers.BeginUpdate;
    try
      Request3.Headers.Clear;
      Request3.Headers.Add('Cache-Control=no-cache, no-store, must-revalidate');
    finally
      Request3.Headers.EndUpdate;
    end;
    Request3.ResponseType := rtJSON;
    Request3.Left := 48;
    Request3.Top := 184;
  finally
    TodaySalesLabel.AfterLoadDFMValues;
    TodayTransLabel.AfterLoadDFMValues;
    TodayAGCLabel.AfterLoadDFMValues;
    TodayThirdPartyPercLabelLabel.AfterLoadDFMValues;
    LastWeekSalesDiffLabel.AfterLoadDFMValues;
    LastWeekTransDiffLabel.AfterLoadDFMValues;
    LastWeekAGCDiffLabel.AfterLoadDFMValues;
    LastWeekThirdPartyPercDiffLabel.AfterLoadDFMValues;
    LastWeekSameTimeAGCDiffLabel.AfterLoadDFMValues;
    LastWeekSameTimeSalesDiffLabel.AfterLoadDFMValues;
    LastWeekSameTimeThirdPartyPercDiffLabel.AfterLoadDFMValues;
    LastWeekSameTimeTransDiffLabel.AfterLoadDFMValues;
    LastMonthSalesDiffLabel.AfterLoadDFMValues;
    LastMonthSameTimeSalesDiffLabel.AfterLoadDFMValues;
    LastMonthAGCDiffLabel.AfterLoadDFMValues;
    LastMonthSameTimeAGCDiffLabel.AfterLoadDFMValues;
    LastMonthSameTimeThirdPartyPercDiffLabel.AfterLoadDFMValues;
    LastMonthSameTimeTransDiffLabel.AfterLoadDFMValues;
    LastMonthThirdPartyPercDiffLabel.AfterLoadDFMValues;
    LastMonthTransDiffLabel.AfterLoadDFMValues;
    TransUpdatedLabel.AfterLoadDFMValues;
    AGCUpdatedLabel.AfterLoadDFMValues;
    SalesUpdatedLabel.AfterLoadDFMValues;
    TodayPersonsLabel.AfterLoadDFMValues;
    TodayAPPLabel.AfterLoadDFMValues;
    LastMonthAPPDiffLabel.AfterLoadDFMValues;
    LastWeekAPPDiffLabel.AfterLoadDFMValues;
    LastMonthPersonsDiffLabel.AfterLoadDFMValues;
    LastWeekPersonsDiffLabel.AfterLoadDFMValues;
    LastWeekSameTimeAPPDiffLabel.AfterLoadDFMValues;
    LastMonthSameTimeAPPDiffLabel.AfterLoadDFMValues;
    LastWeekSameTimePersonsDiffLabel.AfterLoadDFMValues;
    LastMonthSameTimePersonsDiffLabel.AfterLoadDFMValues;
    WebEdit1.AfterLoadDFMValues;
    WebButton1.AfterLoadDFMValues;
    WebDateTimePicker1.AfterLoadDFMValues;
    WebDateTimePicker2.AfterLoadDFMValues;
    ChooseLocationRowDiv.AfterLoadDFMValues;
    chooselocationdropdown.AfterLoadDFMValues;
    TodaysSalesRequest.AfterLoadDFMValues;
    WebTimer1.AfterLoadDFMValues;
    Request2.AfterLoadDFMValues;
    Request3.AfterLoadDFMValues;
  end;
end;

end.
