unit uTables;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, Vcl.Controls, Vcl.Forms, uframe;

type
  TfrmTables = class(TForm)
  private
    { Private declarations }
  public
    { Public declarations }
  protected procedure LoadDFMValues; override; end;

var
  frmTables: TfrmTables;

implementation

{$R *.dfm}

procedure TfrmTables.LoadDFMValues;
begin
  inherited LoadDFMValues;


  try
    Name := 'frmTables';
    Width := 825;
    Height := 513;
  finally
  end;
end;

end.