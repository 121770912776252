unit uMain;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, Vcl.Controls, WEBLib.WebCtrls, Vcl.StdCtrls,
  WEBLib.StdCtrls, Vcl.Forms, WEBLib.Storage;

type
  TMainForm = class(TForm)
    WebHTMLDiv1: THTMLDiv;
    btnDashboard: TButton;
    btnTables: TButton;
    btnSignIn: TButton;
    WebButton1: TButton;
    CurrentPageDescription: TLabel;
    CurrentPageDescription2: TLabel;
    procedure btnDashboardClick(Sender: TObject);
    procedure btnTablesClick(Sender: TObject);
    procedure WebFormShow(Sender: TObject);
    procedure btnSignInClick(Sender: TObject);
  private
    { Private declarations }
    frm: TForm;
    procedure LaunchForm(AInstanceClass: TFormClass);
  public
    { Public declarations }
  protected procedure LoadDFMValues; override; end;

var
  MainForm: TMainForm;

implementation

{$R *.dfm}

uses
  uDashBoard, uTables, uSignIn;

procedure TMainForm.btnDashboardClick(Sender: TObject);
var
  el: TJSElement;
  CurrentClass: string;
begin
  //asm
  //  document.getElementById("iconNavbarSidenav").remove();
  //end;
  //ShowMessage('1');
  if TLocalStorage.GetValue('Login.SignedIn') <> 'True' then
    btnSignInClick(btnSignIn)
  else begin
    TLocalStorage.SetValue('Current.Form', 'Overview');
    CurrentPageDescription.Caption:= 'Overview';
    CurrentPageDescription2.Caption:= CurrentPageDescription.Caption;
    LaunchForm(TfrmDashboard);


    document.getElementById('dashboard-menu')['class']:= document.getElementById('dashboard-menu')['class'].Replace('active', '').Replace('bg-gradient-primary', '');
    document.getElementById('tables-menu')['class']:= document.getElementById('dashboard-menu')['class'].Replace('active', '').Replace('bg-gradient-primary', '');
    document.getElementById('signin-btn')['class']:= document.getElementById('signin-btn')['class'].Replace('active', '').Replace('bg-gradient-primary', '');

    el := document.getElementById('dashboard-menu');
    CurrentClass:= el['class'];
    CurrentClass:= CurrentClass.Replace('active', '');
    CurrentClass:= CurrentClass.Replace('bg-gradient-primary', '');
    CurrentClass:= CurrentClass+' active bg-gradient-primary';
    el['class'] := CurrentClass;
  end;
end;

procedure TMainForm.btnSignInClick(Sender: TObject);
var
  el: TJSElement;
  CurrentClass: string;
begin
  //el:= document.getElementById('MainBody');
  //el['class']:= el['class'].Replace('g-sidenav-pinned', '');

  TLocalStorage.SetValue('Login.SignedIn',  'False');
  CurrentPageDescription.Caption:= 'Overview';
  CurrentPageDescription2.Caption:= CurrentPageDescription.Caption;
  LaunchForm(TFrmSignIn);

  document.getElementById('dashboard-menu')['class']:= document.getElementById('dashboard-menu')['class'].Replace('active', '').Replace('bg-gradient-primary', '');
  document.getElementById('tables-menu')['class']:= document.getElementById('dashboard-menu')['class'].Replace('active', '').Replace('bg-gradient-primary', '');
  document.getElementById('signin-btn')['class']:= document.getElementById('signin-btn')['class'].Replace('active', '').Replace('bg-gradient-primary', '');

  el := document.getElementById('signin-btn');
  CurrentClass:= el['class'];
  CurrentClass:= CurrentClass.Replace('active', '');
  CurrentClass:= CurrentClass.Replace('bg-gradient-primary', '');
  CurrentClass:= CurrentClass+' active bg-gradient-primary';
  el['class'] := CurrentClass;
  el.innerText:= 'Sign In';

  //Application.CreateForm(TFrmSignIn, 'body', FrmSignIn, @FormCreated);

end;

procedure TMainForm.btnTablesClick(Sender: TObject);
var
  el: TJSElement;
  CurrentClass: string;
begin
  if TLocalStorage.GetValue('Login.SignedIn') <> 'True' then
    btnSignInClick(btnSignIn)
  else begin
    {TWebLocalStorage.SetValue('Current.Form', 'MenuAnalysis');
    CurrentPageDescription.Caption:= 'Tables';
    CurrentPageDescription2.Caption:= CurrentPageDescription.Caption;
    LaunchForm(TFrmTables);

    document.getElementById('dashboard-menu')['class']:= document.getElementById('dashboard-menu')['class'].Replace('active', '').Replace('bg-gradient-primary', '');
    document.getElementById('tables-menu')['class']:= document.getElementById('dashboard-menu')['class'].Replace('active', '').Replace('bg-gradient-primary', '');
    document.getElementById('signin-btn')['class']:= document.getElementById('signin-btn')['class'].Replace('active', '').Replace('bg-gradient-primary', '');

    el := document.getElementById('tables-menu');
    CurrentClass:= el['class'];
    CurrentClass:= CurrentClass.Replace('active', '');
    CurrentClass:= CurrentClass.Replace('bg-gradient-primary', '');
    CurrentClass:= CurrentClass+' active bg-gradient-primary';
    el['class'] := CurrentClass;}
  end;

end;

procedure TMainForm.LaunchForm(AInstanceClass: TFormClass);

  procedure FormCreated(AForm: TObject);
  begin
    (AForm as TForm).Show;
  end;

begin
  //ShowMessage('OK');
  if Assigned(frm) then
  begin
    frm.Close;
    frm.Free;
  end;

  if Uppercase(Application.ActiveForm.ClassName) <> Uppercase(AInstanceClass.ClassName) then
  begin
    Application.CreateForm(AInstanceClass, 'main-area', frm, @FormCreated);
  end;
end;

procedure TMainForm.WebFormShow(Sender: TObject);
var
  el: TJSElement;
begin
  //CurrentPageDescription.Caption:= 'Overview';
  //CurrentPageDescription2.Caption:= CurrentPageDescription.Caption;
  //LaunchForm(TfrmDashboard);
  if TLocalStorage.GetValue('Login.SignedIn') <> 'True' then
  begin
    el := document.getElementById('signin-btn');
    el.innerText:= 'Sign In';
    btnSignIn.Click;
  end
  else if TLocalStorage.GetValue('Current.Form') = 'MenuAnalysis' then btnTables.Click
  else btnDashboard.Click;
end;

procedure TMainForm.LoadDFMValues;
begin
  inherited LoadDFMValues;

  CurrentPageDescription := TLabel.Create('CurrentPageDescription');
  CurrentPageDescription2 := TLabel.Create('CurrentPageDescription2');
  WebHTMLDiv1 := THTMLDiv.Create('main-area');
  btnDashboard := TButton.Create('dashboard-menu');
  btnTables := TButton.Create('tables-menu');
  btnSignIn := TButton.Create('signin-btn');
  WebButton1 := TButton.Create('signin-btn2');

  CurrentPageDescription.BeforeLoadDFMValues;
  CurrentPageDescription2.BeforeLoadDFMValues;
  WebHTMLDiv1.BeforeLoadDFMValues;
  btnDashboard.BeforeLoadDFMValues;
  btnTables.BeforeLoadDFMValues;
  btnSignIn.BeforeLoadDFMValues;
  WebButton1.BeforeLoadDFMValues;
  try
    Name := 'MainForm';
    Width := 640;
    Height := 480;
    SetEvent(Self, 'OnShow', 'WebFormShow');
    CurrentPageDescription.SetParentComponent(Self);
    CurrentPageDescription.Name := 'CurrentPageDescription';
    CurrentPageDescription.Left := 356;
    CurrentPageDescription.Top := 36;
    CurrentPageDescription.Width := 3;
    CurrentPageDescription.Height := 15;
    CurrentPageDescription.ElementFont := efCSS;
    CurrentPageDescription.ElementPosition := epIgnore;
    CurrentPageDescription.HeightStyle := ssAuto;
    CurrentPageDescription.HeightPercent := 100.000000000000000000;
    CurrentPageDescription.WidthStyle := ssAuto;
    CurrentPageDescription.WidthPercent := 100.000000000000000000;
    CurrentPageDescription2.SetParentComponent(Self);
    CurrentPageDescription2.Name := 'CurrentPageDescription2';
    CurrentPageDescription2.Left := 364;
    CurrentPageDescription2.Top := 44;
    CurrentPageDescription2.Width := 3;
    CurrentPageDescription2.Height := 15;
    CurrentPageDescription2.ElementFont := efCSS;
    CurrentPageDescription2.ElementPosition := epIgnore;
    CurrentPageDescription2.HeightStyle := ssAuto;
    CurrentPageDescription2.HeightPercent := 100.000000000000000000;
    CurrentPageDescription2.WidthStyle := ssAuto;
    CurrentPageDescription2.WidthPercent := 100.000000000000000000;
    WebHTMLDiv1.SetParentComponent(Self);
    WebHTMLDiv1.Name := 'WebHTMLDiv1';
    WebHTMLDiv1.Left := 8;
    WebHTMLDiv1.Top := 8;
    WebHTMLDiv1.Width := 297;
    WebHTMLDiv1.Height := 201;
    WebHTMLDiv1.ElementClassName := 'container-fluid';
    WebHTMLDiv1.ElementFont := efCSS;
    WebHTMLDiv1.Role := '';
    btnDashboard.SetParentComponent(Self);
    btnDashboard.Name := 'btnDashboard';
    btnDashboard.Left := 408;
    btnDashboard.Top := 112;
    btnDashboard.Width := 96;
    btnDashboard.Height := 25;
    btnDashboard.Caption := 'btnDashboard';
    btnDashboard.ChildOrder := 2;
    btnDashboard.HeightPercent := 100.000000000000000000;
    btnDashboard.WidthPercent := 100.000000000000000000;
    SetEvent(btnDashboard, Self, 'OnClick', 'btnDashboardClick');
    btnTables.SetParentComponent(Self);
    btnTables.Name := 'btnTables';
    btnTables.Left := 408;
    btnTables.Top := 143;
    btnTables.Width := 96;
    btnTables.Height := 25;
    btnTables.Caption := 'btnTables';
    btnTables.ChildOrder := 2;
    btnTables.HeightPercent := 100.000000000000000000;
    btnTables.WidthPercent := 100.000000000000000000;
    SetEvent(btnTables, Self, 'OnClick', 'btnTablesClick');
    btnSignIn.SetParentComponent(Self);
    btnSignIn.Name := 'btnSignIn';
    btnSignIn.Left := 408;
    btnSignIn.Top := 174;
    btnSignIn.Width := 96;
    btnSignIn.Height := 25;
    btnSignIn.Caption := 'btnSignIn';
    btnSignIn.ChildOrder := 2;
    btnSignIn.HeightPercent := 100.000000000000000000;
    btnSignIn.WidthPercent := 100.000000000000000000;
    SetEvent(btnSignIn, Self, 'OnClick', 'btnSignInClick');
    WebButton1.SetParentComponent(Self);
    WebButton1.Name := 'WebButton1';
    WebButton1.Left := 408;
    WebButton1.Top := 205;
    WebButton1.Width := 96;
    WebButton1.Height := 25;
    WebButton1.Caption := 'btnSignIn2';
    WebButton1.ChildOrder := 2;
    WebButton1.HeightPercent := 100.000000000000000000;
    WebButton1.WidthPercent := 100.000000000000000000;
    SetEvent(WebButton1, Self, 'OnClick', 'btnSignInClick');
  finally
    CurrentPageDescription.AfterLoadDFMValues;
    CurrentPageDescription2.AfterLoadDFMValues;
    WebHTMLDiv1.AfterLoadDFMValues;
    btnDashboard.AfterLoadDFMValues;
    btnTables.AfterLoadDFMValues;
    btnSignIn.AfterLoadDFMValues;
    WebButton1.AfterLoadDFMValues;
  end;
end;

end.
