unit uframe;

interface

uses
  System.SysUtils, System.Variants, System.Classes,
  Vcl.Graphics, Vcl.Controls, Vcl.Forms, Vcl.Dialogs, WEBLib.Actions,
  Vcl.StdCtrls, WEBLib.StdCtrls;

type
  TMenuFrame = class(TFrame)
    WebElementActionList1: TElementActionList;
    btnDashboard: TButton;
    btnTables: TButton;
    btnSignIn: TButton;
    WebButton1: TButton;
    procedure WebElementActionList1Execute(Sender: TObject;
      AAction: TElementAction; Element: TJSHTMLElementRecord;
      Event: TJSEventParameter);
    procedure btnDashboardClick(Sender: TObject);
    procedure btnTablesClick(Sender: TObject);
    procedure btnSignInClick(Sender: TObject);
  private
    { Private declarations }
  public
    { Public declarations }
    frm: TForm;
    procedure LaunchForm(AInstanceClass: TFormClass);
  protected procedure LoadDFMValues; override; end;

implementation

{$R *.dfm}

uses
  web, uDashBoard, uTables, uSignIn;

procedure TMenuFrame.WebElementActionList1Execute(Sender: TObject;
  AAction: TElementAction; Element: TJSHTMLElementRecord;
  Event: TJSEventParameter);
begin
  case AAction.Index of
    0: ShowMessage('action 1');
    1: ShowMessage('action 2');
    2: ShowMessage('action 1');
    3: ShowMessage('search');
  end;
end;

procedure TMenuFrame.btnDashboardClick(Sender: TObject);
begin
  LaunchForm(TfrmDashboard);
end;

procedure TMenuFrame.btnSignInClick(Sender: TObject);
begin
  LaunchForm(TFrmSignIn);
end;

procedure TMenuFrame.btnTablesClick(Sender: TObject);
begin
  LaunchForm(TFrmTables);
end;

procedure TMenuFrame.LaunchForm(AInstanceClass: TFormClass);

  procedure FormCreated(AForm: TObject);
  begin
    (AForm as TForm).Show;
  end;

begin
  //ShowMessage('OK');
  if Assigned(frm) then
  begin
    frm.Close;
    frm.Free;
  end;

  if Uppercase(Application.ActiveForm.ClassName) <> Uppercase(AInstanceClass.ClassName) then
  begin
    Application.CreateForm(AInstanceClass, 'body', frm, @FormCreated);
    //Application.CreateForm(
  end;
end;


procedure TMenuFrame.LoadDFMValues;
begin
  inherited LoadDFMValues;

  btnDashboard := TButton.Create('dashboard-menu');
  btnTables := TButton.Create('tables-menu');
  btnSignIn := TButton.Create('signin-btn');
  WebButton1 := TButton.Create('signin-btn2');
  WebElementActionList1 := TElementActionList.Create(Self);

  btnDashboard.BeforeLoadDFMValues;
  btnTables.BeforeLoadDFMValues;
  btnSignIn.BeforeLoadDFMValues;
  WebButton1.BeforeLoadDFMValues;
  WebElementActionList1.BeforeLoadDFMValues;
  try
    Name := 'MenuFrame';
    Left := 0;
    Top := 0;
    Width := 320;
    Height := 453;
    TabOrder := 0;
    btnDashboard.SetParentComponent(Self);
    btnDashboard.Name := 'btnDashboard';
    btnDashboard.Left := 40;
    btnDashboard.Top := 144;
    btnDashboard.Width := 96;
    btnDashboard.Height := 25;
    btnDashboard.Caption := 'btnDashboard';
    btnDashboard.ChildOrder := 2;
    btnDashboard.HeightPercent := 100.000000000000000000;
    btnDashboard.WidthPercent := 100.000000000000000000;
    SetEvent(btnDashboard, Self, 'OnClick', 'btnDashboardClick');
    btnTables.SetParentComponent(Self);
    btnTables.Name := 'btnTables';
    btnTables.Left := 40;
    btnTables.Top := 175;
    btnTables.Width := 96;
    btnTables.Height := 25;
    btnTables.Caption := 'btnTables';
    btnTables.ChildOrder := 2;
    btnTables.HeightPercent := 100.000000000000000000;
    btnTables.WidthPercent := 100.000000000000000000;
    SetEvent(btnTables, Self, 'OnClick', 'btnTablesClick');
    btnSignIn.SetParentComponent(Self);
    btnSignIn.Name := 'btnSignIn';
    btnSignIn.Left := 40;
    btnSignIn.Top := 206;
    btnSignIn.Width := 96;
    btnSignIn.Height := 25;
    btnSignIn.Caption := 'btnSignIn';
    btnSignIn.ChildOrder := 2;
    btnSignIn.HeightPercent := 100.000000000000000000;
    btnSignIn.WidthPercent := 100.000000000000000000;
    SetEvent(btnSignIn, Self, 'OnClick', 'btnSignInClick');
    WebButton1.SetParentComponent(Self);
    WebButton1.Name := 'WebButton1';
    WebButton1.Left := 40;
    WebButton1.Top := 237;
    WebButton1.Width := 96;
    WebButton1.Height := 25;
    WebButton1.Caption := 'btnSignIn';
    WebButton1.ChildOrder := 2;
    WebButton1.HeightPercent := 100.000000000000000000;
    WebButton1.WidthPercent := 100.000000000000000000;
    SetEvent(WebButton1, Self, 'OnClick', 'btnSignInClick');
    WebElementActionList1.SetParentComponent(Self);
    WebElementActionList1.Name := 'WebElementActionList1';
    WebElementActionList1.Actions.Clear;
    with WebElementActionList1.Actions.Add do
    begin
      ID := 'action1';
      Name := 'Action0';
    end;
    with WebElementActionList1.Actions.Add do
    begin
      ID := 'action2';
      Name := 'Action1';
    end;
    with WebElementActionList1.Actions.Add do
    begin
      ID := 'action3';
      Name := 'Action2';
    end;
    with WebElementActionList1.Actions.Add do
    begin
      ID := 'search';
      Name := 'Action3';
    end;
    SetEvent(WebElementActionList1, Self, 'OnExecute', 'WebElementActionList1Execute');
    WebElementActionList1.Left := 208;
    WebElementActionList1.Top := 152;
  finally
    btnDashboard.AfterLoadDFMValues;
    btnTables.AfterLoadDFMValues;
    btnSignIn.AfterLoadDFMValues;
    WebButton1.AfterLoadDFMValues;
    WebElementActionList1.AfterLoadDFMValues;
  end;
end;

end.
